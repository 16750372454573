import React from 'react';
import { AggregateTerms, Search, Table } from 'react-tectonic';
import { startCase } from 'lodash-es';
import { Popup } from 'semantic';

export default ({
  collection,
  title,
  aggField,
  valueFieldName,
  termsSize = 8,
  operation,
  field,
  valueField,
  valueFormatter,
  labelFormatter,
  filter = undefined,
  fetchReference = undefined,
  timeRangeDateField = 'createdAt',
  referenceLabelFormatter = (event) => event.name,
  height,
}) => {
  function renderTable(data, options = {}) {
    return (
      <Table
        {...options}
        height={height}
        data={data}
        title={title}
        titleAlign="center"
        labelFormatter={
          labelFormatter || ((key) => startCase(key.toLowerCase()))
        }
        valueField={valueField}
        valueFieldName={valueFieldName}
        valueFormatter={valueFormatter}
        limit={termsSize}
      />
    );
  }

  return (
    <AggregateTerms
      collection={collection}
      aggField={aggField}
      termsSize={termsSize}
      operation={operation}
      filter={filter}
      timeRangeDateField={timeRangeDateField}
      field={field}>
      {({ data }) => {
        if (data && data.length && fetchReference) {
          return (
            <Search
              timeRange={null}
              collection={fetchReference}
              filter={{ ids: data.map((item) => item.key) }}>
              {({ data: references }) => {
                if (references.length) {
                  data.forEach((item) => {
                    item.reference = references
                      .map((reference) => reference._source)
                      .find((reference) => reference.id === item.key);
                  });
                }
                return renderTable(data, {
                  rowFormatter: (item, label, value) => {
                    return (
                      <React.Fragment>
                        <td>
                          {item.reference ? (
                            referenceLabelFormatter(item.reference)
                          ) : (
                            <Popup
                              trigger={<span>Unknown Reference</span>}
                              content={`${fetchReference}/${label
                                .replace(/s*/g, '')
                                .toLowerCase()}`}
                            />
                          )}
                        </td>
                        <td>{value}</td>
                      </React.Fragment>
                    );
                  },
                });
              }}
            </Search>
          );
        } else {
          return renderTable(data);
        }
      }}
    </AggregateTerms>
  );
};
