import {
  MerchantContractType,
  PaymentTerminalType,
  PayterPaymentMethod,
  PayterAuthorizationFlow,
  PaymentTerminalAcquirer,
} from 'types/payment-terminal';

export const translationPaymentTerminalType = (
  type: PaymentTerminalType
): { key: string; default: string } => {
  switch (type) {
    case 'payter':
      return {
        key: 'locationPaymentTerminal.contractTypePayter',
        default: 'Cloud Terminal - Payter',
      };
    case 'alpitronic':
      return {
        key: 'locationPaymentTerminal.contractTypeAlpitronic',
        default: 'Integrated Terminal - Alpitronic',
      };
    case 'sicharge':
      return {
        key: 'locationPaymentTerminal.contractTypeSiemens',
        default: 'Integrated Terminal - Siemens',
      };
    case 'ads-tec':
      return {
        key: 'locationPaymentTerminal.contractTypeAdsTec',
        default: 'Integrated Terminal - ADS-TEC',
      };
  }
};

export const translationMerchantContractType = (
  contract: MerchantContractType
): { key: string; default: string } => {
  switch (contract) {
    case 'road':
      return {
        key: 'locationPaymentTerminal.contractTypeRoad',
        default: 'Road',
      };
    case 'customer':
      return {
        key: 'locationPaymentTerminal.contractTypeCustomer',
        default: 'Customer',
      };
  }
};

export const translationPaymentTerminalAcquirer = (
  acquirer: PaymentTerminalAcquirer
): { key: string; default: string } => {
  switch (acquirer) {
    case 'elavon':
      return {
        key: 'locationPaymentTerminal.acquirerElavon',
        default: 'Elavon',
      };
    case 'worldline':
      return {
        key: 'locationPaymentTerminal.acquirerWorldline',
        default: 'Worldline',
      };
    case 'payone':
      return {
        key: 'locationPaymentTerminal.acquirerPayone',
        default: 'Payone',
      };
  }
};

export const translationPayterPaymentMethod = (
  method: PayterPaymentMethod
): { key: string; default: string } => {
  switch (method) {
    case 'card':
      return {
        key: 'locationPaymentTerminal.payterPaymentMethodCard',
        default: 'Card',
      };
    case 'card-rfid':
      return {
        key: 'locationPaymentTerminal.payterPaymentMethodCardAndRFID',
        default: 'Card and RFID',
      };
  }
};

export const translationPayterAuthorizationFlow = (
  method: PayterAuthorizationFlow
): { key: string; default: string } => {
  switch (method) {
    case 'connect-first':
      return {
        key: 'locationPaymentTerminal.payterAuthorizationFlowConnectFirst',
        default: 'Connect Cable First',
      };
    case 'connect-or-authorize-first':
      return {
        key: 'locationPaymentTerminal.payterAuthorizationFlowConnectOrAuthorizeFirst',
        default: 'Connect Cable or Authorize Card First',
      };
  }
};
