import React from 'react';
import { Container, Segment, Grid, Divider } from 'semantic';
import { withTranslation } from 'react-i18next';
import { canAccessProviderEndpoint } from 'utils/roles';
import { UserContext } from 'contexts/user';
import LegalLink from '../LegalLink';
import styles from './styles.module.less';

class Footer extends React.Component {
  static contextType = UserContext;

  renderSupportItem() {
    const { provider } = this.context;

    if (provider.supportUrl) {
      return (
        <a rel="noopener" target="_blank" href={`${provider.supportUrl}`}>
          {provider.supportUrl.replace('https://', '')}
        </a>
      );
    }

    if (provider.supportEmail) {
      return (
        <a
          rel="noopener"
          target="_blank"
          href={`mailto:${provider.supportEmail}`}>
          {provider.supportEmail}
        </a>
      );
    }

    return <a href="mailto:support@road.io">support@road.io</a>;
  }

  render() {
    const { t } = this.props;
    const { provider, user } = this.context;

    const customProvider = provider.slug !== 'e-flux';
    const adminAccess =
      user &&
      canAccessProviderEndpoint(user, user.providerId, 'tokens', 'read');

    return (
      <Segment className="footer" vertical>
        <Container>
          <Divider hidden />
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={13}>
                <div>
                  <p className={styles.companyInfo}>
                    <strong>
                      {customProvider && provider ? provider.name : 'Road BV'}
                    </strong>
                    <span>© {new Date().getFullYear()}</span>
                    <span className={styles.legalLinks}>
                      <LegalLink type="privacy">
                        {t('footer.privacyLink', 'Privacy Policies')}
                      </LegalLink>
                      <LegalLink type="tos">
                        {t('footer.tosLink', 'Terms and Conditions')}
                      </LegalLink>
                    </span>
                  </p>

                  <p className={styles.contactInfo}>
                    {customProvider && provider.address ? (
                      <span>{provider.address}</span>
                    ) : (
                      <a
                        href="https://goo.gl/maps/qrQR3sBR8HzzkGzF7"
                        rel="noopener"
                        target="_blank">
                        Joan Muyskenweg 37, 1114 AN Amsterdam, Nederland
                      </a>
                    )}

                    {this.renderSupportItem()}

                    <span>{process.env.RELEASE_VERSION}</span>

                    {customProvider && provider.supportPhoneNo ? (
                      <a
                        href={`tel:${provider.supportPhoneNo.replace(
                          /\s\(\)/,
                          ''
                        )}`}>
                        {provider.supportPhoneNo}
                      </a>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column width={3}>
                {/* Keep empty for Intercom */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {adminAccess && (
            <div
              onClick={() => window.loadAsteroids()}
              style={{
                fontSize: '8px',
                color: 'rgba(192,192,192,.1)',
                position: 'absolute',
                left: '45%',
                right: '45%',
                textAlign: 'center',
                bottom: '0px',
              }}>
              ▲
            </div>
          )}
        </Container>
      </Segment>
    );
  }
}

export default withTranslation()(Footer);
