import React from 'react';
import { Form } from 'semantic';
import PropTypes from 'prop-types';
import DateField from 'components/form-fields/Date';
import { isEmpty } from 'lodash-es';
import { addDays, subDays, startOfDay } from 'date-fns';

import SearchContext from '../Context';
import './date-range.less';

export default class DateRangeFilter extends React.Component {
  static contextType = SearchContext;

  onChange = (evt, { name: part, value }) => {
    const { name } = this.props;

    const range = { ...this.context.filters[name] } || {};

    if (value) {
      range[part] = value;
    } else {
      // start = null => reset range to avoid
      if (part === '$gte') {
        delete range['$lte'];
      }
      delete range[part];
    }

    if (part === '$gte' && range['$lte'] && range['$gte'] > range['$lte']) {
      delete range['$lte'];
    } else if (
      part === '$lte' &&
      range['$gte'] &&
      range['$lte'] < range['$gte']
    ) {
      // dont delete the start date to ensure we have a range
      const newStartDate = startOfDay(new Date(range['$lte']));

      range['$gte'] = newStartDate;
    }

    value = isEmpty(range) ? null : range;

    if (value && this.props.maxRange) {
      const { $gte, $lte } = value;
      const target = part === '$gte' ? '$lte' : '$gte';
      if ($gte && $lte) {
        const diff = Math.abs($gte - $lte);
        const maxDayRangeInMs = this.props.maxRange * 24 * 60 * 60 * 1000;

        if (diff > maxDayRangeInMs) {
          range[target] = new Date(
            target === '$gte'
              ? subDays($lte, this.props.maxRange)
              : addDays($gte, this.props.maxRange)
          );
        }
      }
    }

    if (this.props.onChange) {
      this.props.onChange(evt, { name, value });
    } else {
      this.context.onFilterChange({ name, value });
    }
  };

  render() {
    const { label, name } = this.props;

    return (
      <Form.Field className="date-range">
        <label>{label}</label>
        <Form.Group>
          <DateField
            name="$gte"
            start
            value={this.context.filters[name]?.['$gte']}
            placeholder="No Start"
            onChange={this.onChange}
            clearable
          />
          <span className="divider">&ndash;</span>
          <DateField
            name="$lte"
            end
            value={this.context.filters[name]?.['$lte']}
            placeholder="Present"
            onChange={this.onChange}
            clearable
          />
        </Form.Group>
      </Form.Field>
    );
  }
}

DateRangeFilter.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string.isRequired,
};

DateRangeFilter.defaultProps = {
  object: true,
  value: {},
};
