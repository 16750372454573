import React, { useEffect } from 'react';
import styles from './MobileVerificationWidget.module.less';
import { Button, Icon, Message } from 'semantic';
import { useUser } from 'contexts/user';
import { Trans, useTranslation } from 'react-i18next';
import { joiErrorDetailsToObject, request } from 'utils/api';
import { Formik } from 'formik';
import InputField from 'components/form-fields/formik/InputField';

const requestVerification = async (type: 'email' | 'mobile') => {
  return request({
    method: 'POST',
    path: `/1/users/me/verification/${type}`,
  });
};

/**
 * Returns all pending verifications of the given type
 * @param type
 */
const getPendingVerifications = async (type: 'email' | 'mobile') => {
  try {
    const pendingVerifications = await request({
      method: 'GET',
      path: `/1/users/me/verification/${type}`,
    });
    return Array.isArray(pendingVerifications?.data)
      ? pendingVerifications?.data
      : [];
  } catch (e) {
    console.error(`Failed to get ${type} verification code`, e);
    return [];
  }
};

const MobileVerificationWidget: React.FC<never> = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [hasVerified, setHasVerified] = React.useState(
    sessionStorage.getItem('phoneVerified')
  );

  const [canRetry, setCanRetry] = React.useState(true);
  const [resendInterval, setResendInterval] = React.useState<any>();
  const [hasNonExpiredVerification, setHasNonExpiredVerification] =
    React.useState(false);

  useEffect(() => {
    getPendingVerifications('mobile').then((pendingVerifications) => {
      setHasNonExpiredVerification(pendingVerifications.length > 0);
    });
  }, []);

  const submitHandler = async (formValues: any, formikBag: any) => {
    try {
      const response = await request({
        method: 'POST',
        path: '/1/users/me/verification/mobile/confirm',
        body: formValues,
      });
      setHasVerified(response?.data?.success);
      sessionStorage.setItem('phoneVerified', response?.data?.success);
    } catch (error: any) {
      if (Array.isArray(error?.details)) {
        formikBag.setErrors(joiErrorDetailsToObject(error));
      } else {
        formikBag.setStatus(error?.message);
      }
    }
  };

  if (hasVerified) {
    return (
      <Message positive>
        {t(
          'mobileVerificationWidget.success',
          'Your phone number has been verified!'
        )}
      </Message>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{ code: '' }}
      onSubmit={submitHandler}>
      {({ status, handleSubmit, dirty, isSubmitting }) => (
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <Icon name="mobile" size="big" />
            {!hasNonExpiredVerification && (
              <>
                <div className={styles.content}>
                  <Trans
                    i18nKey="mobileVerificationWidget.unverified"
                    phoneNumber={user?.contact?.phoneNo}>
                    <p>
                      Your phone number <strong>{user.contact?.phoneNo}</strong>{' '}
                      has not yet been verified.
                    </p>
                  </Trans>
                </div>
                <Button
                  onClick={() =>
                    requestVerification('mobile').then(() =>
                      setHasNonExpiredVerification(true)
                    )
                  }>
                  {t(
                    'mobileVerificationWidget.sendCode',
                    'Send verification code'
                  )}
                </Button>
              </>
            )}
            {hasNonExpiredVerification && (
              <>
                <Trans i18nKey="mobileVerificationWidget.guide">
                  <p className={styles.text}>
                    Enter the 6-digit code sent to your phone.
                  </p>
                </Trans>
                <div className={styles.content}>
                  <InputField
                    placeholder={t(
                      'mobileVerificationWidget.codePlaceholder',
                      'Enter the code: e.g. AEF5FB'
                    )}
                    wrapperStyle={{ flex: 1, width: '100%' }}
                    style={{ flex: 1, width: '100%' }}
                    name="code"
                  />
                </div>
                <Button
                  basic
                  icon={canRetry ? 'sync' : 'check'}
                  value={t('mobileVerificationWidget.resend', 'Resend')}
                  disabled={!canRetry}
                  onClick={async () => {
                    clearInterval(resendInterval);
                    setCanRetry(false);
                    requestVerification('mobile').then(() =>
                      setHasNonExpiredVerification(true)
                    );
                    setResendInterval(
                      setInterval(async () => {
                        setCanRetry(true);
                      }, 10_000)
                    );
                  }}
                />
                <Button
                  disabled={!dirty || isSubmitting}
                  onClick={() => handleSubmit()}>
                  {t('mobileVerificationWidget.verify', 'Verify')}
                </Button>
              </>
            )}
          </div>
          {status && (
            <Message error>
              <p>{status}</p>
            </Message>
          )}
        </div>
      )}
    </Formik>
  );
};

export default MobileVerificationWidget;
