import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';

interface ResourceLinkProps {
  route: string;
  linkName: string;
  style?: CSSProperties;
  children?: ReactNode;
}

export default function ResourceLink({
  route,
  linkName,
  style,
  children,
}: ResourceLinkProps) {
  return (
    <Link to={route} style={style}>
      <>
        {linkName}
        {children}
      </>
    </Link>
  );
}
