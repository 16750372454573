import React from 'react';

import { Loader, Message, Segment, Table } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EvseControllerBulkActionExecution } from 'types/evse-controller-bulk-action-execution';
import { formatDateTime } from 'utils/date';
import EvseControllerBulkActionExecutionStatus from '../EvseControllerBulkActionExecutionStatus';
import { EVSE_CONTROLLERS_BACKGROUND_JOBS_PATH } from '../utils';

type Props = {
  evseControllerBulkActionExecutions: EvseControllerBulkActionExecution[];
  loading: boolean;
};

export default function TableView({
  evseControllerBulkActionExecutions,
  loading,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="list">
      {!loading && !evseControllerBulkActionExecutions.length && (
        <Message>
          {t(
            'evseControllersBackgroundJobs.noBackgroundJobs',
            'There are no Jobs to display.'
          )}
        </Message>
      )}

      {!loading && evseControllerBulkActionExecutions.length > 0 && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>
                {t('evseControllersBackgroundJobs.columnJobId', 'Job Id')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('evseControllersBackgroundJobs.columnJobType', 'Job Type')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t(
                  'evseControllersBackgroundJobs.columnNumberOfItems',
                  'No. Of Items'
                )}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t(
                  'evseControllersBackgroundJobs.columnStartedAt',
                  'Started At'
                )}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('evseControllersBackgroundJobs.columnEndedAt', 'Ended At')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('evseControllersBackgroundJobs.columnNote', 'Note')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t(
                  'evseControllersBackgroundJobs.columnCreatedBy',
                  'Created By'
                )}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('evseControllersBackgroundJobs.columnStatis', 'Status')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {evseControllerBulkActionExecutions.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Link
                      to={`${EVSE_CONTROLLERS_BACKGROUND_JOBS_PATH}/${item.id}`}>
                      {item.id}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{item.actionType}</Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`${EVSE_CONTROLLERS_BACKGROUND_JOBS_PATH}/${item.id}`}>
                      {item.workflowExecutionsNumber}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{formatDateTime(item.startedAt)}</Table.Cell>
                  <Table.Cell>
                    {item.endedAt ? formatDateTime(item.endedAt) : '-'}
                  </Table.Cell>
                  <Table.Cell>{item.note}</Table.Cell>
                  <Table.Cell>
                    {item.creatorUser && (
                      <Link to={`/accounts/users/${item.creatorUser.id}`}>
                        {item.creatorUser.contact?.firstName}{' '}
                        {item.creatorUser.contact?.lastName}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <EvseControllerBulkActionExecutionStatus
                      evseControllerBulkActionExecution={item}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
      {loading && (
        <Segment style={{ height: '100px' }}>
          <Loader active>{t('loading.loading')}</Loader>
        </Segment>
      )}
    </div>
  );
}
