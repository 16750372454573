import { Form, Modal, Button, Label } from 'semantic';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import InputField from 'components/form-fields/formik/InputField';
import { request } from 'utils/api';
import SelectField from 'components/form-fields/formik/SelectField';

const authorizationContext = {
  AUTH_REQUEST:
    'To check whether this token was allowed to charge, we made a remote call to the eMSP that issued it.',
  WHITELIST:
    'To check whether this token was allowed to charge, we use the local cache in our database (without making a remote call).',
};

const Status = ({ status }: { status: string }) => {
  const background = (() => {
    switch (status) {
      case 'Accepted':
        return 'olive';
      case 'Blocked':
        return 'red';
      case 'Invalid':
        return 'orange';
      default:
        return 'black';
    }
  })();
  return <Label color={background}>{status}</Label>;
};

interface Props {
  evseControllerId: string;
  connectors: number[];
  trigger: React.ReactNode;
}

const TestTokenAuthorization: React.FC<Props> = ({
  evseControllerId,
  connectors = [],
  trigger,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [response, setResponse] = React.useState<{
    idTag: string;
    connectorId: number;
    status: string;
    type: string;
  } | null>(null);

  const verifyTokenAuthorization = async ({
    idTag,
    connectorId,
    forceRemote,
  }: any) => {
    setResponse(null);
    const { data } = await request({
      method: 'POST',
      path: `/1/evse-controllers/${evseControllerId}/test-token-authorization`,
      body: { idTag, forceRemote, connectorId: parseInt(connectorId, 10) },
    });
    setResponse({
      idTag,
      connectorId,
      status: data?.result?.status,
      type: data?.result?.authType,
    });
  };

  return (
    <Formik
      initialValues={{ idTag: '' }}
      onSubmit={(values) => verifyTokenAuthorization(values)}>
      {({ handleSubmit, isSubmitting }) => (
        <Modal
          closeIcon
          closeOnDimmerClick={false}
          trigger={trigger}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}>
          <Modal.Header>
            {t('testTokenAuthorizationModal.title', 'Test token authorization')}
          </Modal.Header>
          <Modal.Content>
            <p>
              {t(
                'testTokenAuthorizationModal.help',
                'This form simulates an authorization request from a charging station. Use it to test whether a token would be authorized to charge on this charging station. No sessions will be started.'
              )}
            </p>
            <Form>
              <InputField
                name="idTag"
                label={t('testTokenAuthorization.idTag', 'ID Tag (UID)')}
              />
              <br />
              <SelectField
                name="connectorId"
                options={(connectors || []).map((connector) => ({
                  key: connector.toString(),
                  value: connector.toString(),
                  text: connector.toString(),
                }))}
                label="Connector"
              />
              <br />
            </Form>
            {response && (
              <div style={{ padding: '1rem 0' }}>
                <p>
                  Response for <strong>{response?.idTag}</strong> on connector{' '}
                  {response?.connectorId}:
                </p>
                <Status status={response.status} />
                <p style={{ marginTop: '1rem' }}>
                  <strong>{response.type}</strong>-{' '}
                  {authorizationContext[response.type]}
                </p>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button loading={isSubmitting} onClick={handleSubmit} type="submit">
              {t('submit', 'Verify')}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

export default TestTokenAuthorization;
