import React from 'react';
import { Menu, MenuItem } from 'semantic-ui-react';

Menu.Item = function ThemedMenuItem({ ...props }) {
  const activeStyle = {};

  activeStyle.borderColor = `var(--primary-color)`;

  return <MenuItem activeStyle={activeStyle} {...props} />;
};

export default Menu;
