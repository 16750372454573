import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button, Dropdown, Label } from 'semantic';
import HelpTip from 'components/HelpTip';
import EditAccount from 'components/modals/EditAccount';
import { currentUserCanAccess } from 'utils/roles';
import { Search } from 'components';
import Actions from '../Actions';
import { formatDateTime } from 'utils/date';
import { request } from '../../../utils/api';
import ResourceLink from 'components/Link/ResourceLink';
import {
  setResourceProviderIdInSessionStorage,
  removeResourceProviderIdInSessionStorage,
} from 'utils/providers';

function formatAccountType(type) {
  if (type === 'individual') {
    return <Label icon="circle-user" content="Individual" />;
  }
  return <Label icon="building" content="Organization" />;
}

export default function AccountsTable() {
  const { t } = useTranslation();
  const { items, reload, loading } = useContext(Search.Context);

  const writeAccess = currentUserCanAccess('accounts', 'write');

  if (!items.length || loading) return null;

  const fetchAccount = async (id) => {
    const { data } = await request({
      method: 'GET',
      path: `/1/accounts/${id}`,
    });

    return data;
  };

  return (
    <>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('accounts.columnName', 'Name')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('accounts.columnType', 'Type')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('accounts.columnCreated', 'Created')}

              <HelpTip
                title={t('accounts.columnCreated', 'Created')}
                text={t(
                  'accounts.columnCreatedTooltip',
                  'This is the date the product was created.'
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('accounts.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            const externalLinkRoute = `/accounts/${item.id}`;
            const externalLinkSearchItemProviderId = item.providerId;
            const externalLinkName =
              item.name || t('accounts.noName', 'No Name');

            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <ResourceLink
                    route={externalLinkRoute}
                    searchItemProviderId={externalLinkSearchItemProviderId}
                    linkName={externalLinkName}
                  />
                </Table.Cell>
                <Table.Cell>{formatAccountType(item.type)}</Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                <Table.Cell textAlign="center">
                  <EditAccount
                    getData={() => fetchAccount(item.id)}
                    onSave={() => reload()}
                    trigger={
                      <Button
                        basic
                        icon="pen-to-square"
                        title="Edit"
                        disabled={!writeAccess}
                        onClick={() =>
                          setResourceProviderIdInSessionStorage(
                            item?.providerId
                          )
                        }
                      />
                    }
                    onClose={removeResourceProviderIdInSessionStorage}
                  />
                  <Dropdown button basic text="More">
                    <Dropdown.Menu direction="left">
                      <Actions item={item} onReload={reload} />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
