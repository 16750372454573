import React from 'react';
import { Divider, Grid, Segment } from 'semantic';
import { withTranslation } from 'react-i18next';

import TermsDonutChart from 'components/analytics/TermsDonutChart';

class MspEquipment extends React.Component {
  render() {
    const { baseFilter = {}, t } = this.props;
    return (
      <div>
        <Divider hidden />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsDonutChart
                  collection="sessions"
                  aggField="connectorType"
                  operation="count"
                  title="Connector Types"
                  filter={baseFilter}
                  termsSize={15}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsDonutChart
                  collection="sessions"
                  aggField="ocpiCredentialsName"
                  operation="count"
                  title="OCPI Source"
                  filter={baseFilter}
                  termsSize={15}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />
      </div>
    );
  }
}

export default withTranslation()(MspEquipment);
