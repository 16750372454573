import React from 'react';
import { Segment, Message, Button, Form } from 'semantic';

import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';
import { parseToken } from 'utils/token';
import { request, joiErrorDetailsToObject } from 'utils/api';
import { parseQueryString } from 'utils/url';

import { Formik } from 'formik';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useUser } from 'contexts/user';
import { getRequiredFieldMessage } from 'utils/validate';
import * as Yup from 'yup';

import InputField from 'components/form-fields/formik/InputField';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import LegalLink from 'components/LegalLink';
import PhoneCountry from 'components/form-fields/formik/PhoneCountry';
import PhoneNumber from 'components/form-fields/formik/PhoneNumber';
import { ROAD_PROVIDER_ID } from 'utils/env';

const getTokenAndJWT = function (props: any) {
  const token = (parseQueryString(props.location) as any).token;
  const jwt = parseToken(token);
  return {
    token,
    jwt,
  };
};

function getRedirect(location: any) {
  const { redirect } = parseQueryString(location) as any;
  if (!redirect) return null;
  return redirect.replace('://', '');
}

interface IAcceptInviteForm {
  firstName: string;
  lastName: string;
  password: string;
  phoneNo: string;
  phoneCountryCode: string;
  acceptedTerms: boolean;
  acceptedPrivacy: boolean;
  newsletter: boolean;
}

export default function AcceptInvite({ location }: { location: any }) {
  const [token] = React.useState(getTokenAndJWT({ location }).token);
  const [jwt] = React.useState(getTokenAndJWT({ location }).jwt);
  const { provider, setToken } = useUser() as any;

  // XXX why have 2 endpoints for this?
  const path = location.pathname.includes('/invites/accept/create-user')
    ? '/1/invites/accept/create-user'
    : '/1/auth/accept-invite';

  const { i18n, t } = useTranslation();

  React.useEffect(() => {
    jwt && jwt.lng && i18n.changeLanguage(jwt.lng);
  }, [jwt]);

  async function onSubmit(formValues: IAcceptInviteForm, formikBag: any) {
    try {
      const { data } = await request({
        method: 'POST',
        path: path,
        body: {
          ...formValues,
          token,
        },
      });
      setToken(data.token);
      window.location = getRedirect(location) || '/';
    } catch (error: any) {
      if (Array.isArray(error?.details)) {
        formikBag.setErrors(joiErrorDetailsToObject(error));
      }
      formikBag.setStatus(error?.message);
    }
  }

  const labels = {
    firstName: t('formLabel.firstName', 'First Name'),
    lastName: t('formLabel.lastName', 'Last Name'),
    password: t('formLabel.password', 'Password'),
    phoneNo: t('formLabel.phoneNo', 'Phone Number'),
    phoneCountryCode: t('formLabel.phoneCountryCode', 'Extension'),
    acceptedTerms: t('formLabel.acceptedTerms', 'Terms of Service'),
    acceptedPrivacy: t('formLabel.acceptedPrivacy', 'Privacy Statement'),
    newsletter: t('formLabel.newsletter', 'Newsletter'),
  };

  return (
    <PageCenter>
      <LogoTitle title={t('title.acceptInvite', 'Accept Invite')} />
      <Segment.Group>
        <Segment padded>
          {(!token || !jwt) && (
            <p>
              <Message error size="huge">
                <Trans i18nKey="message.notValidInviteToken">
                  <Message.Header>No valid token found</Message.Header>
                  <Message.Content>
                    Please ensure you either click the email link in the email
                    or copy paste the link in full.
                  </Message.Content>
                </Trans>
              </Message>
            </p>
          )}
          {token && jwt && (
            <>
              <Trans i18nKey="message.acceptTokenInfo" />
              <Formik
                validationSchema={Yup.object({
                  firstName: Yup.string().required(
                    getRequiredFieldMessage(t, labels.firstName)
                  ),
                  lastName: Yup.string().required(
                    getRequiredFieldMessage(t, labels.lastName)
                  ),
                  password: Yup.string().required(
                    getRequiredFieldMessage(t, labels.password)
                  ),
                  phoneNo: Yup.string().required(
                    getRequiredFieldMessage(t, labels.phoneNo)
                  ),
                  phoneCountryCode: Yup.string().required(
                    getRequiredFieldMessage(t, labels.phoneCountryCode)
                  ),
                  acceptedTerms: Yup.boolean().oneOf(
                    [true],
                    getRequiredFieldMessage(t, labels.acceptedTerms)
                  ),
                  acceptedPrivacy: Yup.boolean().oneOf(
                    [true],
                    getRequiredFieldMessage(t, labels.acceptedPrivacy)
                  ),
                  newsletter: Yup.boolean().required(
                    getRequiredFieldMessage(t, labels.newsletter)
                  ),
                })}
                initialValues={{
                  firstName: jwt.firstName as string,
                  lastName: jwt.lastName as string,
                  password: '',
                  phoneNo: '',
                  phoneCountryCode: '',
                  acceptedTerms: false,
                  acceptedPrivacy: false,
                  newsletter: true,
                }}
                onSubmit={onSubmit}>
                {({ isSubmitting, handleSubmit, status }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Field>
                        <InputField
                          required
                          autoComplete="firstName"
                          label={labels.firstName}
                          type="text"
                          name="firstName"
                        />
                      </Form.Field>
                      <Form.Field>
                        <InputField
                          required
                          autoComplete="lastname"
                          name="lastName"
                          label={labels.lastName}
                          type="text"
                        />
                      </Form.Field>
                      <Form.Field>
                        <InputField
                          required
                          autoComplete="new-password"
                          name="password"
                          label={labels.password}
                          type="password"
                        />
                      </Form.Field>

                      <div
                        className="required field"
                        style={{ marginTop: '1em', margin: 0 }}>
                        <label>{labels.phoneNo}</label>
                      </div>
                      <Form.Field>
                        <Form.Group widths="equal">
                          <PhoneCountry name="phoneCountryCode" />
                          <PhoneNumber required name="phoneNo" />
                        </Form.Group>
                      </Form.Field>

                      <CheckboxField
                        name="acceptedTerms"
                        required
                        label={
                          <label>
                            <Trans i18nKey="signupAccount.acceptTermsOfServices">
                              I accept the&nbsp;
                              <LegalLink type="tos">Terms of Service</LegalLink>
                              .
                            </Trans>
                          </label>
                        }
                      />

                      <CheckboxField
                        name="acceptedPrivacy"
                        required
                        hideErrorLabel
                        label={
                          <label
                            style={{ maxWidth: '600px', marginRight: '-20px' }}>
                            <Trans
                              i18nKey="signupAccount.acceptPrivacy"
                              platformName={provider?.platformName}>
                              I understand that{' '}
                              {{ platformName: provider?.platformName }} will
                              process my personal data in accordance with the
                              <LegalLink type="privacy">
                                Privacy Statement
                              </LegalLink>
                              .
                            </Trans>
                          </label>
                        }
                      />

                      {[provider?.id, provider?.parentProviderId].includes(
                        ROAD_PROVIDER_ID
                      ) && (
                        <CheckboxField
                          hideErrorLabel
                          label={
                            <label>
                              <Trans i18nKey="message.signupNewsletter">
                                I want to receive newsletters with relevant
                                information about my charge card.
                              </Trans>
                            </label>
                          }
                          name="newsletter"
                        />
                      )}
                      {status && <Message error content={status} />}

                      <Button
                        style={{ margin: 0 }}
                        type="submit"
                        fluid
                        primary
                        size="large"
                        content={t('button.acceptInvite', 'Create Account')}
                        loading={isSubmitting}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </>
          )}
        </Segment>
      </Segment.Group>
    </PageCenter>
  );
}
