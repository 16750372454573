import React, { useEffect, useReducer } from 'react';

import { useUser } from 'contexts/user';
import { request } from 'utils/api';
import { Placeholder } from 'semantic';
import { useTranslation } from 'react-i18next';

import { Box } from 'components';
import { ChargeCard } from 'components';

import { RequestCardModal } from 'components/modals/RequestCard';
import { useDashboard } from '../context';
import styles from 'components/Box/styles.module.less';
import { AccountProvider } from 'contexts/account';

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

const PlaceHolderWidget = () => {
  return (
    <Placeholder style={{ flex: '1 0 auto' }}>
      <Placeholder.Image style={{ height: '160px' }} />
    </Placeholder>
  );
};

function Card({ item }) {
  return (
    <ChargeCard>
      <ChargeCard.Title>{item.nameOnCard}</ChargeCard.Title>
      <ChargeCard.Subtitle>{item.billingPlan?.name}</ChargeCard.Subtitle>
      <ChargeCard.Number>{item.token?.visualNumber || ' '}</ChargeCard.Number>
    </ChargeCard>
  );
}

export default function MyCardsStats() {
  const { user, provider, canAccess } = useUser();
  const { t } = useTranslation();
  const { isAccount } = useDashboard();
  const [state, setState] = useLocalState({
    items: [],
    loading: true,
  });

  async function fetchMyCards() {
    setState({
      loading: true,
    });
    try {
      const { data, meta } = await request({
        method: 'GET',
        path: `/1/cards/mine`,
      });

      setState({
        loading: false,
        items: data,
        total: meta.total,
      });
    } catch (e) {
      setState({
        loading: false,
        error: e,
      });
    }
  }

  useEffect(() => {
    fetchMyCards(user?.id);
  }, [user]);

  const cards = isAccount
    ? state.items
    : state.items.filter((item) => item.userId === user.id);

  const hasNoCards = !state.loading && cards.length === 0;

  return (
    <div>
      <Box title={t('dashboardHome.myCardsTitle', 'My Cards')} loading={true}>
        <Box.Content>
          {state.loading && <PlaceHolderWidget />}

          {hasNoCards && (
            <ChargeCard type="placeholder">
              <ChargeCard.Title>
                {t(
                  'dashboardHome.myCardsCardTitleV2',
                  `{{platformName}} Card`,
                  {
                    platformName: provider.platformName,
                  }
                )}
              </ChargeCard.Title>
              <ChargeCard.Subtitle>
                {t(
                  'dashboard.myCardsCardPromo',
                  'The most affordable charge card'
                )}
              </ChargeCard.Subtitle>
            </ChargeCard>
          )}

          {!state.loading && !hasNoCards && (
            <ChargeCard.Group>
              {cards?.map((item) => (
                <Card key={item.id} item={item} />
              ))}
            </ChargeCard.Group>
          )}
        </Box.Content>
        <Box.Actions>
          {hasNoCards && canAccess('cards', 'write') ? (
            <AccountProvider>
              <RequestCardModal
                key={Date.now()}
                onSuccess={() => {
                  fetchMyCards();
                }}
                trigger={
                  <a className={styles.actionLink}>
                    {'+ '}
                    {t('dashboardHome.myCardsRequestCard', 'Request a Card')}
                  </a>
                }
              />
            </AccountProvider>
          ) : (
            <Box.ActionLink to="/my-cards">
              {t('dashboardHome.myCardsViewMyCards', 'View My Cards')}
            </Box.ActionLink>
          )}
        </Box.Actions>
      </Box>
    </div>
  );
}
