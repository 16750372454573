import React from 'react';
import { Divider, Grid, Segment, Statistic } from 'semantic';
import { withTranslation } from 'react-i18next';
import {
  AggregateTimeSeries,
  ErrorBoundary,
  SeriesChart,
} from 'react-tectonic';

import {
  changeFilterId,
  defaultColors,
  mergeFilters,
} from 'utils/visualizations';
import CardinalityStatistic from 'components/analytics/CardinalityStatistic';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import TermsTable from 'components/analytics/TermsTable';

class CpoLocations extends React.Component {
  render() {
    const { baseFilter = {}, t } = this.props;

    const locationFilter = mergeFilters(
      changeFilterId(baseFilter, 'locationId'),
      {
        terms: [{ deleted: false }],
      }
    );

    return (
      <ErrorBoundary>
        <Divider hidden />
        <Divider hidden />

        <Statistic.Group
          widths="two"
          style={{ justifyContent: 'space-between' }}>
          <CardinalityStatistic
            collection="locations"
            label="Locations"
            filter={locationFilter}
          />
          <CardinalityStatistic
            collection="evsecontrollers"
            label="EVSEs"
            filter={baseFilter}
          />
        </Statistic.Group>

        <Divider hidden />
        <Divider hidden />

        <AggregateTimeSeries
          collection="locations"
          operation="count"
          dateField="createdAt"
          filter={locationFilter}>
          <SeriesChart
            title={t('analyticsSessions.newLocations', 'New Locations')}
            titleAlign="center"
            chartType="bar"
            height={350}
            valueField="count"
            valueFieldLabel={t(
              'analyticsSessions.newLocations',
              'New Locations'
            )}
            color={defaultColors[0]}
          />
        </AggregateTimeSeries>

        <Divider hidden />
        <Divider hidden />

        <AggregateTimeSeries
          collection="evsecontrollers"
          operation="count"
          dateField="createdAt"
          filter={baseFilter}>
          <SeriesChart
            title={t('analyticsSessions.evsecontrollers', 'New EVSEs')}
            titleAlign="center"
            chartType="bar"
            height={350}
            valueField="count"
            valueFieldLabel={t(
              'analyticsSessions.evsecontrollers',
              'New EVSEs'
            )}
            color={defaultColors[0]}
          />
        </AggregateTimeSeries>

        <Divider hidden />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsDonutChart
                  collection="locations"
                  aggField="status"
                  title="Location Status"
                  filter={locationFilter}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsDonutChart
                  collection="locations"
                  aggField="publishingMode"
                  title="Location Privacy"
                  filter={locationFilter}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column computer={16} mobile={16}>
              <Segment basic>
                <TermsTable
                  height={156}
                  collection="locations"
                  aggField="publishingMode"
                  title="Location Privacy Absolute"
                  valueField="count"
                  valueFieldName="Amount"
                  filter={locationFilter}
                  termsSize={2}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsTable
                  collection="locations"
                  aggField="country"
                  title="Countries"
                  valueField="count"
                  valueFieldName="Locations"
                  labelFormatter={(key) => key.toUpperCase()}
                  filter={locationFilter}
                  termsSize={15}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsTable
                  collection="locations"
                  aggField="city"
                  title="Cities"
                  valueField="count"
                  valueFieldName="Locations"
                  filter={locationFilter}
                  termsSize={15}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsTable
                  collection="locations"
                  aggField="facilities"
                  title="Facilities"
                  valueField="count"
                  valueFieldName="Locations"
                  filter={locationFilter}
                  termsSize={15}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsTable
                  collection="locations"
                  aggField="parking_type"
                  title="Parking Types"
                  valueField="count"
                  valueFieldName="Locations"
                  filter={locationFilter}
                  termsSize={15}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ErrorBoundary>
    );
  }
}

export default withTranslation()(CpoLocations);
