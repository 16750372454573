import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { get } from 'lodash-es';
import { request } from 'utils/api';
import { formatDuration } from 'utils/date';
import {
  Form,
  Icon,
  Modal,
  Progress,
  Table,
  Message,
  Button,
  Segment,
} from 'semantic';
import { processFile } from 'utils/csv';
import { tokenBatchImportMapping } from 'utils/constants';
import { withTranslation } from 'react-i18next';
import modal from 'helpers/modal';
import sleep from 'utils/sleep';

import { Layout, Search, SearchFilters } from 'components';

function extractBatchErrors(batchResults) {
  return [...batchResults.skipped, ...batchResults.errors];
}

async function storeBatchWithRetry(batch, batchId, attempt = 1) {
  try {
    if (attempt > 3) {
      return {
        totalAssigned: data.totalAssigned,
        errors: [{ message: 'retries exceeded', count: 1 }],
      };
    }

    const { data } = await request({
      method: 'POST',
      path: `/1/tokens/batch/${batchId}/import`,
      body: { batch: batch.map((body) => ({ ...body })) },
    });
    return {
      totalAssigned: data.totalAssigned,
      errors: extractBatchErrors(data),
    };
  } catch (error) {
    if (error.message.match(/failed to fetch/i)) {
      await sleep(1000);
      return await storeBatchWithRetry(batch, batchId, attempt + 1);
    } else {
      return {
        totalAssigned: 0,
        errors: [
          {
            message: error.name,
            count: attempt,
          },
        ],
      };
    }
  }
}

function chunk(arr, len) {
  const chunks = [];
  let i = 0;
  const n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
}

async function batchCreate(objects, batchId, percentFn) {
  let errors = [];
  let totalAssigned = 0;
  percentFn({
    total: objects.length,
    percent: 1,
    current: 0,
  });
  let i = 0;
  const batchSize = 10000;
  const batches = chunk(objects, batchSize);
  for (const batch of batches) {
    const { totalAssigned: assigned, errors: batchErrors } =
      await storeBatchWithRetry(batch, batchId);
    totalAssigned = assigned;
    errors = errors.concat(batchErrors);
    percentFn({
      percent: (i / objects.length) * 100,
      total: objects.length,
      current: i,
    });
    i += batchSize;
    await sleep(20);
  }
  percentFn({
    percent: 100,
    total: objects.length,
    current: objects.length,
  });
  return { totalAssigned, errors };
}

const defaultState = {
  step: 1,
  loading: false,
  items: null,
  mapping: null,
  progressPercent: 0,
  error: null,
  totalAssigned: null,
};

const ImportSessions = ({ t, close, batchId }) => {
  const [state, setState] = useState({ ...defaultState });

  const drop = async (acceptedFiles, rejectedFiles) => {
    setState((state) => ({ ...state, loading: true, error: null }));
    if (rejectedFiles.length) {
      const error = new Error(
        t(
          'tokenBatch.fileCriteriaError',
          'File did not meet criteria: {{name}}',
          {
            name:
              rejectedFiles[0].errors?.[0]?.message ||
              rejectedFiles[0]?.file?.name,
          }
        )
      );
      setState((state) => ({ ...state, error, loading: false }));
      return;
    }
    if (acceptedFiles.length > 1) {
      const error = new Error(
        t(
          'tokenBatch.fileLimitError',
          'Oops, you can only upload 1 file at a time'
        )
      );
      setState((state) => ({ ...state, error, loading: false }));
      return;
    }

    setState((state) => ({ ...state, step: 2, loading: true }));

    try {
      const result = await processFile(
        tokenBatchImportMapping,
        acceptedFiles[0]
      );
      setState((state) => ({ ...state, loading: false, ...result }));
    } catch (e) {
      setState((state) => ({ ...state, loading: false, error: e }));
    }

    return;
  };

  const commit = () => {
    const { step, items } = state;
    setState({ ...state, step: step + 1, loading: true, startTs: Date.now() });
    batchCreate(items, batchId, (progress) =>
      setState((state) => ({ ...state, progress }))
    )
      .then(({ totalAssigned, errors }) =>
        setState((state) => ({
          ...state,
          loading: false,
          errors,
          totalAssigned,
        }))
      )
      .catch((error) =>
        setState((state) => ({ ...state, loading: false, error }))
      );
  };

  const getFilterMapping = () => {
    return {
      errorMessage: {
        type: 'dropdown',
      },
    };
  };

  const onDataNeeded = async (filters) => {
    let errors = state.errors;

    if (filters.errorMessage) {
      errors = state.errors.filter(
        (error) => error.message === filters.errorMessage
      );
    }

    return {
      data: errors,
    };
  };

  const renderErrorSummary = () => {
    const errorMessages = [
      'Token not part of Batch',
      'Visible ID already assigned RFID',
    ];

    return (
      <Search.Provider
        onDataNeeded={onDataNeeded}
        filterMapping={getFilterMapping()}>
        {(context) => {
          const { items } = context;
          return (
            <>
              <Segment>
                <Layout horizontal spread stackable>
                  <SearchFilters.Modal>
                    <SearchFilters.Dropdown
                      name="errorMessage"
                      label={t('cards.errorMessage', 'Error message')}
                      options={errorMessages.map((errorMessage) => ({
                        text: errorMessage,
                        value: errorMessage,
                      }))}
                    />
                  </SearchFilters.Modal>
                </Layout>
              </Segment>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {t('tokenBatch.columnMessage', 'Message')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('tokenBatch.columnCount', 'Count')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.map((item, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{item.message}</Table.Cell>
                      <Table.Cell textAlign="right">{item.count}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          );
        }}
      </Search.Provider>
    );
  };

  const renderCommit = () => {
    const { loading, progress, error, errors, items, totalAssigned, startTs } =
      state;
    return (
      <div>
        {error && <Message error content={error.message} />}
        {!loading && startTs > 0 && (
          <p>
            Import took{' '}
            {formatDuration(Math.round((Date.now() - startTs) / 1000))}.
          </p>
        )}
        {loading ? (
          <Progress
            label={`${t('tokenBatch.importingData', 'Importing Data')} (${
              progress.current
            }/${progress.total})`}
            percent={progress.percent}
            indicating
          />
        ) : errors && errors.length ? (
          <div>
            <p>
              {t(
                'tokenBatch.importDataImported',
                'Imported {{itemsCount}} Tokens.',
                {
                  itemsCount: items.length,
                }
              )}
            </p>
            {totalAssigned && (
              <p>
                {t(
                  'tokenBatch.totalDataAssigned',
                  'Assigned {{totalAssigned}} Tokens.',
                  {
                    totalAssigned: totalAssigned,
                  }
                )}
              </p>
            )}
            <p>
              {t(
                'tokenBatch.importDataError',
                'Received the following errors while importing:',
                {
                  errorCount: errors.length,
                }
              )}
            </p>
            {renderErrorSummary(errors)}
          </div>
        ) : (
          <p>
            {t(
              'tokenBatch.importDataSuccess',
              'Imported {{itemsCount}} records successfully!',
              {
                itemsCount: items.length,
              }
            )}
          </p>
        )}
      </div>
    );
  };

  const renderPreview = () => {
    const { error, loading, items, mapping, numColumnsMatched } = state;
    return (
      <div>
        {error && <Message error content={error.message} />}
        {loading && (
          <Progress
            label={t('tokenBatch.analyzingData', 'Analyzing Data')}
            percent={100}
            indicating
          />
        )}
        {items && (
          <div>
            <p>
              {t(
                'tokenBatch.analyzingDataSuccess',
                'Matched up {{numColumnsMatched}} columns over {{itemsCount}} records. Preview:',
                {
                  numColumnsMatched,
                  itemsCount: items.length,
                }
              )}
            </p>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    {Object.keys(mapping).map((key) => (
                      <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.slice(0, 5).map((item, i) => (
                    <Table.Row key={i}>
                      {Object.keys(mapping).map((key) => (
                        <Table.Cell key={key}>{get(item, key)}</Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderUploadForm = () => {
    return (
      <div>
        <Form>
          <Dropzone
            maxSize={25 * 1024 * 1024}
            onDrop={(acceptedFiles, rejectedFiles) =>
              drop(acceptedFiles, rejectedFiles)
            }>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                className={
                  isDragActive
                    ? 'ui icon blue message upload-dropzone-active'
                    : 'ui icon message upload-dropzone'
                }
                style={{ cursor: 'pointer', outline: 0 }}>
                <Icon name="file regular" />
                <input {...getInputProps()} />
                <div className="content">
                  {isDragActive ? (
                    <p>{t('tokenBatch.dropfiles', 'Drop files here...')}</p>
                  ) : (
                    <p>
                      {t(
                        'tokenBatch.dropfilesCSV',
                        'Drop a CSV file here, or click to select one for upload.'
                      )}
                    </p>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </Form>
      </div>
    );
  };

  const onClick = () => {
    setState({
      open: false,
      touched: false,
      ...defaultState,
    });
    close();
  };

  const { step, loading, error } = state;
  return (
    <>
      <Modal.Header>
        {t('tokenBatches.import.header', 'Import Token Batch')}
      </Modal.Header>
      <Modal.Content>
        {step === 1 && renderUploadForm()}
        {step === 2 && renderPreview()}
        {step === 3 && renderCommit()}
      </Modal.Content>
      <Modal.Actions>
        {step === 2 && (
          <Button
            content={t('tokenBatches.import.reset', 'Reset')}
            icon="arrow-rotate-right"
            disabled={step === 1 || step > 2}
            onClick={() => setState((state) => ({ ...state, ...defaultState }))}
          />
        )}
        {step === 2 && (
          <Button
            content={t('importSessions.import', 'Import')}
            icon="check"
            primary
            disabled={!!loading || !!error}
            onClick={() => commit()}
          />
        )}
        {step === 3 && (
          <Button
            content={t('importSessions.done', 'Done')}
            primary
            disabled={!!loading}
            loading={loading}
            onClick={onClick}
          />
        )}
      </Modal.Actions>
    </>
  );
};

export default modal(withTranslation()(ImportSessions));
