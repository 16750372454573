import React from 'react';
import { request } from 'utils/api';

import { Form, Message, Modal, Header, Button } from 'semantic';

import { withTranslation } from 'react-i18next';

import { formatFullStatus } from 'utils/formatting';
import modal from 'helpers/modal';

class BlockToken extends React.Component {
  state = {
    token: { ...this.props.token },
  };

  onSubmit = async () => {
    const { token } = this.props;
    this.setState({
      loading: true,
    });

    try {
      await request({
        method: 'POST',
        path: `/1/tokens/${token.id}/block`,
        body: {
          isBlocked: this.state.token.isBlocked,
        },
      });
      this.props.close();
      this.props.onSave();
      this.setState({
        loading: false,
      });
    } catch (e) {
      this.setState({
        error: e,
      });
    }
  };

  render() {
    const { t } = this.props;
    const { loading, error, token } = this.state;

    const changeActiveState = (isActive) =>
      this.setState({
        token: { ...token, ...{ isActive: isActive, isBlocked: !isActive } },
      });

    return (
      <>
        <Modal.Header>{t('blockToken.header', 'Block Token')}</Modal.Header>
        <Modal.Content>
          <Form error={Boolean(error)} onSubmit={() => this.handleSubmit()}>
            {error && <Message error content={error.message} />}

            <Form.Checkbox
              label={t('blockToken.isActive', 'Is Active?')}
              name="isActive"
              checked={token.isActive || false}
              onChange={(e, { name, checked }) => changeActiveState(checked)}
            />

            <Form.Checkbox
              label={t('blockToken.isBlocked', 'Is Blocked?')}
              name="isBlocked"
              checked={token.isBlocked || false}
              onChange={(e, { name, checked }) => changeActiveState(!checked)}
            />

            <Header as="h3">Sync Status</Header>
            <p>
              {formatFullStatus(
                this.props.token.ocpiStatus,
                this.props.token.eclearingStatus
              )}
            </p>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content={t('blockToken.save', 'Save')}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(BlockToken));
