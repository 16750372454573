import React, { useState, useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form, Divider, Button, Message } from 'semantic';
import { request } from '../../../../utils/api';
import FormVerticalSpace from '../../components/atoms/FormVerticalSpace';
import { useUser } from '../../../../contexts/user';
import CheckboxField from '../../../../components/form-fields/formik/CheckboxField';
import SelectField from '../../../../components/form-fields/formik/SelectField';
import { isAccountAdmin } from '../../../../utils/roles';
import { getNotifyEvseRecipientOptions } from '../../../../helpers/account';
import SettingsShell from '../../components/wrappers/SettingsShell';
import FormHeader from 'screens/Settings/components/atoms/FormHeader';

interface NotificationsFormValues {
  user: {
    newsletter: boolean;
  };
  account: {
    notifyEVSEIssues: boolean;
    notifyEVSEIssuesRecipient: string;
  };
}

export default function Notifications() {
  // @ts-ignore until the user context is typed
  const { user, fetchUser, provider } = useUser();
  const { t } = useTranslation();

  const [formValues, setFormValues] = React.useState({
    user,
    account: { notifyEVSEIssues: true, notifyEVSEIssuesRecipient: 'evseOwner' },
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const isNewsletterFeatureEnabledForProvider = useMemo(() => {
    return provider.isNewsletterFeatureEnabled;
  }, [provider]);

  useEffect(() => {
    getNotificationSettings();
  }, []);

  async function getNotificationSettings() {
    if (!isAccountAdmin(user)) {
      return;
    }
    try {
      const response = await request({
        method: 'GET',
        path: '/1/accounts/self',
      });
      setFormValues((state) => ({ ...state, account: response.data }));
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  const handleSubmit = async (values: NotificationsFormValues) => {
    try {
      await request({
        method: 'PATCH',
        path: '/1/users/me',
        body: values.user,
      });
      if (isAccountAdmin(user)) {
        await request({
          method: 'PATCH',
          path: '/1/accounts/self',
          body: values.account,
        });
      }
      await fetchUser();
      setSuccess(true);
      setErrorMessage('');
    } catch (error: any) {
      setSuccess(false);
      setErrorMessage(error.message);
    }
  };

  const notifyEVSEIssuesRecipientOptions = useMemo(() => {
    return getNotifyEvseRecipientOptions(t).map((option) => ({
      key: option.text,
      value: option.value,
      text: option.text,
    }));
  }, [t]);

  return (
    <SettingsShell>
      <Formik
        initialValues={{
          user: {
            newsletter: formValues.user.newsletter,
          },
          account: {
            notifyEVSEIssues: formValues.account.notifyEVSEIssues,
            notifyEVSEIssuesRecipient:
              formValues.account.notifyEVSEIssuesRecipient,
          },
        }}
        onSubmit={handleSubmit}
        enableReinitialize>
        {({ handleSubmit, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit} loading={isSubmitting}>
            <FormHeader
              size="large"
              pb={5}
              pt={5}
              text={t('settings.personal.notifications.title', 'Notifications')}
            />
            <Divider />
            {errorMessage && <Message error content={errorMessage} />}
            {success && (
              <Message
                success
                content={t('messages.updated', 'Successfully updated!')}
              />
            )}
            <p>
              {t(
                'settings.personal.notifications.description',
                'Select and manage notifications you would like to receive from E-Flux by Road.'
              )}
            </p>

            {isNewsletterFeatureEnabledForProvider && (
              <>
                <FormHeader
                  size="medium"
                  mt={20}
                  mb={15}
                  text={t(
                    'settings.personal.notifications.subHeader.newsletter',
                    'Newsletter'
                  )}
                />
                <CheckboxField
                  name="user.newsletter"
                  label={t(
                    'settings.personal.notifications.signupNewsletter',
                    'I want to receive newsletters with relevant information about my charge card and/or subscription'
                  )}
                  toggle
                />
              </>
            )}

            {isAccountAdmin(user) && (
              <>
                <FormHeader
                  size="medium"
                  mt={20}
                  mb={15}
                  text={t(
                    'settings.personal.notifications.subHeader.chargingStations',
                    'Charging Stations'
                  )}
                />

                <CheckboxField
                  name="account.notifyEVSEIssues"
                  label={t(
                    'settings.personal.notifications.notifyEVSEIssues',
                    'Send notifications when my charging stations are experiencing problems.'
                  )}
                  toggle
                />

                <FormVerticalSpace />

                {values.account.notifyEVSEIssues && (
                  <SelectField
                    name="account.notifyEVSEIssuesRecipient"
                    label={t(
                      'settings.personal.notifications.notifyEVSEIssuesRecipient',
                      'Send notifications to'
                    )}
                    options={notifyEVSEIssuesRecipientOptions}
                    required
                  />
                )}
              </>
            )}

            <FormVerticalSpace size={40} />

            <Button type="submit" style={{ marginLeft: 0 }}>
              {t(
                'settings.personal.notifications.updateNotifications',
                'Update Notifications'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </SettingsShell>
  );
}
