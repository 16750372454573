import React from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';

import styled from 'styled-components';
import SearchBox from './SearchBox';

import { numberWithDots } from 'utils/formatting';
import { useTranslation } from 'react-i18next';
import { GOOGLE_KEY } from 'utils/env';

const ClusterMarker = styled.div`
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

//const fetcher = (...args) => fetch(...args).then((response) => response.json());

const Marker = ({ $hover, selected, background, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        ...($hover || selected
          ? {
              height: 40,
              width: 40,
              cursor: 'pointer',
              marginTop: -20,
              marginLeft: -20,
            }
          : {
              height: 20,
              width: 20,
              marginTop: -10,
              marginLeft: -10,
            }),
        borderRadius: 50,
        border: '2px solid #fff',
        background,
      }}
    />
  );
};

const colorForStatus = (status) => {
  if (status === 'CHARGING') return 'blue';
  if (status === 'AVAILABLE') return 'green';
  if (status === 'NOT_AVAILABLE') return 'red';
  return 'grey';
};

export default function ClusterMap({
  config,
  search,
  fetchData,
  height,
  onSelect = () => {},
  defaultZoom = 10,
  emitError,
  ...props
}) {
  const { t } = useTranslation();
  const mapRef = React.useRef();
  const mapApiRef = React.useRef();
  const [bounds, setBounds] = React.useState(null);
  const [zoom, setZoom] = React.useState(10);

  const [points, setPoints] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [meta, setMeta] = React.useState({ total: 0 });
  const [loading, setLoading] = React.useState(true);
  const [selectedId, setSelectedId] = React.useState();

  async function triggerFetchData({ bounds }) {
    setLoading(true);
    if (!bounds || !bounds.length) return;
    try {
      const { data, meta } = await fetchData({
        geoBox: [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]],
        ],
      });

      setData(data);
      setMeta(meta);

      setPoints(
        data.map((location) => ({
          type: 'Feature',
          properties: {
            cluster: false,
            locationId: location.id,
            status: location.status,
          },
          geometry: location.geoLocation,
        }))
      );

      emitError(null);
    } catch (e) {
      emitError(e);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    triggerFetchData({ bounds });
  }, [bounds]);

  function addPlace(place) {
    //this.setState({ places: place });
  }

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20, minPoints: 6 },
  });

  const map = mapRef.current;
  const mapApi = mapApiRef.current;

  return (
    <div
      style={{
        height: height ? `${height}px` : '600px',
        width: '100%',
        position: 'relative',
      }}>
      {search && map && mapApi && (
        <SearchBox map={map} mapApi={mapApi} addplace={addPlace} />
      )}

      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_KEY,
          libraries: ['places'],
        }}
        defaultCenter={
          config.ipLatitude
            ? { lat: config.ipLatitude, lng: config.ipLongitude }
            : {
                //Amsterdam default
                lat: 52.368,
                lng: 4.9036,
              }
        }
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          map.setClickableIcons(false);
          mapRef.current = map;
          mapApiRef.current = maps;
        }}
        onClick={() => setSelectedId(undefined)}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}>
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;

          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <ClusterMarker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                style={{
                  width: `${25 + (pointCount / points.length) * 40}px`,
                  height: `${25 + (pointCount / points.length) * 40}px`,
                }}
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );
                  map.setZoom(expansionZoom);
                  map.panTo({ lat: latitude, lng: longitude });
                }}>
                {pointCount}
              </ClusterMarker>
            );
          }

          return (
            <Marker
              key={`location-${cluster.properties.locationId || cluster.id}`}
              lat={latitude}
              lng={longitude}
              background={colorForStatus(cluster.properties.status)}
              selected={cluster.properties.locationId === selectedId}
              onClick={() => {
                setSelectedId(cluster.properties.locationId);

                onSelect(
                  data.find(
                    (location) => location.id === cluster.properties.locationId
                  )
                );
              }}
            />
          );
        })}
      </GoogleMapReact>
      <p
        style={{
          marginTop: '4px',
          fontStyle: 'italic',
          color: '#666',
        }}>
        {loading
          ? t('loading.loading', 'Loading...')
          : t(
              'locationsMap.resultsShowing',
              `Showing {{size}} out of {{total}} locations`,
              {
                total: numberWithDots(meta.total),
                size: data?.length || 0,
              }
            )}
      </p>
    </div>
  );
}
