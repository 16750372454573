import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useSingleSignOnValidation = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    flavour: Yup.string().required(
      t('sso.form.flavour.validationError', 'Please select your IdP provider')
    ),
    signInUrl: Yup.string()
      .required(
        t(
          'sso.form.signInUrl.validationError',
          'Has to be a valid URL starting with https://'
        )
      )
      .matches(
        /^https:\/\/.+/,
        t(
          'sso.form.signInUrl.validationError',
          'Has to be a valid URL starting with https://'
        )
      ),
    signingCertificate: Yup.string().required(
      t(
        'sso.form.signingCertificate.validationError',
        'Please provide IdP signing certificate'
      )
    ),
    tokenTTL: Yup.number().required(
      t(
        'sso.form.tokenTTL.validationError',
        'Please provide a valid time (seconds) after which to expire the token'
      )
    ),
    allowedDomains: Yup.array().of(Yup.string()) /*.min(1, t(
      'sso.form.allowedDomains.validationError',
      'Please provide at least one allowed domain'
    )).required(t(
      'sso.form.allowedDomains.validationError',
      'Please provide at least one allowed domain'
    ))*/,
  });
};
