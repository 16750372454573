import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Table, Dropdown, Icon } from 'semantic';

import { Link } from 'react-router-dom';

import { Search, SecretSpan, HelpTip } from 'components';
import { get } from 'lodash-es';

import Actions from '../Actions';
import { formatDateTime } from 'utils/date';
import { formatContractId } from '../../../utils/formatting';

const TokensTable = () => {
  const { t } = useTranslation();
  const { items, reload, loading } = useContext(Search.Context);

  if (!items.length || loading) return null;

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>
              {t('tokens.columnCustomId', 'Identifier')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('tokens.columnUID', 'UID')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('tokens.columnUser', 'User')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('tokens.columnBillingPlan', 'Billing Plan')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t('tokens.columnActive', 'Active?')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t('tokens.columnBlocked', 'Blocked?')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('tokens.columnCreated', 'Created')}
              <HelpTip
                title={t('tokens.columnCreated', 'Created')}
                text={t(
                  'tokens.columnCreatedToolTip',
                  'This is the date and time the token was created.'
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('tokens.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>
                {item.visualNumber ||
                  item.customId ||
                  formatContractId(item.emi3Contract)}{' '}
                {item.hasMaintenanceAccess && (
                  <Icon
                    name="screwdriver-wrench"
                    style={{
                      float: 'right',
                      marginTop: '2px',
                      color: '#999',
                    }}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <SecretSpan value={item.uid} />
              </Table.Cell>
              <Table.Cell>
                {item.userId && item.user ? (
                  <Link to={`/accounts/users/${item.userId}`}>
                    {item.user.name}
                  </Link>
                ) : (
                  <span>{t('tokens.noUser', 'No user')}</span>
                )}
              </Table.Cell>
              <Table.Cell>
                {get(
                  item,
                  'billingPlan.name',
                  t('tokens.noBillingPlan', 'No Billing Plan')
                )}
              </Table.Cell>
              <Table.Cell>
                {item.isActive ? t('yes', 'Yes') : t('no', 'No')}
              </Table.Cell>
              <Table.Cell>
                {item.isBlocked ? t('yes', 'Yes') : t('no', 'No')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
              <Table.Cell textAlign="center">
                <Dropdown button basic text="More">
                  <Dropdown.Menu direction="left">
                    <Actions item={item} onReload={reload} />
                  </Dropdown.Menu>
                </Dropdown>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default TokensTable;
