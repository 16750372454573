import React, { useEffect } from 'react';

import { isArray } from 'lodash';
import { Form, Message, Modal, Button } from 'semantic';
import { useFeatures } from 'contexts/features';
import { request } from 'utils/api';

import JSONSchemaInputs from 'components/JSONSchemaInputs';

function getSchema(protocol, method) {
  let schema = null;
  if (protocol && method) {
    schema = protocol.CENTRAL_SYSTEM_COMMANDS.find(
      (c) => c.name === method
    ).requestDefinition;
  }
  return schema;
}

export default function ExecuteEvseCommand(props = { initialValues: {} }) {
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    ...(props.initialValues || {}),
  });
  const [submitted, setSubmitted] = React.useState(false);
  const [protocol, setProtocol] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { hasFeature } = useFeatures();

  useEffect(() => {
    setSubmitted(false);
    setFormValues({ ...(props.initialValues || {}) });
  }, [props.initialValues]);

  useEffect(() => {
    request({
      method: 'GET',
      path: props.ocppProtocolPath,
    })
      .then(({ data }) => {
        setProtocol(data);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [props.ocppProtocolPath]);

  const shouldUseCommandCenter =
    hasFeature('commandcenter_send_single_command') ||
    hasFeature('commandcenter_send_commands_in_bulk');

  const handleSubmit = async () => {
    const {
      evseControllerId,
      chargingStationId,
      chargingStationIds,
      initialValues,
    } = props;
    const schema = getSchema(protocol, formValues.method);
    const commandPayload = JSONSchemaInputs.normalize(
      schema,
      formValues.params
    );
    setSubmitted(true);
    setLoading(true);
    setError(null);

    if (shouldUseCommandCenter) {
      const params =
        isArray(chargingStationIds) && chargingStationIds.length > 0
          ? {
              method: 'POST',
              path: `/1/commands/bulk`,
              body: {
                commands: chargingStationIds.map((id) => ({
                  chargingStationId: id,
                  action: formValues.method,
                  payload: commandPayload,
                })),
              },
            }
          : {
              method: 'POST',
              path: `/1/commands`,
              body: {
                chargingStationId: chargingStationId,
                action: formValues.method,
                payload: commandPayload,
              },
            };
      try {
        await request(params);
        props.onDone();
        setFormValues(initialValues || {});
        setOpen(false);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }

      return;
    }

    try {
      await request({
        method: 'POST',
        path: `/1/evse-controllers/${evseControllerId}/commands`,
        body: {
          method: formValues.method,
          params: commandPayload,
        },
      });
      props.onDone();
      setFormValues(initialValues || {});
      setOpen(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function setField(name, value) {
    setSubmitted(false);

    setFormValues((prevFormValues) => {
      let updatedFormValues;

      if (name === 'method') {
        updatedFormValues = {
          method: value,
          params: {}, // Resetting params
        };
      } else {
        updatedFormValues = {
          ...prevFormValues,
          [name]: value,
        };
      }

      return updatedFormValues;
    });
  }

  const { trigger } = props;
  const schema = getSchema(protocol, formValues.method);

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        setFormValues(props.initialValues || {});
        setSubmitted(false);
      }}
      onOpen={() => {
        setOpen(true);
        setError(null);
      }}
      open={open}>
      <Modal.Header>Execute EVSE Command</Modal.Header>
      <Modal.Content>
        {(props.mixedProtocolsSelected && (
          <Message
            error
            header="Mixed OCPP Protocol Versions Selected"
            content="You have selected multiple charging stations with different protocol versions. You can only send commands to charging stations with the same protocol version."
          />
        )) || (
          <Form
            id="execute-command"
            error={submitted && Boolean(error)}
            onSubmit={() => handleSubmit()}>
            {error && <Message error content={error.message} />}

            {protocol && (
              <Form.Select
                search
                value={formValues.method}
                options={protocol.CENTRAL_SYSTEM_COMMANDS.map((command) => {
                  return {
                    key: command.name,
                    text: command.name,
                    value: command.name,
                  };
                })}
                name="method"
                label="Method"
                required
                onChange={(e, { name, value }) => setField(name, value)}
              />
            )}

            {protocol &&
              formValues.method &&
              protocol.CENTRAL_SYSTEM_COMMAND_DESCRIPTIONS[
                formValues.method
              ] && (
                <Message
                  info
                  header="Command Information"
                  content={protocol.CENTRAL_SYSTEM_COMMAND_DESCRIPTIONS[
                    formValues.method
                  ]
                    .split('\n')
                    .map((line, i) => {
                      return <p key={i}>{line}</p>;
                    })}
                />
              )}

            {schema && (
              <JSONSchemaInputs
                schema={schema}
                params={formValues.params}
                onChange={(params) => setField('params', params)}
              />
            )}
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading || submitted}
          primary
          content="Execute"
          form="execute-command"
        />
      </Modal.Actions>
    </Modal>
  );
}
