import React, { useState } from 'react';

import { Message, Modal, Button } from 'semantic';
import { useTranslation } from 'react-i18next';
import { EvseController } from 'types/evse-controller';
import { isEvseControllersFromSameVendor } from 'utils/evse-controllers';

type Props = {
  evseControllers: EvseController[];
  trigger: React.ReactNode;
  onDone: () => void;
};

export default function ConfirmWithWarningFirmwareUpdateBulk({
  evseControllers,
  onDone,
  trigger,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const evseControllersWithSameVendor =
    isEvseControllersFromSameVendor(evseControllers);

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('confirmWithWarningFirmwareUpdateBulk.title', 'Firmware Update')}
      </Modal.Header>
      <Modal.Content>
        {!evseControllersWithSameVendor && (
          <>
            <Message
              error
              icon="triangle-exclamation"
              header={t(
                'confirmWithWarningFirmwareUpdateBulk.messageTitle',
                'Different vendors for charging stations detected'
              )}
              content={t(
                'confirmWithWarningFirmwareUpdateBulk.messageContent',
                'Some of the selected charging stations are from different vendors. Please review your selection to avoid unexpected results.'
              )}
            />
            <p>
              {t(
                'confirmWithWarningFirmwareUpdateBulk.confirmWithWarning',
                'Would you like to proceed anyway?'
              )}
            </p>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onClick={() => setOpen(false)}
          content={t('confirmWithWarningFirmwareUpdateBulk.back', 'Back')}
        />
        <Button
          primary
          onClick={() => onDone()}
          content={t('confirmWithWarningFirmwareUpdateBulk.confirm', 'Confirm')}
        />
      </Modal.Actions>
    </Modal>
  );
}
