import React, { Dispatch, SetStateAction, useEffect } from 'react';
import WrappedModal from 'components/modal/WrappedModal';
import { Button, Modal, Table, Checkbox } from 'semantic';
import { useModalContext } from 'components/modal/ModalContext';
import { toggle, toggleAll } from './utils';

export type DataColumn<T> = {
  title: React.ReactNode;
  data: (item: T) => React.ReactNode;
};

type UpdateSelectionProps = {
  updateSelection: () => void;
};

type SelectionReviewModalProps<T> = {
  selectedItems: T[];
  onSetSelectedItems: Dispatch<SetStateAction<T[]>>;
  isOpen: boolean;
  onSetIsOpen: Dispatch<SetStateAction<boolean>>;
  selectionReviewColumns: DataColumn<T>[];
  equal: (a: T, b: T) => boolean;
};

const CloseModal = () => {
  const { closeModal } = useModalContext();

  return <Button primary onClick={closeModal} content="Close" />;
};

const UpdateSelection = ({ updateSelection }: UpdateSelectionProps) => {
  const { closeModal } = useModalContext();

  return (
    <Button
      primary
      onClick={() => {
        updateSelection();
        closeModal();
      }}
      content="Update Selection"
    />
  );
};

export default function SelectionReviewModal<T>({
  selectedItems,
  onSetSelectedItems,
  isOpen,
  onSetIsOpen,
  selectionReviewColumns,
  equal,
}: SelectionReviewModalProps<T>) {
  const [updatedSelectedItems, setUpdatedSelectedItems] = React.useState<T[]>(
    []
  );

  useEffect(() => {
    setUpdatedSelectedItems(selectedItems);
  }, [selectedItems]);

  return (
    <WrappedModal isOpen={isOpen} setIsOpen={onSetIsOpen}>
      <Modal.Header>Review Selected Items</Modal.Header>
      <Modal.Content scrolling>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ width: '1%' }} textAlign="center">
                <Checkbox
                  onChange={(e: any, { checked }: { checked: boolean }) => {
                    toggleAll(
                      setUpdatedSelectedItems,
                      updatedSelectedItems,
                      selectedItems,
                      equal,
                      checked
                    );
                  }}
                />
              </Table.HeaderCell>
              {selectionReviewColumns.map((column, index) => (
                <React.Fragment key={index}>{column.title}</React.Fragment>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedItems.map((item, index) => {
              const isSelected = updatedSelectedItems.some((i) =>
                equal(i, item)
              );
              return (
                <Table.Row key={index} active={isSelected}>
                  <Table.Cell style={{ width: '1%' }} textAlign="center">
                    <Checkbox
                      checked={isSelected}
                      onChange={(e: any, { checked }: { checked: boolean }) => {
                        toggle(
                          setUpdatedSelectedItems,
                          updatedSelectedItems,
                          item,
                          equal,
                          checked
                        );
                      }}
                    />
                  </Table.Cell>
                  {selectionReviewColumns.map((column, index) => (
                    <React.Fragment key={index}>
                      {column.data(item)}
                    </React.Fragment>
                  ))}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <UpdateSelection
          updateSelection={() => onSetSelectedItems(updatedSelectedItems)}
        />
        <CloseModal />
      </Modal.Actions>
    </WrappedModal>
  );
}
