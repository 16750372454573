import React from 'react';
import {
  Modal,
  Form,
  Divider,
  Header,
  Grid,
  Message,
  Radio,
  Button,
  Icon,
} from 'semantic';
import { useUser } from 'contexts/user';
import { useAccount } from 'contexts/account';
import { Link } from 'react-router-dom';
import { request } from 'utils/api';
import CountriesField from 'components/form-fields/Countries';
import PostalCode from 'components/form-fields/PostalCode';
import SearchDropDown from 'components/form-fields/SearchDropdown';
import { useFeatures } from 'contexts/features';
import useAccountHasFeature from 'hooks/useAccountHasFeature';
import { useTranslation, Trans } from 'react-i18next';
import { useWizardContext } from '../context';
import {
  getParkingTypeOptions,
  getFacilityOptions,
  getAccessPoliciesOptions,
} from 'utils/locations';
import { PlatformFeature } from 'components';
import { ROAD_PROVIDER_ID } from 'utils/env';

export default ({ onDone, onBack, stepHeader }) => {
  const {
    billingPlan,
    location,
    station,
    addToExisting,
    existingLocation,
    stickerCode,
    wantSticker,
  } = useWizardContext();

  const { t, i18n } = useTranslation();
  const { user, provider } = useUser();
  const { account } = useAccount();
  const { hasFeature, isReady } = useFeatures();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const splitReimburseEnabled = useAccountHasFeature(
    'advanced-billing:split-billing',
    account?.id
  );

  const communityReimburseEnabled = useAccountHasFeature(
    'advanced-billing:community-reimburse',
    account?.id
  );

  const [locationChoice, setLocationChoice] = React.useState('home');

  const [hasPrice, tooglePrice] = React.useState(false);
  const [hasRoaming, toogleRoaming] = React.useState(false);
  const [enablePublicFreeCharging, setEnablePublicFreeCharging] =
    React.useState(false);

  const [creditBilling, setCreditBilling] = React.useState({
    ...user.creditBilling,
  });

  const [accessPolicy, setAccessPolicy] = React.useState();
  const [hasPublishLocation, togglePublishLocation] = React.useState(false);

  const [choice, setChoice] = React.useState();
  const [accessGroupName, setAccessGroupName] = React.useState('');
  const [userAccessId, setUserAccessId] = React.useState(null);
  const [accessGroupCost, setAccessGroupCost] = React.useState(null);
  const [optOutOfScanToPay, setoptOutOfScanToPay] = React.useState(false);

  const [isPublic, setPublic] = React.useState(false);
  const [cost, setCost] = React.useState(null);

  React.useEffect(() => {
    if (locationChoice === 'home') {
      setChoice('employeeReimbursement');
      setCreditBilling({ ...user.creditBilling });
    } else {
      setChoice('businessReimburse');
      setCreditBilling({ ...account?.creditBilling });
    }
  }, [locationChoice]);

  React.useEffect(() => {
    if (existingLocation) {
      tooglePrice(existingLocation.accessPolicy !== 'noReimburse');
      toogleRoaming(existingLocation.accessPolicy !== 'noReimburse');
    }
  }, [existingLocation]);

  const [locationMeta, setLocationMeta] = React.useState({});

  async function handleSubmit() {
    setLoading(true);

    try {
      await request({
        method: 'PATCH',
        path:
          choice === 'employeeReimbursement' ||
          choice === 'privateChargingWithReimbursement'
            ? '/1/users/me'
            : '/1/accounts/self',
        body: {
          creditBilling,
        },
      });
    } catch (e) {
      setError(e);
      setLoading(false);
      return;
    }

    const locationObj = {
      ...location,
      address: `${location.address} ${location.addressLine2}`,
    };
    delete locationObj.addressLine2;

    try {
      let body = {
        evseControllerId: station.id,
        billingPlanId: billingPlan.id,
        enablePublicCharging: hasRoaming,
        enablePublicFreeCharging,
        // if the location is configured to home, we automatically opt the user out of scan-to-pay
        optOutOfScanToPay: locationChoice === 'home' ? true : optOutOfScanToPay,
        costSettings: hasPrice
          ? station.connectors.map((c) => {
              return {
                connectorId: c.connectorId,
                pricePerKwh: Number(cost),
              };
            })
          : undefined,
        ...(!addToExisting
          ? {
              location: {
                accessPolicy: accessPolicy,
                publishingMode: isPublic ? 'public' : 'private',
                ...locationMeta,
                ...locationObj,
              },
            }
          : { locationId: existingLocation.id }),
        // Private Charging mode vars
        privateReimbursementMode:
          choice === 'privateChargingWithReimbursement' ? true : false,
        ...(userAccessId && { userAccessId }),
        ...(accessGroupName && { accessGroupName }),
        ...(accessGroupCost && { accessGroupCost }),
      };

      if (stickerCode) {
        body.evseId = stickerCode;
      } else {
        body.assignNewEvseId = 'true';
      }

      const { data } = await request({
        method: 'POST',
        path: '/1/evse-controllers/link',
        body,
      });

      const dataLayer = window.dataLayer;
      if (dataLayer) {
        dataLayer.push({
          event: 'evse_controller_created',
          billingPlanId: billingPlan.id,
          billingPlanName: billingPlan.name,
        });
      }

      onDone(data);
    } catch (e) {
      setError(e);
      setLoading(false);
      return;
    }

    if (wantSticker) {
      try {
        await request({
          method: 'POST',
          path: `/1/evse-controllers/${station.id}/evse-id-assignment-request`,
          body: {
            source: 'registration',
          },
        });
      } catch (e) {
        setError(e);
      }
    }

    setLoading(false);
  }

  React.useEffect(() => {
    setAccessPolicy(undefined);
    tooglePrice(true);
    toogleRoaming(false);
    togglePublishLocation(true);
    setPublic(false);
    setEnablePublicFreeCharging(false);

    switch (choice) {
      case 'employeeReimbursement':
        toogleRoaming(true);
        setAccessPolicy('employeeReimburse');
        setUserAccessId(undefined);
        break;
      case 'private':
        toogleRoaming(false);
        tooglePrice(false);
        setAccessPolicy('noReimburse');
        togglePublishLocation(false);
        setUserAccessId(undefined);
        break;
      case 'privateChargingWithReimbursement':
        toogleRoaming(false);
        tooglePrice(true);
        setAccessPolicy('employeeReimburse');
        togglePublishLocation(false);
        break;
      case 'businessReimburse':
        toogleRoaming(true);
        setAccessPolicy('businessReimburse');
        setUserAccessId(undefined);
        break;
      case 'noReimburse':
        toogleRoaming(true);
        tooglePrice(false);
        setEnablePublicFreeCharging(true);
        setAccessPolicy('noReimburse');
        togglePublishLocation(false);
        setUserAccessId(undefined);
        break;
    }
  }, [choice]);

  return (
    <>
      <Modal.Header>
        {stepHeader}
        {t('newChargingStationDetails.header', 'Finalize + test')}
      </Modal.Header>

      <Modal.Content>
        {error && <Message error>{error.message}</Message>}

        <Form
          style={{ minHeight: '350px' }}
          id={'NewChargingStationDetails-form'}
          onSubmit={handleSubmit}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                {existingLocation && (
                  <>
                    <Message>
                      {t(
                        'newChargingStationDetails.existingLocationMessage',
                        'This charging station will use the setting the existing location: {{name}}',
                        { name: existingLocation.name }
                      )}
                    </Message>
                    <p>
                      {t(
                        'newChargingStationDetails.existingLocationSettings',
                        'These settings will be apply'
                      )}
                    </p>
                    <p>
                      {t('editLocation.visibility', 'Visibility')}
                      :
                      <br />
                      <b>
                        {existingLocation.publishingMode === 'public'
                          ? t('common.public', 'Public')
                          : t('common.private', 'Private')}
                      </b>
                    </p>
                    <p>
                      {t(
                        'editLocation.accessPolicy',
                        'Access & Billing Policy'
                      )}
                      :
                      <br />
                      <b>
                        {
                          getAccessPoliciesOptions(
                            isReady &&
                              hasFeature('billing_engine_v2__split_billing') &&
                              splitReimburseEnabled,
                            isReady &&
                              hasFeature('billing_engine_v2__split_billing') &&
                              communityReimburseEnabled
                          ).find(
                            (option) =>
                              option.value === existingLocation.accessPolicy
                          )?.text
                        }
                      </b>
                    </p>
                  </>
                )}
                {!existingLocation && (
                  <>
                    {user.accountType === 'organization' && (
                      <Form.Select
                        label={t(
                          'newChargingStationDetails.selectLocation',
                          'Where is the charge station installed?'
                        )}
                        options={[
                          {
                            text: t('newChargingStationDetails.home'),
                            value: 'home',
                          },
                          {
                            text: t(
                              'newChargingStationDetails.businessLocation'
                            ),
                            value: 'business',
                          },
                          {
                            text: t('newChargingStationDetails.public'),
                            value: 'public',
                          },
                        ]}
                        value={locationChoice}
                        onChange={(e, { value }) => {
                          setLocationChoice(value);
                        }}
                      />
                    )}
                    <Form.Field>
                      {locationChoice === 'home' && (
                        <>
                          <Form.Field
                            control={Radio}
                            label={t(
                              'newChargingStationDetails.settingHomeReimbursement',
                              'I want to receive reimbursement for charge sessions (in case you have to settle costs with your company or guests)'
                            )}
                            checked={choice === 'employeeReimbursement'}
                            onChange={() => setChoice('employeeReimbursement')}
                          />
                          <Form.Field
                            control={Radio}
                            label={t(
                              'newChargingStationDetails.settingPrivateNoReimbursement',
                              'I do not want to receive reimbursement for charge sessions and want to use this charge station privately.'
                            )}
                            checked={choice === 'private'}
                            onChange={() => setChoice('private')}
                          />
                          {/* SAAS-1975 only show the privateChargingWithReimbursement if e-flux provider */}
                          {provider.id == ROAD_PROVIDER_ID && (
                            <Form.Field
                              control={Radio}
                              label={t(
                                'newChargingStationDetails.settingPrivateWithReimbursement',
                                'I want to receive reimbursement for charge sessions and want to use this charge station privately. (Only possible with an E-flux charge card)'
                              )}
                              checked={
                                choice === 'privateChargingWithReimbursement'
                              }
                              onChange={() =>
                                setChoice('privateChargingWithReimbursement')
                              }
                            />
                          )}
                        </>
                      )}
                      {locationChoice === 'business' && (
                        <>
                          <Form.Field
                            control={Radio}
                            label={t(
                              'newChargingStationDetails.settingBusinessReimburse',
                              'I want to receive reimbursement for charge sessions (in case you have to settle costs with guests)'
                            )}
                            checked={choice === 'businessReimburse'}
                            onChange={() => setChoice('businessReimburse')}
                          />
                          <Form.Field
                            control={Radio}
                            label={t(
                              'newChargingStationDetails.settingPrivate',
                              'I do not want to receive reimbursement for charge sessions and want to use this charge station privately.'
                            )}
                            checked={choice === 'private'}
                            onChange={() => setChoice('private')}
                          />
                          <Form.Field
                            control={Radio}
                            label={t(
                              'newChargingStationDetails.settingNoReimburse',
                              'Allow everyone to charge here for free. All tokens will be accepted, and no sessions will be reimbursed.'
                            )}
                            checked={choice === 'noReimburse'}
                            onChange={() => setChoice('noReimburse')}
                          />
                        </>
                      )}
                      {locationChoice === 'public' && (
                        <>
                          <Form.Field
                            control={Radio}
                            label={t(
                              'newChargingStationDetails.settingPublicReimburse',
                              'I want to receive reimbursement for all charge sessions. You will receive the reimbursement of the charge sessions every month.'
                            )}
                            checked={choice === 'businessReimburse'}
                            onChange={() => setChoice('businessReimburse')}
                          />
                          <Form.Field
                            control={Radio}
                            label={t(
                              'newChargingStationDetails.settingNoReimburse',
                              'Allow everyone to charge here for free. All tokens will be accepted, and no sessions will be reimbursed.'
                            )}
                            checked={choice === 'noReimburse'}
                            onChange={() => setChoice('noReimburse')}
                          />
                        </>
                      )}
                    </Form.Field>
                    <Divider></Divider>
                    {choice === 'private' && (
                      <Trans i18nKey="newChargingStationDetails.helpPrivateDescription">
                        The charge station can only be used by tokens that are
                        added to an
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`https://help.e-flux.io/${i18n.language}/articles/4872136-alles-over-toegangsgroepen`}>
                          Access Group
                        </a>
                      </Trans>
                    )}
                    {choice === 'employeeReimbursement' && (
                      <p>
                        {t(
                          'newChargingStationDetails.helpEmployeeReimburseDescription',
                          'Automatic settlement of charge sessions with your employer or own company.'
                        )}
                      </p>
                    )}
                    {choice === 'businessReimburse' && (
                      <p>
                        {t(
                          'newChargingStationDetails.helpBusinessReimburseDescription',
                          'All charge costs will be reimbursement with your business account.'
                        )}
                      </p>
                    )}
                    {choice === 'noReimburse' && (
                      <p>
                        {t(
                          'newChargingStationDetails.helpNoReimburseDescription',
                          'Every token can start a session. Costs will not be billed or reimbursed.'
                        )}
                      </p>
                    )}
                  </>
                )}

                {choice === 'privateChargingWithReimbursement' && (
                  <div>
                    <Header as="h3">
                      {t(
                        'newChargingStationDetails.userAccess',
                        'My Access Group'
                      )}
                    </Header>
                    <Form.Input
                      value={accessGroupName}
                      name="accessGroupName"
                      required
                      label={t(
                        'newChargingStationDetails.accessGroupName',
                        'Name'
                      )}
                      type="text"
                      onChange={(_, { value }) => {
                        setAccessGroupName(value);
                      }}
                    />
                    <Form.Field>
                      <SearchDropDown
                        keywordField="searchPhrase"
                        value={userAccessId || ''}
                        required
                        label={t(
                          'newChargingStationDetails.privateUserForAccessGroup',
                          'Select a user who will gain access'
                        )}
                        onDataNeeded={(body) => {
                          return request({
                            path: '/1/users/search/fast',
                            method: 'POST',
                            body: {
                              ...body,
                              accountId: account.id,
                            },
                          });
                        }}
                        onChange={(e, { value }) => {
                          if (!value) {
                            setUserAccessId(undefined);
                          } else {
                            setUserAccessId(value.id);
                          }
                        }}
                      />
                    </Form.Field>
                    <label
                      style={{
                        fontStyle: 'italic',
                        color: '#2A2A2A',
                      }}>
                      {t(
                        'activate.labelExample',
                        'In case you want to add multiple users to have access to your charge station you can add them later in '
                      )}
                      <Link
                        to={'/my-locations/access-groups'}
                        rel="noopener"
                        target="_blank">
                        Access Groups
                      </Link>
                    </label>
                  </div>
                )}

                {[
                  'employeeReimbursement',
                  'businessReimburse',
                  'privateChargingWithReimbursement',
                ].includes(choice) && (
                  <>
                    <Divider />
                    <Header as="h3">
                      {t(
                        'newChargingStationDetails.userAccess',
                        'Setup automatic reimbursement of charge sessions'
                      )}
                    </Header>

                    <Form.Input
                      value={creditBilling.accountHolderName}
                      name="accountHolderName"
                      label={t(
                        'creditBilling.accountHolderName',
                        'Account Holder Name'
                      )}
                      type="text"
                      onChange={(e, { name, value }) =>
                        setCreditBilling({ ...creditBilling, [name]: value })
                      }
                    />
                    <Form.Input
                      required
                      value={creditBilling.ibanNo || ''}
                      name="ibanNo"
                      label={t('common.iban', 'IBAN Number')}
                      type="text"
                      onChange={(e, { value, name }) =>
                        setCreditBilling({ ...creditBilling, [name]: value })
                      }
                    />
                    <Form.Input
                      required
                      value={creditBilling.bicNo || ''}
                      name="bicNo"
                      label={t('common.bic', 'BIC Number')}
                      type="text"
                      onChange={(e, { value, name }) =>
                        setCreditBilling({ ...creditBilling, [name]: value })
                      }
                    />
                    <Form.Group widths="3">
                      <Form.Input
                        width="12"
                        required
                        value={creditBilling.address || ''}
                        name="address"
                        label={t('formLabel.address', 'Address')}
                        type="text"
                        onChange={(e, { name, value }) =>
                          setCreditBilling({ ...creditBilling, [name]: value })
                        }
                      />

                      <Form.Input
                        required
                        value={creditBilling.addressLine2 || ''}
                        name="addressLine2"
                        label={t('formLabel.addressLine2', 'No.')}
                        autoComplete="disabled"
                        type="text"
                        onChange={(e, { name, value }) =>
                          setCreditBilling({ ...creditBilling, [name]: value })
                        }
                      />
                    </Form.Group>

                    <CountriesField
                      required
                      name="countryCode"
                      label={t('formLabel.country', 'Country')}
                      value={creditBilling.countryCode || 'NL'}
                      onChange={(value) => {
                        setCreditBilling({
                          ...creditBilling,
                          countryCode: value,
                        });
                      }}
                    />

                    <Form.Group widths="equal">
                      <PostalCode
                        required
                        countryCode={creditBilling.countryCode || 'NL'}
                        value={creditBilling.postalCode || ''}
                        name="postalCode"
                        label={t('formLabel.postCode', 'Postal Code')}
                        type="text"
                        onChange={(e, { name, value }) =>
                          setCreditBilling({ ...creditBilling, [name]: value })
                        }
                      />

                      <Form.Input
                        required
                        value={creditBilling.city || ''}
                        name="city"
                        label={t('formLabel.city', 'City/Town')}
                        type="text"
                        onChange={(e, { name, value }) =>
                          setCreditBilling({ ...creditBilling, [name]: value })
                        }
                      />
                    </Form.Group>
                  </>
                )}
              </Grid.Column>

              <Grid.Column width={8}>
                {hasPrice && (
                  <div>
                    <Header as="h3">
                      {choice === 'privateChargingWithReimbursement'
                        ? t(
                            'newChargingStationDetails.accessGroupCost',
                            'Access Group Costs'
                          )
                        : t(
                            'newChargingStationDetails.energyCost',
                            'Energy Cost'
                          )}
                    </Header>
                    <p>
                      {t(
                        'newChargingStationDetails.costText',
                        'This tariff will be reimbursed.'
                      )}
                    </p>
                    {choice === 'privateChargingWithReimbursement' ? (
                      <Form.Input
                        value={accessGroupCost || ''}
                        name="costs"
                        type="number"
                        step="0.01"
                        required={hasPrice}
                        label={t(
                          'newChargingStationDetails.costLabel',
                          'Price per Kwh'
                        )}
                        onChange={(e, { value }) => setAccessGroupCost(value)}
                      />
                    ) : (
                      <Form.Input
                        value={cost || ''}
                        name="costs"
                        type="number"
                        step="0.01"
                        required={hasPrice}
                        label={t(
                          'newChargingStationDetails.costLabel',
                          'Price per Kwh'
                        )}
                        onChange={(e, { value }) => setCost(value)}
                      />
                    )}
                  </div>
                )}

                <Divider />
                {hasPublishLocation && !existingLocation && (
                  <>
                    <Header as="h3">
                      {t(
                        'newChargingStationDetails.Publication',
                        'Publication'
                      )}
                    </Header>
                    <p>
                      {t(
                        'newChargingStationDetails.publicationNode',
                        'Public charging stations will be shared other providers, so that it can be found on maps.'
                      )}
                    </p>

                    <Form.Checkbox
                      checked={isPublic}
                      name="publishingMode"
                      label={t(
                        'newChargingStationDetails.publishChargeStation',
                        'Publish Charge Station Location'
                      )}
                      onChange={(e, { checked }) => setPublic(checked)}
                    />

                    {isPublic && (
                      <>
                        <Form.Select
                          options={getParkingTypeOptions()}
                          disabled={!isPublic}
                          value={locationMeta.parking_type}
                          name="parking_type"
                          label={t(
                            'newChargingStationDetails.parkingType',
                            'Parking Type'
                          )}
                          type="text"
                          onChange={(e, { name, value }) =>
                            setLocationMeta({ ...locationMeta, [name]: value })
                          }
                        />
                        <Form.Select
                          multiple
                          disabled={!isPublic}
                          value={locationMeta.facilities || []}
                          options={getFacilityOptions()}
                          name="facilities"
                          label={t(
                            'newChargingStationDetails.availableFacilities',
                            'Available Facilities'
                          )}
                          type="text"
                          onChange={(e, { name, value }) =>
                            setLocationMeta({ ...locationMeta, [name]: value })
                          }
                        />
                      </>
                    )}
                  </>
                )}
                {locationChoice !== 'home' && (
                  <>
                    <Divider />
                    <PlatformFeature
                      feature={'charging-station-ui:scan-to-pay'}>
                      <Header as="h3">
                        {t(
                          'newChargingStationDetails.scanToPay',
                          'Scan to Pay'
                        )}
                      </Header>
                      <p>
                        {t(
                          'newChargingStationDetails.scanToPayNode',
                          'Scan to Pay allows drivers to start, stop and pay for charging sessions anonymously at public charge points using their preferred payment method. Popular payment methods accepted include: Visa, Mastercard, Google Pay & Apple Pay. "Scan to Pay" is enabled for this station by default.'
                        )}
                      </p>

                      <Form.Checkbox
                        checked={optOutOfScanToPay}
                        name="optOutOfScanToPay"
                        label={t(
                          'newChargingStationDetails.optOutOfScanToPay',
                          'Opt-out of "Scan to Pay" functionality'
                        )}
                        onChange={(e, { checked }) =>
                          setoptOutOfScanToPay(checked)
                        }
                      />
                    </PlatformFeature>
                  </>
                )}

                {isPublic && (
                  <Message info style={{ display: 'flex' }}>
                    <Icon name="circle-info" size="huge" />
                    <Message.Content>
                      <p>
                        {t(
                          'newChargingStationDetails.legalNotice1',
                          'Note that if you open a public charging station within the EU after 14 April 2024 you are responsible to ensure its compliance with applicable European Regulation (AFIR) which includes offering drivers a means of anonymous payment for charging activity.'
                        )}
                      </p>
                    </Message.Content>
                  </Message>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onBack()}>{t('common.back', 'Back')}</Button>
        <Button
          loading={loading}
          disabled={loading}
          primary
          content={t('common.create', 'Create')}
          form="NewChargingStationDetails-form"
        />
      </Modal.Actions>
    </>
  );
};
