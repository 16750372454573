import React, { useState } from 'react';

import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash-es';
import { Modal, Icon, Progress, Table, Message, Button } from 'semantic';

import { processFile } from 'utils/csv';
import { evseCommandsMapping } from 'utils/constants';
import { request } from 'utils/api';
import sleep from 'utils/sleep';
import { useFeatures } from 'contexts/features';
import { batchExecute } from 'utils/batch';

type Props = {
  trigger: React.ReactNode;
  onClose?: () => void;
};

export default function ImportEvseCommands({ trigger, onClose }: Props) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[] | null>(null);
  const [mapping, setMapping] = useState<any>(null);
  const [progressPercent, setProgressPercent] = useState(0);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [errors, setErrors] = useState<Error[] | null>(null);
  const [numColumnsMatched, setNumColumnsMatched] = useState(0);
  const { hasFeature } = useFeatures();

  const { t } = useTranslation();
  const sendCommandsInBulkRawCommandService = hasFeature(
    'commandcenter_send_commands_in_bulk'
  );

  const drop = (acceptedFiles: any, rejectedFiles: any) => {
    setLoading(false);
    setError(null);

    const loading = false;
    let error = null;

    if (rejectedFiles.length) {
      error = new Error(
        t(
          'importEvseCommands.fileCriteriaError',
          'File did not meet criteria: {{name}}',
          {
            name: rejectedFiles[0].name,
          }
        )
      );

      setLoading(loading);
      setError(error);
    }
    if (acceptedFiles.length > 1) {
      error = new Error(
        t(
          'importEvseCommands.fileLimitError',
          'Oops, you can only upload 1 file at a time'
        )
      );

      setLoading(loading);
      setError(error);
    }

    setStep(2);
    setLoading(true);

    processFile(evseCommandsMapping, acceptedFiles[0])
      .then((result) => {
        setLoading(false);
        setMapping(result.mapping);
        setItems(result.items);
        setNumColumnsMatched(result.numColumnsMatched);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const commit = () => {
    setStep(step + 1);
    setLoading(true);

    if (sendCommandsInBulkRawCommandService) {
      if (!items) {
        return;
      }

      const commands = items.map((item) => ({
        evseControllerId: item.evseControllerId,
        action: item.commandMethod,
        payload: item.commandParams,
      }));

      request({
        method: 'POST',
        path: `/1/commands/bulk`,
        body: { commands },
      })
        .then(() => setLoading(false))
        .catch((error) => {
          setLoading(false);
          setError(error);
        });

      return;
    }

    batchExecute(
      async function ({
        evseControllerId,
        commandMethod,
        commandParams,
      }: {
        evseControllerId: string;
        commandMethod: string;
        commandParams: any;
      }) {
        const { data } = await request({
          method: 'GET',
          path: `/1/evse-controllers/${evseControllerId}`,
        });
        if (!data) {
          throw new Error(`Could not find EVSE controller ${evseControllerId}`);
        }
        await request({
          method: 'POST',
          path: `/1/evse-controllers/${evseControllerId}/commands`,
          body: {
            method: commandMethod,
            params: commandParams,
          },
        });
        await sleep(500);
      },
      items,
      (progressPercent: any) => setProgressPercent(progressPercent)
    )
      .then((errors: any) => {
        setLoading(false);
        setErrors(errors);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const close = () => {
    if (onClose) {
      onClose();
    }

    setOpen(false);
    reset();
  };

  const reset = () => {
    setStep(1);
    setLoading(false);
    setItems(null);
    setMapping(null);
    setProgressPercent(0);
    setError(null);
    setErrors(null);
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      closeIcon
      trigger={trigger}
      onClose={() => close()}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('importEvseCommands.header', 'Bulk Execute EVSE Commands')}
      </Modal.Header>
      <Modal.Content>
        {step === 1 && (
          <div>
            <p>
              {t(
                'importEvseCommands.modalDescription',
                'The CSV file must contain the following columns: "evseControllerId", "commandMethod", "commandParams". When sending json values for "commandParams" column, double quotes must be escaped with another double quotes. For example: {""key"": ""value""}'
              )}
            </p>
            <Message
              warning
              icon="circle-exclamation"
              content="Bulk executing commands requires extreme care. Executing the wrong commands in bulk can severely impact operations. Please validate your commands with the tech team before running!"
            />
          </div>
        )}
        {step === 1 && <UploadForm drop={drop} />}
        {step === 2 && (
          <Preview
            items={items}
            numColumnsMatched={numColumnsMatched}
            mapping={mapping}
            loading={loading}
            error={error}
          />
        )}
        {step === 3 && (
          <Commit
            items={items}
            progressPercent={progressPercent}
            loading={loading}
            error={error}
            errors={errors}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t('importEvseCommands.reset', 'Reset')}
          secondary
          icon="arrow-rotate-right"
          disabled={step === 1 || step > 2}
          onClick={() => reset()}
        />
        {step === 2 && !error && (
          <Button
            content={t('importEvseCommands.execute', 'Execute Commands')}
            icon="terminal"
            color="red"
            disabled={loading}
            onClick={() => commit()}
          />
        )}
        {step === 3 && (
          <Button
            content={t('importEvseCommands.done', 'Done')}
            primary
            disabled={loading}
            loading={loading}
            onClick={() => close()}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
}

const Preview = ({
  items,
  numColumnsMatched,
  loading,
  error,
  mapping,
}: {
  numColumnsMatched: number;
  mapping: any;
  items: any[] | null;
  loading: boolean;
  error: Error | null;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {error && <Message error content={error.message} />}
      {loading && (
        <Progress
          label={t('importEvseCommands.analyzingData', 'Analyzing Data')}
          percent={100}
          indicating
        />
      )}
      {items && (
        <div>
          <p>
            {t(
              'importSessions.analyzingDataSuccess',
              'Matched up {{numColumnsMatched}} columns over {{itemsCount}} records. Preview:',
              {
                numColumnsMatched,
                itemsCount: items.length,
              }
            )}
          </p>
          <div style={{ overflowX: 'auto' }}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  {Object.keys(mapping).map((key) => (
                    <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {items.slice(0, 5).map((item, i) => (
                  <Table.Row key={i}>
                    {Object.keys(mapping).map((key) => {
                      const value = get(item, key);
                      if (key === 'commandParams') {
                        return (
                          <Table.Cell key={key}>
                            {JSON.stringify(value)}
                          </Table.Cell>
                        );
                      }
                      return <Table.Cell key={key}>{value}</Table.Cell>;
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

const UploadForm = ({
  drop,
}: {
  drop: (acceptedFiles: any[], rejectedFiles: any[]) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Dropzone
      maxSize={10 * 1024 * 1024}
      onDrop={(acceptedFiles, rejectedFiles) =>
        drop(acceptedFiles, rejectedFiles)
      }>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div
            {...getRootProps()}
            className={
              isDragActive
                ? 'ui icon blue message upload-dropzone-active'
                : 'ui icon message upload-dropzone'
            }
            style={{ cursor: 'pointer', outline: 0 }}>
            <Icon name="file regular" />
            <input {...getInputProps()} />
            <div className="content">
              {isDragActive ? (
                <p>
                  {t('importEvseCommands.dropfiles', 'Drop files here...')}
                  Drop files here...
                </p>
              ) : (
                <p>
                  {t(
                    'importEvseCommands.dropfilesCSV',
                    'Drop a CSV file here, or click to select one for upload.'
                  )}
                </p>
              )}
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};

const Commit = ({
  items,
  progressPercent,
  loading,
  error,
  errors,
}: {
  items: any[] | null;
  loading: boolean;
  progressPercent: number;
  error: Error | null;
  errors: Error[] | null;
}) => {
  const { t } = useTranslation();
  const showErrors = !loading && errors && errors.length > 0;
  const showError = !loading && error;
  const showSuccess = !loading && !error && (!errors || errors.length < 1);

  return (
    <div>
      {loading && (
        <>
          <p>Make sure to keep this window open!</p>
          <Progress
            label={t('importEvseCommands.executingData', 'Executing Commands')}
            percent={progressPercent}
            indicating
          />
        </>
      )}

      {showError && <Message error content={error.message} />}

      {showErrors && (
        <div>
          <p>
            {t(
              'importEvseCommands.executeCommandsError',
              'Received {{errorCount}} errors while executing {{itemsCount}} commands:',
              {
                errorCount: errors.length,
                itemsCount: items && items.length,
              }
            )}
          </p>
          <ErrorSummary errors={errors} />
        </div>
      )}

      {showSuccess && (
        <p>
          {t(
            'importEvseCommands.executeCommandsSuccess',
            'Executed {{itemsCount}} commands successfully!',
            {
              itemsCount: items && items.length,
            }
          )}
        </p>
      )}
    </div>
  );
};

const ErrorSummary = ({ errors }: { errors: Error[] }) => {
  const { t } = useTranslation();

  if (!errors || errors.length < 1) {
    return null;
  }

  const errorsByCount: { [error: string]: number } = {};
  errors.forEach((error) => {
    if (!errorsByCount[error.message]) {
      errorsByCount[error.message] = 0;
    }
    errorsByCount[error.message] += 1;
  });

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {t('importEvseCommands.columnError', 'Error')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('importEvseCommands.columnOccurrences', 'Occurrences')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(errorsByCount).map((message, i) => (
          <Table.Row key={i}>
            <Table.Cell>{message}</Table.Cell>
            <Table.Cell textAlign="right">{errorsByCount[message]}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
