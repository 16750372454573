import React, { useState, useContext } from 'react';

import { Form, Message, Modal, Header, Segment, Button } from 'semantic';
import { get, mergeWith, set, omit } from 'lodash-es';

import SearchDropdown from 'components/form-fields/SearchDropdown';
import LanguageSelect from 'components/form-fields/Languages';
import CountriesField from 'components/form-fields/Countries';
import PostalCode from 'components/form-fields/PostalCode';

import PhoneCountry from 'components/form-fields/PhoneCountry';
import PhoneNumber from 'components/form-fields/PhoneNumber';
import { Layout } from 'components/Layout';

import { request } from 'utils/api';
import { getRoleIds, setRolesInBody } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { simpleOptions } from 'utils/form';
import { currentUserIsSuperAdmin, currentUserCanAccess } from 'utils/roles';
import { UserContext } from 'contexts/user';
import AsyncModal from '../../helpers/async-modal';

const EditUser = ({ data, close, onSave }) => {
  const { t } = useTranslation();
  const { provider } = useContext(UserContext);
  const [skipValidation, setSkipValidation] = useState(null);
  const [formValues, setFormValues] = useState(getDefaults());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function getDefaults() {
    if (data.id) {
      return {
        roles: [],
        ...data,
        accountRoleIds: getRoleIds(data.accountRoles || []),
        providerRoleIds: getRoleIds(data.providerRoles || []),
        globalRoleIds: getRoleIds(data.globalRoles || []),
        systemRoleIds: getRoleIds(data.systemRoles || []),
      };
    }
    return {
      defaultLangCode: 'nl',
      roles: ['consumer'],
      ...data,
      accountRoleIds: getRoleIds(data.accountRoles || []),
      providerRoleIds: getRoleIds(data.providerRoles || []),
      globalRoleIds: getRoleIds(data.globalRoles || []),
      systemRoleIds: getRoleIds(data.systemRoles || []),
    };
  }

  async function onSubmit() {
    setLoading(true);

    // don't want to modify the address
    const { address, ...formValuesWithoutAddress } = formValues;
    if (!formValues.providerId) {
      formValuesWithoutAddress.providerId = provider.id;
    }

    const body = setRolesInBody(formValuesWithoutAddress);

    try {
      if (data?.id) {
        await request({
          method: 'PATCH',
          path: `/1/users/${formValues.id}`,
          body,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/users',
          body,
        });
      }
      close();
      onSave();
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }

  const setField = (name, value, convertEmpty = true) => {
    const parsedValue =
      convertEmpty && typeof value === 'string' && value.length === 0
        ? null
        : value;
    setFormValues(set({ ...formValues }, name, parsedValue));
  };

  const isUpdate = !!data.id;

  const name =
    (data.contact &&
      [data.contact.firstName, data.contact.lastName]
        .filter(Boolean)
        .join(' ')) ||
    t('editUser.unknownName', 'Unknown Name');

  const { contact = {} } = formValues;

  const cannotEditBillingInformation = !currentUserCanAccess(
    'users-billing',
    'write'
  );

  return (
    <>
      <Modal.Header>
        {isUpdate
          ? t('editUser.titleEdit', 'Edit {{name}}', {
              name,
            })
          : t('editUser.titleNew', 'New User')}
      </Modal.Header>
      <Modal.Content>
        <Form
          id="edit-user-form"
          autoComplete="disabled"
          error={Boolean(error)}
          onSubmit={() => onSubmit()}>
          {error && <Message error content={error.message} />}

          <Form.Field required>
            <label>{t('editUser.account', 'Account')}</label>
            <SearchDropdown
              value={formValues.accountId}
              disabled={isUpdate}
              objectMode={false}
              onDataNeeded={(body) =>
                request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body: body,
                })
              }
              onChange={(e, { value }) => setField('accountId', value)}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('editUser.accountRoles', 'Account Roles')}</label>
            <p style={{ marginBottom: '0.2em' }}>
              {t(
                'editUser.accountRolesDescription',
                'These roles give the user access within the scope of the account.'
              )}
            </p>
            <SearchDropdown
              multiple
              value={formValues.accountRoleIds}
              disabled={!formValues.accountId}
              objectMode={false}
              onDataNeeded={(body) =>
                request({
                  path: '/1/roles/search',
                  method: 'POST',
                  body: {
                    ...body,
                    context: 'account',
                  },
                })
              }
              onChange={(e, { value }) => setField('accountRoleIds', value)}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('editUser.providerRoles', 'Provider Roles')}</label>
            <p style={{ marginBottom: '0.2em' }}>
              {t(
                'editUser.providerRolesDescription',
                ' Warning! These roles give the user access within the scope of the entire provider.'
              )}
            </p>
            <SearchDropdown
              multiple
              color="teal"
              value={formValues.providerRoleIds}
              objectMode={false}
              onDataNeeded={(body) =>
                request({
                  path: '/1/roles/search',
                  method: 'POST',
                  body: {
                    ...body,
                    context: 'provider',
                  },
                })
              }
              onChange={(e, { value }) => setField('providerRoleIds', value)}
            />
          </Form.Field>

          {currentUserIsSuperAdmin() && (
            <Form.Field>
              <label>{t('editUser.globalRoles', 'Global Roles')}</label>
              <p style={{ marginBottom: '0.2em' }}>
                {t(
                  'editUser.globalRolesDescription',
                  ' Warning! These roles give the user global access.'
                )}
              </p>
              <SearchDropdown
                multiple
                color="orange"
                value={formValues.globalRoleIds}
                objectMode={false}
                onDataNeeded={(body) =>
                  request({
                    path: '/1/roles/search',
                    method: 'POST',
                    body: {
                      ...body,
                      context: 'global',
                    },
                  })
                }
                onChange={(e, { value }) => setField('globalRoleIds', value)}
              />
            </Form.Field>
          )}

          {currentUserIsSuperAdmin() && (
            <Form.Field>
              <label>{t('editUser.systemRoles', 'System Roles')}</label>
              <p style={{ marginBottom: '0.2em' }}>
                {t(
                  'editUser.systemRolesDescription',
                  ' Warning! These roles give the User System level access.'
                )}
              </p>
              <SearchDropdown
                multiple
                color="orange"
                value={formValues.systemRoleIds}
                objectMode={false}
                onDataNeeded={(body) =>
                  request({
                    path: '/1/roles/search',
                    method: 'POST',
                    body: {
                      ...body,
                      context: 'system',
                    },
                  })
                }
                onChange={(e, { value }) => setField('systemRoleIds', value)}
              />
            </Form.Field>
          )}

          {currentUserIsSuperAdmin() && (
            <Form.Field>
              <label>{t('editUser.legacyRoles', 'Legacy System Roles')}</label>
              <p style={{ marginBottom: '0.2em' }}>
                {t(
                  'editUser.legacyRolesDescription',
                  ' Warning! Setting the Super Admin role gives access to all endpoints across all providers.'
                )}
              </p>
              <Form.Select
                multiple
                value={formValues.roles || []}
                options={simpleOptions(['super_admin', 'user', 'consumer'])}
                name="roles"
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
              />
            </Form.Field>
          )}

          <LanguageSelect
            value={formValues.defaultLangCode}
            name="defaultLangCode"
            label={t('editUser.language', 'Language')}
            type="text"
            placeholder={t('editUser.noLanguageSelected', 'None')}
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Form.Input
            value={formValues.email || ''}
            required
            autoComplete="disabled"
            name="email"
            label={t('editUser.email', 'E-mail')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Header as="h4">Contact</Header>
          <Segment>
            <Form.Input
              value={contact.firstName || ''}
              name="contact.firstName"
              autoComplete="disabled"
              label={t('editUser.firstName', 'First Name')}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
            <Form.Input
              value={contact.lastName || ''}
              name="contact.lastName"
              autoComplete="disabled"
              label={t('editUser.lastName', 'Last Name')}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
            <Form.Select
              value={contact.gender || ''}
              options={[
                {
                  value: 'male',
                  text: t('gender.male', 'Male'),
                },
                {
                  value: 'female',
                  text: t('gender.female', 'Female'),
                },
              ]}
              name="contact.gender"
              label={t('editUser.gender', 'Gender')}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
            <Form.Field>
              <label>{t('formLabel.phoneNumber', 'PhoneNumber')}</label>
              <Layout horizontal spread>
                <PhoneCountry
                  value={get(formValues, 'contact.phoneCountryCode', '')}
                  name="contact.phoneCountryCode"
                  autoComplete="disabled"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                />

                <PhoneNumber
                  disabled={!get(formValues, 'contact.phoneCountryCode', '')}
                  value={get(formValues, 'contact.phoneNo', '')}
                  name="contact.phoneNo"
                  autoComplete="disabled"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                />
              </Layout>
            </Form.Field>
          </Segment>

          <Form.Checkbox
            label={t('editUser.signupCompleted', 'Signup Completed?')}
            name="signupCompleted"
            checked={formValues.signupCompleted || false}
            onChange={(e, { name, checked }) => setField(name, checked)}
          />

          <Header as="h4">Credit Billing</Header>

          {(currentUserCanAccess('users-billing', 'read') && (
            <Segment>
              {cannotEditBillingInformation && (
                <Message info>
                  {t(
                    'access.cannotEditBillingInformation',
                    'You do not have sufficient permissions to edit billing information.'
                  )}
                </Message>
              )}

              <Form.Input
                value={get(formValues, 'creditBilling.accountHolderName', '')}
                name="creditBilling.accountHolderName"
                label={t(
                  'editAccount.accountHolderName',
                  'Account Holder Name'
                )}
                autoComplete="disabled"
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
                readOnly={cannotEditBillingInformation}
              />

              <Form.Group widths="2">
                <Form.Input
                  value={get(formValues, 'creditBilling.ibanNo', '')}
                  name="creditBilling.ibanNo"
                  label={t('editAccount.ibanNumber', 'IBAN Number')}
                  autoComplete="disabled"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                  readOnly={cannotEditBillingInformation}
                />

                <Form.Input
                  value={get(formValues, 'creditBilling.bicNo', '')}
                  name="creditBilling.bicNo"
                  label={t('editAccount.bicNo', 'BIC Number')}
                  autoComplete="disabled"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value, true)}
                  readOnly={cannotEditBillingInformation}
                />
              </Form.Group>

              <Form.Group widths="3">
                <Form.Input
                  width="12"
                  value={get(formValues, 'creditBilling.address', '')}
                  name="creditBilling.address"
                  label={t('formLabel.address', 'Address')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                  readOnly={cannotEditBillingInformation}
                />

                <Form.Input
                  value={get(formValues, 'creditBilling.addressLine2', '')}
                  name="creditBilling.addressLine2"
                  label={t('formLabel.addressLine2', 'No.')}
                  autoComplete="disabled"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                  readOnly={cannotEditBillingInformation}
                />
              </Form.Group>

              <CountriesField
                label={t('formLabel.country', 'Country')}
                value={get(formValues, 'creditBilling.countryCode', 'NL')}
                onChange={(value) =>
                  setField('creditBilling.countryCode', value)
                }
                readOnly={cannotEditBillingInformation}
              />

              <Form.Group widths="equal">
                <PostalCode
                  value={get(formValues, 'creditBilling.postalCode', '')}
                  countryCode={get(
                    formValues,
                    'creditBilling.countryCode',
                    'NL'
                  )}
                  name="creditBilling.postalCode"
                  label={t('formLabel.postCode', 'Postal Code')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                  readOnly={cannotEditBillingInformation}
                />
                <Form.Input
                  value={get(formValues, 'creditBilling.city', '')}
                  name="creditBilling.city"
                  label={t('formLabel.city', 'City/Town')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                  readOnly={cannotEditBillingInformation}
                />
              </Form.Group>
            </Segment>
          )) || (
            <Message warning>
              {t(
                'access.cannotViewBillingInformation',
                'You do not have sufficient permissions to view billing information.'
              )}
            </Message>
          )}

          {provider.supportedServices?.includes('exact') && (
            <>
              <Form.Input
                value={get(formValues, 'exactCreditAccountId')}
                name="exactCreditAccountId"
                label={t(
                  'editUser.exactCreditAccountId',
                  'Exact Credit Account ID'
                )}
                autoComplete="disabled"
                type="text"
                readOnly
                onChange={(e, { name, value }) => setField(name, value)}
              />

              <Segment>
                <Header as="h5">
                  {t('editUser.exactStatus', 'Exact Status')}
                </Header>
                <p>
                  {t(
                    'editUser.exactCreditAccountId',
                    'Exact Credit Account ID'
                  )}{' '}
                  = {data.exactCreditAccountId}
                </p>
                <p>
                  {t(
                    'editUser.exactCreditBankAccountId',
                    'Exact Credit Bank Account ID'
                  )}{' '}
                  = {data.exactCreditBankAccountId}
                </p>
                <p>
                  {t(
                    'editUser.exactCreditMandateId',
                    'Exact Credit Mandate ID'
                  )}{' '}
                  = {data.exactCreditMandateId}
                </p>
              </Segment>
            </>
          )}

          {provider.supportedServices?.includes('netsuite') && (
            <>
              <Header as="h5">NetSuite</Header>
              <Segment>
                <Form.Input
                  value={get(formValues, 'netSuiteAccountId')}
                  name="netSuiteAccountId"
                  label={t('editUser.netSuiteAccountId', 'NetSuite Account ID')}
                  autoComplete="disabled"
                  type="text"
                  readOnly
                  onChange={(e, { name, value }) => setField(name, value)}
                />
                <Form.Input
                  value={get(formValues, 'netSuiteMandateId')}
                  name="netSuiteMandateId"
                  label={t('editUser.netSuiteMandateId', 'NetSuite Mandate ID')}
                  autoComplete="disabled"
                  type="text"
                  readOnly
                  onChange={(e, { name, value }) => setField(name, value)}
                />
              </Segment>
            </>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={isUpdate ? t('button.update') : t('button.create')}
          form="edit-user-form"
        />
      </Modal.Actions>
    </>
  );
};

export default AsyncModal(EditUser);
