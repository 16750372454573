import React, { useMemo } from 'react';
import EditEvseControllerSettings from 'components/modals/EditEvseController/tabs/SettingsTab';
import { Tab, Menu, Popup } from 'semantic';
import { useTranslation } from 'react-i18next';
import EditEvseControllerTariffsTab from 'components/modals/EditEvseController/tabs/TariffsTab';
import { EvseControllerModalTabViewProps } from 'components/modals/EditEvseController/types';

export default function EvseControllerModalTabView(
  props: EvseControllerModalTabViewProps
) {
  const { t } = useTranslation();

  const tariffsTabMenuItem = useMemo(() => {
    let disabledReason: string | null = null;
    if (props.formValues.enablePublicFreeCharging) {
      disabledReason = t(
        'evseController.tariffsDisabledPublicFreeCharging',
        'Tariffs are not applicable because any card can charge here for free'
      );
    } else if (!props.formValues.enablePublicCharging) {
      disabledReason = t(
        'evseController.tariffsDisabledPublicCharging',
        'Tariffs are not applicable because charging from other networks (roaming) is not enabled'
      );
    } else if (!props.currency) {
      disabledReason = t(
        'evseController.tariffsDisabledNoCurrency',
        'Location must be set to determine tariff currency'
      );
    }

    const menuItem = (
      <Menu.Item disabled={!!disabledReason}>
        {t('evseController.tariffsTab', 'Tariffs')}
      </Menu.Item>
    );

    if (disabledReason) {
      return (
        <Popup
          wide
          offset={[0, 10]}
          size={'small'}
          position={'right center'}
          content={disabledReason}
          trigger={menuItem}
        />
      );
    }
    return menuItem;
  }, [
    props.formValues.enablePublicCharging,
    props.formValues.enablePublicFreeCharging,
    props.currency,
  ]);

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      panes={[
        {
          menuItem: (
            <Menu.Item>{t('evseController.settingsTab', 'Settings')}</Menu.Item>
          ),
          render: () => <EditEvseControllerSettings {...props} />,
          condition: () => true,
        },
        {
          menuItem: tariffsTabMenuItem,
          render: () => <EditEvseControllerTariffsTab {...props} />,
          condition: () => true,
        },
      ].filter((x) => x.condition())}
    />
  );
}
