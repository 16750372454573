import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import request from 'utils/api/request';
import { toUtcIsoString } from 'utils/date';

type ProviderContext = 'msp' | 'cpo';
type Filters = {
  excluded: boolean;
  currencyConvertMode?: string;
  revenueType?: 'platform' | 'other' | 'total';
};

type ActionProps = {
  month: number;
  year: number;
  providerContext: ProviderContext;
  filters?: Filters;
};

const exportFileFormat = 'csv';
const defaultRecordsLimit = 1000000;
const defaultFilters = {
  excluded: false,
};

export default function Actions({
  month,
  year,
  providerContext,
  filters = defaultFilters,
}: ActionProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const sessionSearchEndpoint: { msp: string; cpo: string } = {
    msp: '/1/sessions/search',
    cpo: '/2/sessions/cpo/search',
  };

  const exportSessionsForRow = async (
    month: number,
    year: number,
    filters: Filters = defaultFilters
  ) => {
    const billingPeriodFrom = toUtcIsoString(year, Math.max(0, month - 1));
    const billingPeriodTo = toUtcIsoString(year, month);

    const providerId = window.user.providerId;
    const exportFileName = `${providerContext}-sessions-${year}-${month}.csv`;

    const appliedFilters = { ...defaultFilters, ...filters };

    try {
      setLoading(true);
      setError(false);

      await request({
        method: 'POST',
        path: sessionSearchEndpoint[providerContext as ProviderContext],
        body: {
          format: exportFileFormat,
          filename: exportFileName,
          from: billingPeriodFrom,
          to: billingPeriodTo,
          ...appliedFilters,
          providerId,
          limit: defaultRecordsLimit,
        },
      });
    } catch (error) {
      console.error(error);
      setError(error);
    }

    setLoading(false);
  };

  return (
    <Dropdown
      button
      basic
      text={t('finance.breakdown.actions.title', 'More')}
      error={error}>
      <Dropdown.Menu direction="left">
        <Dropdown.Item
          text={t('finance.breakdown.actions.download', 'Export')}
          icon="download"
          onClick={() => exportSessionsForRow(month, year)}
        />
        <Dropdown.Item
          text={t('finance.breakdown.actions.downloadInEuros', 'Export in EUR')}
          icon="euro-sign"
          onClick={() => {
            const exportFilters = {
              ...filters,
              currencyConvertMode: 'to-eur',
            };
            return exportSessionsForRow(month, year, exportFilters);
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}
