import React from 'react';
import { withTranslation } from 'react-i18next';
import { Table, Button, Label, Dropdown } from 'semantic';
import { Search } from 'components';
import Actions from '../Actions';
import EditTariffNote from 'components/modals/EditTariffNote';

class TariffNotesTable extends React.Component {
  static contextType = Search.Context;

  render() {
    const { t } = this.props;
    const { items, reload, loading } = this.context;
    if (!items.length || loading) return null;

    return (
      <>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>
                {t('tariffNotes.columnPartyId', 'Party ID')}
              </Table.HeaderCell>
              <Table.HeaderCell width={4}>
                {t('tariffNotes.columnCountryCodes', 'Country Codes')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('tariffNotes.columnEnabled', 'Status')}
              </Table.HeaderCell>
              <Table.HeaderCell width={4}>
                {t('tariffNotes.columnCustomNote', 'Custom Note')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('tariffNotes.columnActions', 'Actions')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Label>{item.partyId}</Label>
                </Table.Cell>
                <Table.Cell>{item.countryCodes.join(', ')}</Table.Cell>
                <Table.Cell textAlign="center">
                  {item.enabled ? (
                    <Label color="olive" content="Enabled" />
                  ) : (
                    <Label color="grey" content="Disabled" />
                  )}
                </Table.Cell>
                <Table.Cell style={{ padding: 0 }}>
                  <div style={{ maxHeight: '7em', overflowX: 'auto' }}>
                    <div style={{ padding: '.5em' }}>
                      {item.customNote ||
                        t(
                          'tariffNotes.noCustomNote',
                          'No custom tariff note specified'
                        )}
                    </div>
                  </div>
                </Table.Cell>

                <Table.Cell textAlign="center">
                  <EditTariffNote
                    onSave={reload}
                    initialValues={item}
                    trigger={<Button basic icon="pen-to-square" title="Edit" />}
                  />
                  <Dropdown button basic text="More">
                    <Dropdown.Menu direction="left">
                      <Actions item={item} onReload={reload} />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default withTranslation()(TariffNotesTable);
