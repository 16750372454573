import React, { useEffect } from 'react';
import InputField from 'components/form-fields/formik/InputField';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import { Button, Divider, Form, Message } from 'semantic';
import { useSingleSignOnValidation } from './SingleSignOn.SAML.validation';
import { useMutation } from '@tanstack/react-query';
import FormHeader from 'screens/Settings/components/atoms/FormHeader';
import SelectField from 'components/form-fields/formik/SelectField';
import TextArea from 'components/form-fields/formik/TextArea';
import TagsField from 'components/form-fields/formik/TagsField';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import SAMLAttributeMappingField from 'screens/Settings/screens/organization/SingleSignOn/SAML/fields/SAMLAttributeMappingField';
import SAMLRoleMappingField from 'screens/Settings/screens/organization/SingleSignOn/SAML/fields/SAMLRoleMappingField';
import { request } from 'utils/api';
import { useUser } from 'contexts/user';
import {
  canAccessAccountEndpoint,
  userCanAccessProviderEndpoint,
} from 'utils/roles';
import styles from './SingleSignOn.module.less';

export type SingleSignOnSAMLFormValues = {
  flavour: string;
  entityId: string;
  acsUrl: string;
  signInUrl: string;
  signingCertificate: string;
  autoProvisioning?: {
    enabled?: boolean;
    attributeMapping?: [];
    accountRolesMapping?: [];
    providerRolesMapping?: [];
  };
  allowedDomains: string[];
  disallowPasswordLogin: boolean;
  tokenTTL: number;
};

export default function SingleSignOnSAMLForm() {
  const { t } = useTranslation();
  const { user, provider } = useUser();

  const canEditProviderRoles =
    user && userCanAccessProviderEndpoint(user, provider, 'roles', 'POST');

  const canEditAccountEndpoint =
    user &&
    user.accountId &&
    canAccessAccountEndpoint(user, user.accountId, 'roles', 'write');

  const initialValueMutation = useMutation<SingleSignOnSAMLFormValues>({
    mutationFn: async () => {
      const { data } = await request({
        method: 'GET',
        path: '/1/accounts/self/sso',
      });

      return data;
    },
    networkMode: 'always',
  });

  useEffect(() => {
    initialValueMutation.mutate();
  }, []);

  const handleSubmitMutation = useMutation<
    SingleSignOnSAMLFormValues,
    unknown,
    SingleSignOnSAMLFormValues
  >({
    mutationFn: async (values) => {
      const { data } = await request({
        method: 'PATCH',
        path: '/1/accounts/self/sso',
        body: values,
      });

      return data;
    },
    onSuccess: (data) => {
      initialValueMutation.data = data;

      return data;
    },
    networkMode: 'always',
  });

  return (
    <>
      <Formik
        initialValues={
          initialValueMutation.data || {
            flavour: '',
            acsUrl: '',
            allowedDomains: [],
            signingCertificate: '',
            signInUrl: '',
            disallowPasswordLogin: false,
            entityId: '',
            tokenTTL: 3600,
          }
        }
        onSubmit={handleSubmitMutation.mutate}
        validationSchema={useSingleSignOnValidation()}
        enableReinitialize={true}>
        {({ handleSubmit, isSubmitting, values, dirty }) => (
          <Form
            onSubmit={handleSubmit}
            loading={handleSubmitMutation.isLoading}>
            <FormHeader
              size="large"
              pb={5}
              pt={5}
              text={t('sso.form.headerSettings', 'Single Sign On')}
            />
            <Divider />
            {initialValueMutation.isError && (
              <Message
                error
                content={t(
                  'sso.form.loadForm',
                  `Sorry but there's been a problem loading your details. Please refresh to try again.`
                )}
              />
            )}
            {handleSubmitMutation.isError && (
              <Message
                error
                content={(handleSubmitMutation.error as any)?.message}
              />
            )}
            {handleSubmitMutation.isSuccess && (
              <Message
                success
                content={t('messages.updated', 'Successfully updated!')}
              />
            )}
            <p style={{ maxWidth: 800 }}>
              {t(
                'sso.form.flavour.description',
                `Please select the Identity Provider (IdP) you wish to configure. In case your IdP is not on the list, please select 'other'.`
              )}
            </p>
            <FormVerticalSpace />
            <SelectField
              name={'flavour'}
              label={t('sso.form.flavour.label', 'Identity Provider')}
              required
              options={[
                {
                  key: 'other',
                  value: 'other',
                  text: t('sso.form.flavour.option.other', 'Other'),
                },
                {
                  key: 'okta',
                  value: 'okta',
                  text: t('sso.form.flavour.option.okta', 'Okta'),
                },
                {
                  key: 'google',
                  value: 'google',
                  text: t(
                    'sso.form.flavour.option.googleWorkspace',
                    'Google Workspace'
                  ),
                },
              ]}
              disabled={
                initialValueMutation.isLoading || initialValueMutation.isError
              }
            />

            <FormVerticalSpace />

            <FormHeader
              pt={5}
              pb={5}
              text={t(
                'sso.form.serviceProviderHeader',
                'Service Provider Settings'
              )}
            />

            <FormVerticalSpace />

            <div style={{ maxWidth: 800 }}>
              <p className="sso-instruction-okta">
                {t(
                  'sso.form.instruction.sp.okta',
                  `Use the below information to configure the Service Provider (SP) within your IdP.`
                )}
              </p>
            </div>

            <FormVerticalSpace />

            <InputField
              name="entityId"
              label={t('sso.form.entityId.label', 'Entity Id')}
              readOnly={true}
              action={{
                icon: 'copy',
              }}
              disabled={
                initialValueMutation.isLoading || initialValueMutation.isError
              }
            />

            <FormVerticalSpace />

            <InputField
              name="acsUrl"
              label={t(
                'sso.form.acsUrl.label',
                'Assertion Consumer Service (ACS)'
              )}
              readOnly={true}
              action={{
                icon: 'copy',
              }}
              disabled={
                initialValueMutation.isLoading || initialValueMutation.isError
              }
            />

            <FormVerticalSpace />

            <FormHeader
              pt={5}
              pb={5}
              text={t(
                'sso.form.identityProviderHeader',
                'Identity Provider Settings'
              )}
            />

            <FormVerticalSpace />

            <div style={{ maxWidth: 800 }}>
              <p className="sso-instruction-okta">
                {t(
                  'sso.form.instruction.idp.okta',
                  `Add the following attributes from your identity provider (IdP) to create an SSO connection for this group.`
                )}
              </p>
            </div>

            <FormVerticalSpace />

            <InputField
              name="signInUrl"
              label={t('sso.form.signInUrl.label', 'Sign in URL')}
              required={true}
              disabled={
                initialValueMutation.isLoading || initialValueMutation.isError
              }
            />

            <FormVerticalSpace />

            <TextArea
              name="signingCertificate"
              label={t(
                'sso.form.signingCertificate.label',
                'Signing Certificate (X509)'
              )}
              disabled={
                initialValueMutation.isLoading || initialValueMutation.isError
              }
              required={true}
              rows={10}
              placeholder={
                '-----BEGIN CERTIFICATE-----\n' +
                'MIIDnDCCAoSgAwIBAgIGAY3WFv8...\n' +
                '-----END CERTIFICATE-----'
              }
            />

            <FormVerticalSpace />

            <TagsField
              name="allowedDomains"
              label={t('sso.form.allowedDomains.label', 'Allowed Domains')}
              required={true}
            />

            <FormVerticalSpace />

            <FormHeader
              pt={5}
              pb={5}
              text={t('sso.form.configuration', 'Configuration')}
            />

            <FormVerticalSpace />

            <CheckboxField
              name="disallowPasswordLogin"
              label={t(
                'sso.form.disallowPasswordLogin.label',
                'Disable login with email and password'
              )}
              disabled={
                initialValueMutation.isLoading || initialValueMutation.isError
              }
            />

            <InputField
              name="tokenTTL"
              label={t(
                'sso.form.sessionExpiresAfterSeconds',
                'Session expires after seconds'
              )}
              type={'number'}
              required={true}
              disabled={
                initialValueMutation.isLoading || initialValueMutation.isError
              }
            />

            <p className="sso-instruction-okta">
              {t(
                'sso.form.instruction.email_attribute',
                `The attribute from your IdP that contains the user's email address is a required field. We use this to match the user to an existing account or create a new one.`
              )}
            </p>
            <InputField
              name="emailAttribute"
              placeholder="email_address"
              label={t('sso.form.emailAttribute', 'Email attribute')}
            />

            <Message warning>
              <Trans i18nKey="sso.form.emailAttributeWarning">
                <Message.Content>
                  We will attempt to link your users using direct match of the{' '}
                  <code className={styles.code}>Subject.NameID</code> from the
                  IdP and the{' '}
                  <code className={styles.code}>User.externalId</code> in our
                  system. If the{' '}
                  <code className={styles.code}>User.externalId</code> is not
                  set, we will fallback to matching the{' '}
                  <code className={styles.code}>User.email</code> with the email
                  attribute provided by the IdP. Once a relationship is
                  established, subsequent logins will be using the externalId
                  ensuring that users of your system can change their email
                  without impacting their ability to login to our system.
                </Message.Content>
              </Trans>
            </Message>

            <Divider />

            <FormVerticalSpace size={20} />

            <FormHeader
              pt={5}
              pb={5}
              text={t('sso.form.userProvisioning', 'User provisioning')}
            />

            <FormVerticalSpace size={20} />

            <CheckboxField
              toggle
              name="autoProvisioning.enabled"
              label={t(
                'sso.form.autoCreateUsers.label',
                'Automatically provision User on login.'
              )}
            />

            <FormVerticalSpace size={20} />

            {values?.autoProvisioning?.enabled && (
              <div style={{ maxWidth: 800 }}>
                <SAMLAttributeMappingField
                  name="autoProvisioning.attributeMapping"
                  label="Attribute Mapping"
                  localAttributes={[
                    {
                      text: t('sso.attributeMapping.firstName', 'First name'),
                      key: 'firstName',
                      value: 'firstName',
                    },
                    {
                      text: t('sso.attributeMapping.lastName', 'Last name'),
                      key: 'lastName',
                      value: 'lastName',
                    },
                    {
                      text: t('sso.attributeMapping.phone', 'Phone number'),
                      key: 'phoneNo',
                      value: 'phoneNo',
                    },
                  ]}
                />
                <FormVerticalSpace size={20} />
                <SAMLRoleMappingField
                  name={'autoProvisioning.accountRolesMapping'}
                  label={'Account roles'}
                  disabled={!canEditAccountEndpoint}
                  roles={async () => {
                    try {
                      const result = await request({
                        method: 'POST',
                        path: `/1/roles/search`,
                        body: {
                          accountId: user?.accountId,
                          context: 'account',
                        },
                      });
                      return result.data.map((role: any) => ({
                        text: role.name,
                        value: role.id,
                        kind: 'account',
                      }));
                    } catch (e) {
                      console.error(`Failed to fetch account roles: ${e}`);
                      return [];
                    }
                  }}
                />
                <SAMLRoleMappingField
                  name={'autoProvisioning.providerRolesMapping'}
                  disabled={!canEditProviderRoles}
                  label={'Provider roles'}
                  roles={async () => {
                    try {
                      const result = await request({
                        method: 'POST',
                        path: `/1/roles/search`,
                        body: {
                          context: 'provider',
                        },
                      });
                      return result.data.map((role: any) => ({
                        text: role.name,
                        value: role.id,
                        kind: 'provider',
                      }));
                    } catch (e) {
                      console.error(`Failed to fetch provider roles: ${e}`);
                      return [];
                    }
                  }}
                />
              </div>
            )}
            <div style={{ display: 'flex', width: '100%' }}>
              <Button
                primary
                disabled={
                  initialValueMutation.isLoading ||
                  !dirty ||
                  initialValueMutation.isError
                }
                content={t('sso.form.updateButton', 'Save')}
                loading={handleSubmitMutation.isLoading}
                style={{ marginLeft: 0 }}
                type={'submit'}
              />
            </div>

            <Divider />

            <FormHeader
              pt={5}
              pb={5}
              text={t('sso.form.instructions', 'Instructions  ')}
            />

            <p>
              {t(
                'sso.form.enable_instructions.description',
                `To enable your users to log in via your SSO configuration
              directly, you can share the following link with them:`
              )}
            </p>

            <InputField
              name="directUrl"
              label={t('sso.form.directUrl.label', 'Direct URL')}
              readOnly={true}
              action={{
                icon: 'copy',
              }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}
