import React, { Component } from 'react';
import { Icon, Modal, Header, Button } from 'semantic';

export default class HelpTip extends Component {
  state = { open: false };

  close = (e) => {
    e?.stopPropagation();
    this.setState({ open: false });
  };
  open = (e) => {
    e.stopPropagation();
    this.setState({ open: true });
  };

  icon() {
    if (!this.props.children) {
      return (
        <Icon
          name="circle-question"
          style={{ color: '#ccc', cursor: 'pointer' }}
          onClick={this.open}
        />
      );
    }
    return React.Children.map(this.props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { onClick: this.open });
      }
      return child;
    });
  }

  render() {
    const { icon, title, text, style, float = 'right' } = this.props;
    return (
      <div
        style={{
          display: 'block',
          marginLeft: '0.2em',
          marginTop: '3px',
          float,
          ...(style || {}),
        }}>
        {this.icon()}
        {this.state.open && (
          <Modal
            open={true}
            size="small"
            closeOnEscape={true}
            closeOnDimmerClick={true}
            onClose={this.close}>
            <Header
              icon={icon || 'circle-info'}
              content={title || 'Explanation'}
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <Modal.Content>{text}</Modal.Content>
            <Modal.Actions>
              {this.props.actions?.map((action, index) =>
                React.cloneElement(action, {
                  key: index,
                  handleClose: () => {
                    this.close();
                  },
                })
              )}
              <Button basic onClick={this.close}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}
