import React, { useState } from 'react';
import { Button, Form, Icon, Message } from 'semantic';
import { request } from 'utils/api';
import { useTranslation, withTranslation } from 'react-i18next';
import modal from 'helpers/modal';
import { Formik, FormikValues } from 'formik';
import { Modal, Popup } from 'semantic-ui-react';
import * as Yup from 'yup';
import DateField from 'components/form-fields/formik/DateField';
import { useFeatures } from 'contexts/features';
import { FeatureFlags } from 'screens/Invoices/features';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import { convertLocalDateToUTCEquivalent } from 'utils/date';
import { format } from 'date-fns';
import InputField from 'components/form-fields/formik/InputField';
import { getDefaultInvoicePostingPeriod } from './utils';

const RegenerateInvoiceForm = ({
  invoiceId,
  handleClose,
  close,
}: {
  invoiceId: string;
  handleClose: () => Promise<void>;
  close: () => void;
}) => {
  const [error, setError] = useState<Error | null>();
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();

  const isInvoiceDateFeatureEnabled = hasFeature(FeatureFlags.InvoiceDate);

  async function onSubmit(values: FormikValues) {
    try {
      await request({
        method: 'POST',
        path: `/1/invoices/${invoiceId}/regenerate`,
        body: {
          ...(isInvoiceDateFeatureEnabled && {
            invoiceDate: format(
              convertLocalDateToUTCEquivalent(values.invoiceDate),
              'yyyy-MM-dd'
            ),
          }),
          ...(isInvoiceDateFeatureEnabled && {
            invoicePostingPeriod: values.invoicePostingPeriod,
          }),
        },
      });
      if (typeof handleClose === 'function') {
        await handleClose();
      }
    } catch (error) {
      if (error instanceof Error && error.message) {
        setError(error);
      }
    }
  }

  const initialValues = {
    ...(isInvoiceDateFeatureEnabled && { invoiceDate: undefined }),
    ...(isInvoiceDateFeatureEnabled && {
      invoicePostingPeriod: getDefaultInvoicePostingPeriod(),
    }),
  };

  const validationSchema = Yup.object({
    ...(isInvoiceDateFeatureEnabled && {
      invoiceDate: Yup.date().required(),
      invoicePostingPeriod: Yup.string(),
    }),
  });

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <>
            <Modal.Header>
              {t(
                'invoicesRegenerateDialog.title',
                'Are you sure you want to regenerate invoice?'
              )}
            </Modal.Header>
            <Modal.Content>
              <Form>
                {error && <Message error content={error.message} />}
                <Message info>
                  {t(
                    'invoicesRegenerateDialog.description',
                    'All new totals will be calculated based on charge sessions. A new invoice will be created in Exact and a new email will be sent unless skipped in the invoice configuration'
                  )}
                </Message>
                {isInvoiceDateFeatureEnabled && (
                  <>
                    <div
                      className="inline field required"
                      style={{ width: '100%', marginBottom: '0' }}>
                      <label>
                        {t('generateInvoiceDialog.invoiceDate', 'Invoice Date')}
                      </label>
                      <Popup
                        content={t(
                          'generateInvoiceDialog.invoiceDateToolTip',
                          'This will be the date that appears as invoice date on the invoice.'
                        )}
                        trigger={<Icon size="small" name="circle-info" />}
                      />
                    </div>
                    <div>
                      <DateField
                        name="invoiceDate"
                        required
                        fullWidth={true}
                        hideErrorLabel={false}
                      />
                    </div>
                  </>
                )}

                <FormVerticalSpace size={20} />

                {isInvoiceDateFeatureEnabled && (
                  <>
                    <div
                      className="inline field"
                      style={{ width: '100%', marginBottom: '0' }}>
                      <label>
                        {t(
                          'generateInvoiceDialog.invoicePostingPeriod',
                          'Invoice Posting Date'
                        )}
                      </label>
                      <Popup
                        content={t(
                          'generateInvoiceDialog.invoiceDateToolTip',
                          'This will be the date that appears as posting date on the invoice.'
                        )}
                        trigger={<Icon size="small" name="circle-info" />}
                      />
                    </div>
                    <div>
                      <InputField
                        name="invoicePostingPeriod"
                        hideErrorLabel={false}
                        type="month"
                      />
                    </div>
                  </>
                )}

                <FormVerticalSpace size={20} />

                <div>
                  <Button
                    icon="triangle-exclamation"
                    disabled={error || isSubmitting}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    content={t('invoicesRegenerateDialog.button', 'Regenerate')}
                    floated="right"
                  />

                  <Button
                    basic
                    onClick={close}
                    content={t('common.cancel', 'Cancel')}
                    floated="right"
                  />
                  <FormVerticalSpace size={25} />
                </div>
              </Form>
            </Modal.Content>
          </>
        )}
      </Formik>
    </>
  );
};

export default modal(withTranslation()(RegenerateInvoiceForm));
