import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button, Label, Dropdown } from 'semantic';
import HelpTip from 'components/HelpTip';
import { Link } from 'react-router-dom';
import { Search } from 'components';
import { formatDateTime } from 'utils/date';
import EditOcpiCredential from 'components/modals/EditOcpiCredential';
import Actions from '../Actions';

export default function OcpiCredentialsTable() {
  const { t } = useTranslation();
  const { items, reload, loading } = useContext(Search.Context);

  if (!items.length || loading) return null;

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>
              {t('ocpiCredentials.columnName', 'Name')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('ocpiCredentials.columnRole', 'Role')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('ocpiCredentials.columnStatus', 'Status')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('ocpiCredentials.columnActiveVersion', 'Active Version')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('ocpiCredentials.columnCreated', 'Created')}
              <HelpTip
                title={t('ocpiCredentials.columnCreated', 'Created')}
                text={t(
                  'ocpiCredentials.columnCreatedToolTip',
                  'This is the date and time the credential was created.'
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('ocpiCredentials.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            const isActive = !!item.activeVersion;
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Link to={`/credentials/ocpi/${item.id}`}>{item.name}</Link>
                </Table.Cell>
                <Table.Cell>{item.role}</Table.Cell>
                <Table.Cell textAlign="center">
                  {isActive ? (
                    <Label color="olive" content="Active" />
                  ) : (
                    <Label content="Inactive" />
                  )}
                </Table.Cell>
                <Table.Cell>{item.activeVersion || '-'}</Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>

                <Table.Cell textAlign="center">
                  <EditOcpiCredential
                    onSave={reload}
                    initialValues={item}
                    trigger={<Button basic icon="pen-to-square" title="Edit" />}
                  />
                  <Dropdown button basic text="More">
                    <Dropdown.Menu direction="left">
                      <Actions item={item} onReload={reload} />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
