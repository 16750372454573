import { Formik } from 'formik';
import modal from 'helpers/modal';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'semantic';
import { joiErrorDetailsToObject, request } from 'utils/api';
import * as Yup from 'yup';
import InputField from '../form-fields/formik/InputField';
import SelectField from '../form-fields/formik/SelectField';

const TOKEN_TYPES = ['card', 'tag'] as const;
type TokenTypes = (typeof TOKEN_TYPES)[number];

interface Props {
  close(): void;
  onSave(): void;
}

const VALIDATION_SCHEMA = Yup.object({
  tokenType: Yup.string().oneOf(TOKEN_TYPES),
  batchSize: Yup.number().min(1).max(20000).integer().required(),
  note: Yup.string().required(),
});

const INITIAL_VALUES = {
  batchSize: 0,
};

const CreateTokenBatchForm: React.FC<Props> = ({ close, onSave }) => {
  const { t } = useTranslation();

  const TOKEN_TYPES_LABELS: { [key in TokenTypes]: string } = useMemo(
    () => ({
      card: t('tokenBatches.card', 'Card'),
      tag: t('tokenBatches.tag', 'Tag'),
    }),
    []
  );

  const submitHandler = async (formValues: any, formikBag: any) => {
    const newTokenBatchBody = {
      note: formValues.note,
      batchSize: formValues.batchSize,
      type: formValues.tokenType,
    };

    try {
      await request({
        method: 'POST',
        path: '/1/tokens/batch',
        body: newTokenBatchBody,
      });

      close();
      onSave();
    } catch (error: any) {
      if (Array.isArray(error?.details)) {
        formikBag.setErrors(joiErrorDetailsToObject(error));
      } else {
        formikBag.setStatus(error?.message);
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        onSubmit={submitHandler}
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}>
        {({ dirty, isSubmitting, handleSubmit, isValid }) => {
          return (
            <>
              <Modal.Header>
                {t('tokenBatches.new', 'Create New Batch')}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <SelectField
                    options={TOKEN_TYPES.map((tokenType) => ({
                      key: tokenType,
                      value: tokenType,
                      text: TOKEN_TYPES_LABELS[tokenType],
                    }))}
                    name="tokenType"
                    label="Token Type"
                  />
                  <InputField
                    name="batchSize"
                    label={t(
                      'tokenBatches.batchSize',
                      'Amount of Tokens to be created'
                    )}
                    type="number"
                    step={100}
                    min={0}
                    max={20000}
                  />
                  <InputField
                    name="note"
                    label={t(
                      'tokenBatches.note',
                      "A note that describes the batch's purpose"
                    )}
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="button"
                  loading={isSubmitting}
                  role="button"
                  disabled={isSubmitting || !isValid || !dirty}
                  primary
                  content={t('tokenBatches.create', 'Create Batch')}
                  onClick={handleSubmit}
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export const CreateTokenBatch = modal(CreateTokenBatchForm);
