import React from 'react';

import { Box, FeatureFlag } from 'components';
import { Grid, Header, Loader, Checkbox, Icon, Divider } from 'semantic';

import MyCards from './MyCards';
import MyCardUsage from './MyCardUsage';
import MyChargePoints from './MyChargePoints';
import GetMobileApp from './GetMobileApp';

import { useDashboard } from './context';
import PageCenter from 'components/PageCenter';
import { useUser } from 'contexts/user';

import { Link } from 'react-router-dom';
import widgetstyles from './widgetstyles.module.less';
import mapBackground from './map-background.png';
import { useTranslation } from 'react-i18next';
import Modals from 'screens/Home/Welcome';
import { GridColumn } from 'semantic-ui-react';
import MobileVerificationWidget from 'components/MobileVerificationWidget';

export default function Home() {
  const { loading, toggleAccount, isAccount, canManagedUser } = useDashboard();
  const { provider, user } = useUser();
  const enabledChargeProducts = provider?.enabledChargeProducts || [];
  const { t } = useTranslation();

  if (loading) {
    return (
      <div style={{ height: '80vh' }}>
        <PageCenter>
          <Loader active>{t('loading.loading')}</Loader>
        </PageCenter>
      </div>
    );
  }

  return (
    <>
      <Divider hidden />
      {/* A bit of space on top, not required if there are breadcrumbs */}
      <Grid>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Header as="h2" style={{ margin: '0' }}>
            {t('dashboardHome.title', 'Dashboard')}
          </Header>
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={8}
          verticalAlign="middle"
          className={widgetstyles.headerToggle}>
          {canManagedUser && (
            <>
              {t('dashboardHome.toggleMyUsage', 'My Usage')}
              <Checkbox
                toggle
                style={{ marginLeft: '1em' }}
                label={t('dashboardHome.toggleAccountUsage', 'Account Usage')}
                checked={isAccount}
                onChange={() => toggleAccount()}
              />
            </>
          )}
        </Grid.Column>
      </Grid>
      {user?.contact?.phoneNo && user?.contact?.phoneCountryCode && (
        <FeatureFlag feature="mobile_verification">
          {!user?.contact?.phoneNoVerifiedAt && (
            <Grid>
              <GridColumn mobile={16} tablet={16} computer={16}>
                <MobileVerificationWidget />
              </GridColumn>
            </Grid>
          )}
        </FeatureFlag>
      )}
      <Grid>
        {(enabledChargeProducts.includes('card') ||
          enabledChargeProducts.includes('tag')) && (
          <Grid.Row>
            <Grid.Column mobile={16} computer={11}>
              <MyCardUsage />
            </Grid.Column>
            <Grid.Column mobile={16} computer={5}>
              <MyCards />
            </Grid.Column>
          </Grid.Row>
        )}
        {enabledChargeProducts.includes('charge-station') && (
          <Grid.Row>
            <Grid.Column
              mobile={16}
              computer={provider.slug === 'e-flux' ? 11 : 16}>
              <MyChargePoints />
            </Grid.Column>
            {provider.slug === 'e-flux' && (
              <Grid.Column mobile={16} computer={5}>
                <GetMobileApp />
              </Grid.Column>
            )}
          </Grid.Row>
        )}

        {/* SAAS-1975 only show map if the provider has cards/tags enabled */}
        {enabledChargeProducts.some((c) => ['card', 'tag'].includes(c)) && (
          <Grid.Row>
            <Grid.Column>
              <Box
                title={t(
                  'dashboardHome.mapTitle',
                  'Explore Charging Stations'
                )}>
                <Box.Content>
                  <Link
                    to="/my-map"
                    className={widgetstyles.mapLink}
                    style={{
                      backgroundImage: `url(${mapBackground})`,
                    }}>
                    <Header as="div" align="center">
                      <Icon
                        name="map"
                        size="huge"
                        style={{ verticalAlign: '-.27778em' }}
                      />
                      {t(
                        'dashboardHome.mapAction',
                        'Find EV Charging Locations'
                      )}
                    </Header>
                  </Link>
                </Box.Content>
              </Box>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      <Modals />
    </>
  );
}
