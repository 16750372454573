import { isSuperAdmin, userCanAccessProviderEndpoint } from 'utils/roles';
import { useFeatures } from 'contexts/features';
import { useUser } from 'contexts/user';

export const EVSE_CONTROLLERS_BACKGROUND_JOBS_PATH =
  '/charging-stations/background-jobs';
export const EVSE_CONTROLLERS_BACKGROUND_JOBS_FEATURE_FLAG =
  'commandcenter_workflows_page';
export const EVSE_CONTROLLERS_BACKGROUND_JOBS_PERMISSIONS = [
  'evseBulkActions',
  'evseCommands',
];

export const hasEvseControllersBackgroundJobsPermissions = (
  requiredLevel: string
) => {
  const { hasFeature } = useFeatures();
  const { user, provider } = useUser();

  return (
    user &&
    hasFeature(EVSE_CONTROLLERS_BACKGROUND_JOBS_FEATURE_FLAG) &&
    (isSuperAdmin(user) ||
      EVSE_CONTROLLERS_BACKGROUND_JOBS_PERMISSIONS.some((p) =>
        userCanAccessProviderEndpoint(user, provider, p, requiredLevel)
      ))
  );
};
