import React from 'react';
import Pagination from 'components/Pagination';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { countries } from 'eflux-pkg-js';
import EditEvseControllerCostSettings from 'components/modals/EditEvseControllerCostSettings';
import EditEvseControllerAccessGroups from 'components/modals/EditEvseControllerAccessGroups';

import { Table, Loader, Segment, Message, Label, Button } from 'semantic';

import { formatCurrency } from 'utils/formatting';

import {
  currentUserCanAccessProviderEndpoint,
  canAccessAccountEndpoint,
  hasFacilitatorPermissionsAccess,
  canAccessProviderEndpoint,
} from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { useUser } from 'contexts/user';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import { useEnergyMarketPriceContext } from 'components/MarketEnergyTariffs/Context';
import { AccountPlatformFeatureProvider } from 'components/account-platform-features/context';
import { EvseTariffFeatureProvider } from 'components/tariffs/EvseTariff/features';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

function currentUserHasCostSettingsAccess(
  location,
  user,
  facilitatorPermissions
) {
  const { accessPolicy } = location;

  const hasProviderAdminAccess = canAccessProviderEndpoint(
    user,
    location.providerId,
    'cpoLocations',
    'read'
  );
  if (hasProviderAdminAccess) {
    return true;
  }

  const hasAccountAdminAccess = canAccessAccountEndpoint(
    user,
    location.accountId,
    'cpoLocations',
    'read'
  );
  if (hasAccountAdminAccess) {
    return true;
  }

  const hasFacilitatorAccess = hasFacilitatorPermissionsAccess(
    facilitatorPermissions,
    'cpoLocations',
    'read'
  );

  if (hasFacilitatorAccess) {
    return true;
  }
  if (accessPolicy === 'employeeReimburse' && location.userId == user.id) {
    return false;
  }
  if (accessPolicy === 'communityReimburse') {
    return false;
  }
}

function EvseControllerCosts({ item, currency, advancedTariffFeatures }) {
  const energyMarketPriceContext = useEnergyMarketPriceContext();
  if (
    advancedTariffFeatures.advancedTariffs &&
    item.costSettings?.some((costSetting) => costSetting.components?.length > 1)
  ) {
    return <Label content="Advanced Tariff" />;
  }
  if (item.enablePublicFreeCharging) {
    return <Label content="Free for any RFID tag" />;
  }
  if (!item.enablePublicCharging) {
    return <Label content="Roaming disabled" />;
  }
  if (item.tariffProfileId && !item.enableTariffProfileOverride) {
    return <Label content="Tariff Profile" />;
  }

  return (
    <>
      {' '}
      {item.costSettings?.map((cost) => {
        if (cost.enableEnergyMarketTracking) {
          const pricePerKwh =
            cost.surchargePerKwh +
            (energyMarketPriceContext.activeTariff?.costSettings?.pricePerKwh ||
              0);
          return (
            <Label
              key={cost.connectorId}
              content={formatCurrency(pricePerKwh, { precision: 2, currency })}
              icon={'chart-line'}
              color={'blue'}
            />
          );
        }
        return (
          <Label
            key={cost.connectorId}
            content={formatCurrency(cost.pricePerKwh, {
              precision: 2,
              currency,
            })}
          />
        );
      })}
    </>
  );
}

export default function TableView({
  location,
  status,
  data: items,
  meta = {},
  page,
  limit,
  onRefresh,
  setPage,
}) {
  const { t } = useTranslation();
  const { user, facilitatorPermissions } = useUser();
  const advancedTariffFeatures = useAdvancedTariffFeatures();

  const currency = countries.getCurrencyByAlpha3(location?.country);

  return (
    <div className="list">
      {status.success && !items.length && (
        <Message>
          {t('locationCost.noCostyet', 'No EVSE Controllers created yet')}
        </Message>
      )}

      {status.success && items.length > 0 && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>
                {t('locationCost.columnSerialNo', 'Serial No')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('locationCost.columnOCPPID', 'OCPP Identity')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('locationCost.columnPlan', 'Plan')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('locationCost.columnEnergyCosts', 'Energy Costs')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('locationCost.columnAccessGroups', 'Access Groups')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('locationCost.roaming', 'Roaming')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                {t('locationCost.columnSettings', 'Settings')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {currentUserCanAccessProviderEndpoint(
                      'evseControllers',
                      'read'
                    ) ? (
                      <Link to={`/charging-stations/${item.id}`}>
                        {item.serialNumber ||
                          t('locationCost.noSerialNo', 'No Serial No.')}
                      </Link>
                    ) : (
                      item.serialNumber ||
                      t('locationCost.noSerialNo', 'No Serial No.')
                    )}
                  </Table.Cell>
                  <Table.Cell>{item.ocppIdentity}</Table.Cell>
                  <Table.Cell>
                    {item.billingPlan && item.billingPlan.details.en.name}
                  </Table.Cell>
                  <Table.Cell>
                    <EvseControllerCosts
                      item={item}
                      currency={currency}
                      advancedTariffFeatures={advancedTariffFeatures}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {item.accessGroups.map((item) => {
                      return (
                        <Link
                          style={{
                            marginRight: '0.5em',
                            textDecoration: 'underline',
                          }}
                          key={item.id}
                          to={`/my-locations/access-groups/${item.id}`}>
                          {item.name}
                        </Link>
                      );
                    })}
                  </Table.Cell>
                  <Table.Cell>
                    {item.enablePublicCharging ? t('yes') : t('no')}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {currentUserHasCostSettingsAccess(
                      location,
                      user,
                      facilitatorPermissions
                    ) ? (
                      <AccountPlatformFeatureProvider
                        accountId={item.accountId}>
                        <EvseTariffFeatureProvider
                          accessPolicy={item.location.accessPolicy}>
                          <EditEvseControllerCostSettings
                            locationId={item.locationId}
                            accountId={item.accountId}
                            currency={countries.getCurrencyByAlpha3(
                              location.country
                            )}
                            initialValues={item}
                            trigger={<Button basic icon="gear" />}
                            onClose={() => onRefresh()}
                          />
                        </EvseTariffFeatureProvider>
                      </AccountPlatformFeatureProvider>
                    ) : (
                      <EditEvseControllerAccessGroups
                        locationId={item.locationId}
                        initialValues={item}
                        trigger={<Button basic icon="gear" />}
                        onClose={() => onRefresh()}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
      {status.success && meta.total > limit && (
        <Center>
          <Pagination
            limit={limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) => {
              setPage(activePage).then(() => {
                window.scrollTo(0, 0);
              });
            }}
          />
        </Center>
      )}
      {status.request && (
        <Segment style={{ height: '100px' }}>
          <Loader active>{t('loading.loading')}</Loader>
        </Segment>
      )}
      {status.error && <Message error content={status.error.message} />}
    </div>
  );
}
