import React, { useEffect } from 'react';
import { request } from 'utils/api';
import {
  Loader,
  Message,
  Table,
  Container,
  Divider,
  Header,
  Label,
} from 'semantic';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PartyModal from './PartyModal';
import IssuerModal from './IssuerModal';

const StyledLabel = styled(Label)`
  &:hover {
    outline: 1px solid currentColor;
    cursor: pointer;
  }
`;

const StyledLink = styled.a`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    request({
      method: 'POST',
      path: '/1/external-tokens/party-breakdown',
    })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  return (
    <Container>
      <Header as="h2">Analytics</Header>
      <Divider hidden />
      {loading && <Loader inline="centered" active />}
      {!!error && <Message error content={error.message} />}
      {data?.providers?.length ? (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Source</Table.HeaderCell>
              <Table.HeaderCell>Party ID</Table.HeaderCell>
              <Table.HeaderCell>Issuer(s)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.providers.map((provider) => (
              <Table.Row key={provider._id}>
                <Table.Cell textAlign="center">
                  <Label basic content={data.importSource} />
                </Table.Cell>
                <Table.Cell>
                  <PartyModal
                    partyId={provider._id}
                    trigger={<StyledLink>{provider._id}</StyledLink>}
                  />
                </Table.Cell>
                <Table.Cell>
                  {provider?.issuer.map((issuer) => (
                    <IssuerModal
                      key={issuer}
                      issuer={issuer}
                      trigger={<StyledLabel content={issuer} />}
                    />
                  ))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        !loading && t('common.noData', 'No data found') + '...'
      )}
    </Container>
  );
};
