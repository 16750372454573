import React from 'react';
import AppWrapper from 'components/AppWrapper';

import { Divider, Header, Button, Menu, Grid } from 'semantic';
import AnalyticsToken from 'components/AnalyticsToken';
import { TectonicProvider, TimeRangePicker } from 'react-tectonic';
import { Switch, Route, Link, NavLink } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';

import { withTranslation } from 'react-i18next';

import Overview from './Overview';
import Transactions from 'components/analytics-screens/cpo/Transactions';
import Equipment from 'components/analytics-screens/cpo/Equipment';
import { TECTONIC_URL } from 'utils/env';

const screens = [
  {
    id: 'overview',
    name: 'Overview',
    component: Overview,
  },
  {
    id: 'transactions',
    name: 'Transactions',
    component: Transactions,
  },
  {
    id: 'equipment',
    name: 'Equipment',
    component: Equipment,
    props: {
      hasUsersReference: false,
      includeEvseIssues: false,
    },
  },
];

class Analytics extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <AppWrapper>
        <AnalyticsToken path="/1/evse-controllers/maintenance/analytics-token">
          <TectonicProvider
            debug
            baseUrl={TECTONIC_URL}
            primaryColor="#ff3d00"
            collection="sessions"
            dateField="createdAt"
            timeRangeMode="all">
            <React.Fragment>
              <Breadcrumbs
                path={[
                  <Link
                    key="maintenance-evse-controllers"
                    to="/maintenance/evse-controllers">
                    Maintenance
                  </Link>,
                ]}
                active={t('analytics.title', 'Analytics')}
              />
              <Grid reversed="computer">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={10}
                  textAlign="right"
                  verticalAlign="middle">
                  <TimeRangePicker
                    align="right"
                    renderButton={(label, handleOnClick) => (
                      <Button
                        primary
                        icon="clock"
                        content={label}
                        onClick={handleOnClick}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  <Header as="h2" style={{ margin: '0' }}>
                    {t('analytics.title', 'Analytics')}
                  </Header>
                </Grid.Column>
              </Grid>
              <Divider hidden />
              {this.renderMenu()}
              <Divider hidden />
              <Switch>
                {screens.map((screen) => {
                  return (
                    <Route
                      key={screen.id}
                      exact
                      path={
                        screen.id === 'overview'
                          ? `/maintenance/analytics`
                          : `/maintenance/analytics/${screen.id}`
                      }
                      component={() => (
                        <screen.component {...(screen.props || {})} />
                      )}
                    />
                  );
                })}
              </Switch>
            </React.Fragment>
          </TectonicProvider>
        </AnalyticsToken>
      </AppWrapper>
    );
  }

  renderMenu() {
    return (
      <Menu fluid pointing secondary>
        {screens.map(({ id, name }) => {
          return (
            <Menu.Item
              key={id}
              exact
              content={name}
              to={
                id === 'overview'
                  ? `/maintenance/analytics`
                  : `/maintenance/analytics/${id}`
              }
              as={NavLink}
            />
          );
        })}
      </Menu>
    );
  }
}

export default withTranslation()(Analytics);
