import React from 'react';
import { Divider, Statistic } from 'semantic';
import { withTranslation } from 'react-i18next';
import { AggregateTimeSeries, SeriesChart } from 'react-tectonic';

import {
  defaultColors,
  mergeFilters,
  sessionsExclusionFilter,
} from 'utils/visualizations';
import CardinalityStatistic from 'components/analytics/CardinalityStatistic';

class MspOverview extends React.Component {
  render() {
    const { baseFilter = {}, t } = this.props;
    return (
      <div>
        <Divider hidden />
        <Divider hidden />

        <Statistic.Group
          widths="three"
          style={{ justifyContent: 'space-between' }}>
          <CardinalityStatistic
            collection="cards"
            label="Cards"
            filter={baseFilter}
          />
          <CardinalityStatistic
            collection="tokens"
            label="Tokens"
            filter={baseFilter}
          />
          <CardinalityStatistic
            collection="sessions"
            label="Sessions"
            filter={baseFilter}
          />
        </Statistic.Group>

        <Divider hidden />
        <Divider hidden />

        <AggregateTimeSeries
          collection="sessions"
          operation="count"
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          <SeriesChart
            title={t('analyticsSessions.sessions', 'Sessions')}
            titleAlign="center"
            chartType="bar"
            height={350}
            valueField="count"
            valueFieldLabel={t('analyticsSessions.sessions', 'Sessions')}
            color={defaultColors[0]}
          />
        </AggregateTimeSeries>

        <Divider hidden />
        <Divider hidden />
      </div>
    );
  }
}

export default withTranslation()(MspOverview);
