import React, { useEffect, useState } from 'react';
import { AppWrapper, Breadcrumbs, FeatureFlag } from 'components';
import { request } from 'utils/api';
import { Button, Container, Grid, Header, Icon, Message } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch } from 'react-router-dom';
import { useUser } from 'contexts/user';
import InspectObject from 'components/modals/InspectObject';
import PageLoader from 'components/PageLoader';
import CreditSessionsModal from '../SessionsMsp/CreditSessions/CreditSessionsModal';
import DataList from 'components/DataList';
import CreditSessionsTable from '../SessionsMsp/CreditSessions/Table';
import Details from 'components/SessionDetails';
import MspMenu from './MspMenu';
import EvsePaymentTerminalSessions from 'screens/SessionsCpo/EvsePaymentTerminalSessions';
import { SessionTimelineModal } from 'components/Session/TimelineModal';
import { useCanAccess } from 'hooks/useCanAccess';

const itemLimit = 100;

const buildBreadcrumbPath = (path, t) => {
  if (path.includes('/cards')) {
    return [
      <Link key="cards" to="/cards">
        {t('cards.title', 'Cards')}
      </Link>,
      <Link key="sessions" to="/cards/sessions">
        {t('sessions.title', 'Sessions')}
      </Link>,
    ];
  } else if (path.includes('/charging-stations')) {
    return [
      <Link key="charging-stations" to="/charging-stations">
        {t('menu.cpoChargeStations', 'Charging stations')}
      </Link>,
      <Link key="sessions" to="/charging-stations/sessions">
        {t('sessions.title', 'Sessions')}
      </Link>,
    ];
  }
};

export default function SessionDetail({ match, context = 'cpo' }) {
  const { t } = useTranslation();
  const { user } = useUser();
  const sessionId = match.params.id;
  const [session, setSession] = useState();
  const [loading, setLoading] = useState(true);
  const [dataKey, setDataKey] = useState(Date.now());

  const isMSP = context === 'msp';
  const hasCreditSessions = session?.creditSessions?.length > 0;

  async function fetchSession() {
    try {
      let { data } = await request({
        method: 'GET',
        path: isMSP
          ? `/1/sessions/${sessionId}`
          : `/2/sessions/cpo/${sessionId}`,
        params: { currencyConvertMode: 'to-eur', includeCreditSessions: isMSP },
      });
      setSession(data);
    } finally {
      setLoading(false);
    }
  }

  async function fetchSessionTimeline(id) {
    const result = await request({
      method: 'GET',
      path: `/2/sessions/cpo/${id}/timeline`,
    });
    return result.data;
  }

  async function fetchEvsePaymentTerminalSession(id) {
    const result = await request({
      method: 'GET',
      path: `/2/sessions/cpo/evse-payment-terminal-session/${id}`,
    });

    return result.data;
  }

  const deleteCreditSession = async (creditSessionId) => {
    await request({
      method: 'DELETE',
      path: `/1/credit-sessions/${creditSessionId}`,
    });
    setDataKey(Date.now());
  };

  const handleRequest = (filters = {}) => {
    return request({
      method: 'POST',
      path: `/1/credit-sessions/search`,
      body: {
        ...filters,
        limit: itemLimit,
        searchId: sessionId,
      },
    });
  };

  useEffect(() => {
    (async () => {
      await fetchSession();
    })();
  }, [sessionId]);

  // Only allow access to timeline via provider/system/global roles
  const showTimelineButton = useCanAccess({
    endpoint: 'cpoSessions',
    requiredLevel: 'read',
    allowAccountContext: false,
  });

  return (
    <AppWrapper>
      <Container>
        <Breadcrumbs
          path={buildBreadcrumbPath(match.path, t)}
          active={session && session.id}
        />
        {hasCreditSessions && (
          <Message icon info>
            <Icon name={'circle-info'} />
            <Message.Content>
              <Message.Header>
                A credit session has been issued for this session. See:{' '}
                <Link exact to={`/cards/sessions/${sessionId}/credit-sessions`}>
                  {t('creditSessions.title', 'Credit Sessions')}
                </Link>
              </Message.Header>
            </Message.Content>
          </Message>
        )}
        <Grid>
          <Grid.Column mobile={4} tablet={6} computer={8}>
            <Header as="h2" style={{ margin: '0' }}>
              {t('sessions.singleSession', 'Session')}
            </Header>
          </Grid.Column>
          <Grid.Column mobile={12} tablet={10} computer={8} textAlign={'right'}>
            {isMSP && (
              <CreditSessionsModal
                session={session}
                onClose={() => setDataKey(Date.now())}
                trigger={
                  <Button basic content="Credit Sessions" icon="credit-card" />
                }
              />
            )}
            {showTimelineButton && (
              <FeatureFlag feature={'advanced_session_timeline'}>
                <SessionTimelineModal
                  name="Session Timeline"
                  getData={() =>
                    fetchSessionTimeline(session._id || session.id)
                  }
                  trigger={<Button basic content="Timeline" icon="timeline" />}
                  size="large"
                />
              </FeatureFlag>
            )}
            <InspectObject
              name="Session"
              data={session}
              trigger={<Button basic content={'Inspect'} icon="code" />}
            />
            {session?.evsePaymentTerminalSessionId && (
              <EvsePaymentTerminalSessions
                getData={() =>
                  fetchEvsePaymentTerminalSession(
                    session.evsePaymentTerminalSessionId._id ||
                      session.evsePaymentTerminalSessionId
                  )
                }
                size="medium"
                trigger={
                  <Button
                    basic
                    content={t(
                      'evsePaymentTerminalSession.showDetails',
                      'Show payment details'
                    )}
                    icon="file"
                  />
                }
              />
            )}
          </Grid.Column>
        </Grid>
        {isMSP && <MspMenu sessionId={sessionId} />}
        {session && session.deletedAt && (
          <Message
            error
            content={t('cdr.deleted', 'This session has been deleted.')}
          />
        )}
        <Switch>
          <Route
            exact
            path="/cards/sessions/:sessionId/credit-sessions"
            render={() => (
              <DataList
                key={dataKey}
                limit={itemLimit}
                onRequest={handleRequest}>
                <CreditSessionsTable
                  onCreditSessionDelete={deleteCreditSession}
                  session={session}
                />
              </DataList>
            )}
          />
          <Route
            render={() => (
              <>
                {!session && loading && <PageLoader />}
                {!session && !loading && (
                  <div style={{ marginTop: '1em' }}>
                    Session with id: <code>{sessionId}</code> not found.
                  </div>
                )}
                {session && (
                  <Details
                    session={session}
                    user={user}
                    includeIntegrityInfo={!isMSP}
                  />
                )}
              </>
            )}
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
