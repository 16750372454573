import React from 'react';
import { Box, FeatureFlag } from 'components';
import { Item, Icon, Header } from 'semantic';
import { formatDuration } from 'utils/date';
import { useDashboard } from '../context';
import {
  formatNumber,
  roundUpZeroDigits,
  formatCurrency,
} from 'utils/formatting';
import widgetstyles from '../widgetstyles.module.less';
import { useTranslation } from 'react-i18next';

export default function MyCardUsage() {
  const { msp, isAccount, hasCards } = useDashboard();
  const { t } = useTranslation();

  const progressDegrees = () => {
    if (!msp.monthlyCreditLimit) {
      return '0deg';
    }
    const ratio = Math.min(
      msp.monthlyCreditUsage / msp.monthlyCreditLimit,
      1.0
    );
    const deg = ratio * 360.0;
    return roundUpZeroDigits(deg) + 'deg';
  };

  const title = isAccount
    ? t('dashboardHome.myCardUsageTitleAccount', "My Account's Card Usage")
    : t('dashboardHome.myCardUsageTitle', 'My Card Usage');

  if (!hasCards) {
    return (
      <Box title={title}>
        <Box.Content>
          <div className={widgetstyles.itemGrid}>
            <div className={widgetstyles.itemGridColumn}>
              <Item.Group unstackable className={widgetstyles.itemGroup}>
                <Item>
                  <Item.Image style={{ width: '48px' }}>
                    <Icon name="bolt" className={widgetstyles.itemIcon} />
                  </Item.Image>
                  <Item.Content verticalAlign="middle">
                    <Header as="div">
                      {t(
                        'dashboard.myCardUsagePlaceHolder',
                        'Start your EV Charge'
                      )}
                    </Header>
                    <div className={widgetstyles.itemSubheader}>
                      {t(
                        'dashboard.myCardUsagePlaceHolderSubHeader',
                        'You’re ready to begin your charging sessions'
                      )}
                    </div>
                  </Item.Content>
                </Item>
              </Item.Group>
            </div>
          </div>
        </Box.Content>
        <Box.Actions>
          <Box.ActionLink to="/my-map">
            {t(
              'dashboard.myCardUsageFindEvseLocations',
              'Find EV Charging Locations'
            )}
          </Box.ActionLink>
        </Box.Actions>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Box.Content>
        <div className={widgetstyles.itemGrid}>
          {isAccount && msp.monthlyCreditLimit && (
            <FeatureFlag feature="show_customer_credit_limit">
              <div className={widgetstyles.itemGridColumn}>
                <Item.Group unstackable className={widgetstyles.itemGroup}>
                  <Item style={{ height: 45, justifyContent: 'center' }}>
                    <div
                      className={widgetstyles.progressOut}
                      style={{ '--progressDeg': progressDegrees() }}>
                      <div className={widgetstyles.progressIn} />
                    </div>
                  </Item>
                  <Item style={{ height: 55 }}>
                    <Item.Content
                      verticalAlign="middle"
                      style={{ textAlign: 'center' }}>
                      <Header as="div">
                        {formatCurrency(msp.monthlyCreditUsage, {
                          currency: msp.billingCurrency,
                        })}
                      </Header>
                      <div className={widgetstyles.itemSubheader1}>
                        {t(
                          'dashboard.ofMonthlyLimit',
                          'of {{limit}} monthly limit',
                          {
                            limit: formatCurrency(msp.monthlyCreditLimit, {
                              currency: msp.billingCurrency,
                            }),
                          }
                        )}
                      </div>
                    </Item.Content>
                  </Item>
                  <Item>
                    <Item.Content
                      verticalAlign="middle"
                      style={{ textAlign: 'center', justifyContent: 'center' }}>
                      <div className={widgetstyles.itemSubheader2}>
                        {t('common.spentThisMonth', 'Spent this month') + ' '}
                      </div>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </div>
            </FeatureFlag>
          )}

          <div className={widgetstyles.itemGridColumn}>
            <Item.Group unstackable className={widgetstyles.itemGroup}>
              <Item style={{ height: 45, justifyContent: 'center' }}>
                <Item.Image style={{ width: '48px' }}>
                  <Icon
                    name="bolt-lightning"
                    className={widgetstyles.itemIcon}
                  />
                </Item.Image>
              </Item>
              <Item style={{ height: 55 }}>
                <Item.Content
                  verticalAlign="middle"
                  style={{ textAlign: 'center' }}>
                  <Header as="div">
                    {t('dashboard.myCardUsageKwh', '{{kwh}} kWh', {
                      kwh: formatNumber(
                        roundUpZeroDigits(msp.totalSessionsKwh)
                      ),
                    })}
                  </Header>
                  <div className={widgetstyles.itemSubheader2}>{'    '}</div>
                </Item.Content>
              </Item>
              <Item>
                <Item.Content
                  verticalAlign="middle"
                  style={{ textAlign: 'center' }}>
                  <div className={widgetstyles.itemSubheader2}>
                    {t('common.chargedLastMonth', 'Charged last month')}
                  </div>
                </Item.Content>
              </Item>
            </Item.Group>
          </div>

          <div className={widgetstyles.itemGridColumn}>
            <Item.Group unstackable className={widgetstyles.itemGroup}>
              <Item style={{ height: 45, justifyContent: 'center' }}>
                <Item.Image style={{ width: '48px' }}>
                  <Icon name="clock" className={widgetstyles.itemIcon} />
                </Item.Image>
              </Item>
              <Item style={{ height: 55 }}>
                <Item.Content
                  verticalAlign="middle"
                  style={{ textAlign: 'center' }}>
                  <Header as="div">
                    {msp.totalSessionsTime > 60
                      ? formatDuration(msp.totalSessionsTime)
                      : 'N / A'}
                  </Header>
                </Item.Content>
              </Item>
              <Item>
                <Item.Content
                  verticalAlign="middle"
                  style={{ textAlign: 'center' }}>
                  <div className={widgetstyles.itemSubheader2}>
                    {t('common.chargedLastMonth', 'Charged last month')}
                  </div>
                </Item.Content>
              </Item>
            </Item.Group>
          </div>

          <div className={widgetstyles.itemGridColumn}>
            <Item.Group unstackable className={widgetstyles.itemGroup}>
              <Item style={{ height: 45, justifyContent: 'center' }}>
                <Item.Image style={{ width: '48px' }}>
                  <Icon name="car-side" className={widgetstyles.itemIcon} />
                </Item.Image>
              </Item>
              <Item style={{ height: 55 }}>
                <Item.Content
                  verticalAlign="middle"
                  style={{ textAlign: 'center' }}>
                  <Header as="div">
                    {t(
                      'dashboard.myCardUsageSessions',
                      '{{sessions}} sessions',
                      {
                        sessions: formatNumber(msp.totalSessions),
                      }
                    )}
                  </Header>
                </Item.Content>
              </Item>
              <Item>
                <Item.Content
                  verticalAlign="middle"
                  style={{ textAlign: 'center' }}>
                  <div className={widgetstyles.itemSubheader2}>
                    {t('common.completedLastMonth', 'Completed last month')}
                  </div>
                </Item.Content>
              </Item>
            </Item.Group>
          </div>
        </div>
      </Box.Content>

      <Box.Actions>
        <Box.ActionLink to="/my-cards/usage">
          {t('dashboard.myCardUsageViewUsage', 'View Usage')}
        </Box.ActionLink>
        <Box.ActionLink to="/my-invoices">
          {t('dashboard.myCardUsageViewInvoices', 'View Invoices')}
        </Box.ActionLink>
      </Box.Actions>
    </Box>
  );
}
