import React from 'react';

import { Form, Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';

import UploadsField from 'components/form-fields/Uploads';

export default class ExecuteEvseCommand extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = {
    open: false,
    formValues: { ...this.props.initialValues },
  };

  handleSubmit = () => {
    const { evseControllerId, initialValues } = this.props;
    const { formValues } = this.state;
    this.setState({
      submitted: true,
      loading: true,
    });
    if (!formValues.firmwareUpload || !formValues.firmwareUpload.length) {
      return this.setState({
        error: new Error('No file was uploaded'),
        loading: false,
        sunmitted: false,
      });
    }
    request({
      method: 'POST',
      path: `/1/evse-controllers/${evseControllerId}/firmware`,
      body: {
        firmwareUpload: formValues.firmwareUpload[0].id,
      },
    })
      .then(({ data }) => {
        this.props.onDone(data);
        this.setState({
          formValues: initialValues,
          open: false,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  setField(name, value) {
    const { formValues } = this.state;
    this.setState({
      submitted: false,
      formValues: {
        ...formValues,
        [name]: value,
      },
    });
  }

  render() {
    const { trigger } = this.props;
    const { error, loading, formValues, submitted, open } = this.state;

    return (
      <Modal
        closeIcon
        closeOnDimmerClick={false}
        trigger={trigger}
        onClose={() =>
          this.setState({
            open: false,
            formValues: this.props.initialValues,
            submitted: false,
          })
        }
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>Update Firmware</Modal.Header>
        <Modal.Content>
          <Form
            error={submitted && Boolean(error)}
            onSubmit={() => this.handleSubmit()}>
            {error && <Message error content={error.message} />}
            <UploadsField
              type="code"
              name="firmwareUpload"
              label="Firmware Binary File"
              onChange={(uploads) => {
                this.setField('firmwareUpload', uploads);
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading || submitted}
            disabled={
              !formValues.firmwareUpload || !formValues.firmwareUpload.length
            }
            primary
            color="red"
            content="Execute"
            onClick={this.handleSubmit}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
