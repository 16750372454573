import React from 'react';
import { Divider, Grid, Segment, Statistic } from 'semantic';
import { withTranslation } from 'react-i18next';
import {
  Aggregate,
  AggregateTerms,
  ErrorBoundary,
  MultiSeriesChart,
} from 'react-tectonic';

import { changeFilterId, defaultColors } from 'utils/visualizations';
import CardinalityStatistic from 'components/analytics/CardinalityStatistic';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import TermsTable from 'components/analytics/TermsTable';
import MultiCardinalityDonutChart from 'components/analytics/MultiCardinalityDonutChart';
import TermsByTermsTable from 'components/analytics/TermsByTermsTable';
import { Link } from 'react-router-dom';
import { mergeFilters } from '../../../utils/visualizations';

class CpoEquipment extends React.Component {
  render() {
    const {
      t,
      hasUsersReference = true,
      includeEvseIssues = true,
      baseFilter = {},
    } = this.props;

    const locationFilter = changeFilterId(baseFilter, 'locationId');

    return (
      <ErrorBoundary>
        <Divider hidden />
        <Divider hidden />

        <Statistic.Group
          widths={includeEvseIssues ? 4 : 3}
          style={{ justifyContent: 'space-between' }}>
          <CardinalityStatistic
            collection="evsecontrollers"
            label="Manufacturers"
            field="bootInfo.chargePointVendor"
            filter={baseFilter}
          />
          <CardinalityStatistic
            collection="evsecontrollers"
            label="Models"
            field="bootInfo.chargePointModel"
            filter={baseFilter}
          />

          <CardinalityStatistic
            collection="evsecontrollers"
            label="Firmware Versions"
            field="bootInfo.firmwareVersion"
            filter={baseFilter}
          />

          {includeEvseIssues && (
            <CardinalityStatistic
              collection="evseissues"
              label="Resolved Issues"
              filter={mergeFilters(
                {
                  terms: [{ isResolved: true }],
                },
                baseFilter
              )}
            />
          )}
        </Statistic.Group>

        <Divider hidden />
        <Divider hidden />

        <AggregateTerms
          collection="evsecontrollers"
          aggField="bootInfo.chargePointVendor"
          operation="count"
          timeRangeDateField="createdAt"
          filter={baseFilter}
          termsSize={6}>
          {({ data }) => {
            return (
              <Aggregate
                type="time-series"
                collection="evsecontrollers"
                dateField="createdAt"
                requests={data.map((term) => {
                  return {
                    operation: 'count',
                    dateField: 'createdAt',
                    filter: mergeFilters(
                      {
                        terms: [{ 'bootInfo.chargePointVendor': term.key }],
                      },
                      baseFilter
                    ),
                  };
                })}>
                <MultiSeriesChart
                  title={'Installations by Manufacturers'}
                  colors={defaultColors}
                  titleAlign="center"
                  height={350}
                  chartType="area"
                  stacked
                  valueField="count"
                  labels={data.map((term) => term.key)}
                />
              </Aggregate>
            );
          }}
        </AggregateTerms>

        <Divider hidden />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsDonutChart
                  collection="locations"
                  aggField="evses.connectors.standard"
                  operation="count"
                  title="Connector Standards"
                  filter={locationFilter}
                  termsSize={15}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsDonutChart
                  collection="locations"
                  aggField="evses.connectors.power_type"
                  operation="count"
                  filter={locationFilter}
                  title="Power Types"
                  termsSize={15}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment basic>
                <TermsByTermsTable
                  collection="evsecontrollers"
                  filter={baseFilter}
                  aggField="bootInfo.chargePointVendor"
                  aggField2="bootInfo.chargePointModel"
                  operation="count"
                  valueField="count"
                  valueFieldLabel="EVSEs"
                  title="EVSE Hardware"
                  label=""
                  label2="Top Hardware Models"
                  termsSize={10}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsTable
                  collection="locations"
                  filter={locationFilter}
                  aggField="evses.connectors.max_amperage"
                  operation="count"
                  valueField="count"
                  valueFieldLabel="Connectors"
                  labelFormatter={(item) => `${item}A`}
                  title="Max Amperage"
                  termsSize={15}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsDonutChart
                  collection="locations"
                  filter={locationFilter}
                  aggField="evses.connectors.max_voltage"
                  labelFormatter={(item) => `${item}V`}
                  operation="count"
                  title="Max Voltage"
                  termsSize={15}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {includeEvseIssues && (
          <React.Fragment>
            <Divider hidden />
            <Divider hidden />

            <Grid>
              <Grid.Row>
                <Grid.Column computer={8} mobile={16}>
                  <Segment basic>
                    {hasUsersReference ? (
                      <TermsTable
                        filter={baseFilter}
                        collection="evseissues"
                        aggField="resolvedByUserId"
                        operation="count"
                        valueField="count"
                        valueFieldName="Issues"
                        title="Top Issue Resolvers"
                        fetchReference="users"
                        referenceLabelFormatter={(item) => {
                          return (
                            <Link to={`/accounts/users/${item.id}`}>
                              {item.contact.firstName}
                            </Link>
                          );
                        }}
                        termsSize={15}
                      />
                    ) : (
                      <TermsTable
                        filter={baseFilter}
                        collection="evseissues"
                        aggField="resolvedByUserName"
                        operation="count"
                        valueField="count"
                        valueFieldName="Issues"
                        title="Top Issue Resolvers"
                        termsSize={15}
                      />
                    )}
                  </Segment>
                </Grid.Column>
                <Grid.Column computer={8} mobile={16}>
                  <Segment basic>
                    <TermsTable
                      filter={baseFilter}
                      title="Issue Types"
                      collection="evseissues"
                      aggField="type"
                      operation="count"
                      valueField="count"
                      valueFieldName="Issues"
                      labelFormatter={(item) => `${item}`}
                      termsSize={15}
                      style={{ height: '100%' }}
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Divider hidden />
            <Divider hidden />

            <Grid>
              <Grid.Row>
                <Grid.Column computer={8} mobile={16}>
                  <Segment basic>
                    <TermsDonutChart
                      filter={baseFilter}
                      collection="evseissues"
                      aggField="severity"
                      operation="count"
                      title="Issue Severities"
                      termsSize={15}
                    />
                  </Segment>
                </Grid.Column>
                <Grid.Column computer={8} mobile={16}>
                  <Segment basic>
                    <MultiCardinalityDonutChart
                      title="Issue Resolution Status"
                      collection="evseissues"
                      items={[
                        {
                          label: 'Resolved',
                          request: {
                            collection: 'evseissues',
                            fields: ['id'],
                            filter: mergeFilters(
                              {
                                terms: [{ isResolved: true }],
                              },
                              baseFilter
                            ),
                          },
                        },
                        {
                          label: 'Open',
                          request: {
                            collection: 'evseissues',
                            fields: ['id'],
                            filter: mergeFilters(
                              {
                                terms: [{ isResolved: false }],
                              },
                              baseFilter
                            ),
                          },
                        },
                      ]}
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </React.Fragment>
        )}

        <Divider hidden />
        <Divider hidden />
      </ErrorBoundary>
    );
  }
}

export default withTranslation()(CpoEquipment);
