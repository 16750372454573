import React, { useState, useMemo } from 'react';
import Pagination from 'components/Pagination';

import styled from 'styled-components';
import { formatCurrency } from 'utils/formatting';
import { Link } from 'react-router-dom';
import { request } from 'utils/api';

import {
  Button,
  Divider,
  Label,
  Loader,
  Message,
  Modal,
  Segment,
  Table,
} from 'semantic';
import { userCanAccessProviderEndpoint } from 'utils/roles';
import EditTariffProfile from 'components/modals/EditTariffProfile/EditTariffProfile';
import { formatDateTime } from 'utils/date';

import './Table.less';
import { useUser } from 'contexts/user';
import { useFeatures } from 'contexts/features';
import { useTranslation } from 'react-i18next';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

/**
 *
 * @param {TariffProfileCurrentCostSettings} object
 * @param {string} currency
 * @return {React.JSX.Element}
 */
function formatTariff(
  currency,
  { pricePerKwh, pricePerHour, pricePerSession }
) {
  // const currency = object.currency ?? currencyOverwrite;
  const items = [];
  if (pricePerKwh) {
    items.push(
      <Label
        content={formatCurrency(pricePerKwh, {
          precision: 2,
          currency: currency,
        })}
        detail="per kWh"
      />
    );
  }
  if (pricePerHour) {
    items.push(
      <Label
        content={formatCurrency(pricePerHour, {
          precision: 2,
          currency: currency,
        })}
        detail="per Hour"
      />
    );
  }
  if (pricePerSession) {
    items.push(
      <Label
        content={formatCurrency(pricePerSession, {
          precision: 2,
          currency: currency,
        })}
        detail="per Session"
      />
    );
  }
  return <div style={{ marginBottom: '5px' }}>{items}</div>;
}

/**
 *
 * @param {object} props
 * @param props.status
 * @param {Array<TariffProfile>} props.data
 * @param props.limit
 * @param props.meta
 * @param props.page
 * @param props.currencies
 * @param props.onRefresh
 * @param props.onDeleteItem
 * @param props.setPage
 * @return {React.JSX.Element}
 */
export default function DataTable({
  status,
  data: items,
  limit,
  meta = {},
  page,
  currencies,
  onRefresh,
  onDeleteItem,
  setPage,
}) {
  const { t } = useTranslation();
  const { user, provider } = useUser();
  const [enrichedItems, setEnrichedItems] = useState([]);
  const writeAccess = userCanAccessProviderEndpoint(
    user,
    provider,
    'tariffProfiles',
    'write'
  );

  useMemo(() => {
    request({
      method: 'GET',
      path: `/1/tariff-profiles/usage-summary`,
    })
      .then((result) => {
        setEnrichedItems(enrichItemsWithUsage(items, result?.data));
      })
      .catch(() => {
        setEnrichedItems(items);
      });
  }, [items]);

  const { hasFeature } = useFeatures();
  // TODO: Remove this one tarrifProfiles v2 migration is Complete
  const supportsNewSchema = hasFeature('tariff-profiles-v2');

  return (
    <div className="list">
      {status.success && !items.length && (
        <Message>No Tariff Profiles created yet</Message>
      )}

      {status.success && enrichedItems?.length > 0 && (
        <div>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell width={2}>No. Of Evse</Table.HeaderCell>
                <Table.HeaderCell width={2}>Currency</Table.HeaderCell>
                <Table.HeaderCell width={4}>Tariffs</Table.HeaderCell>
                <Table.HeaderCell width={3}>Created</Table.HeaderCell>
                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {enrichedItems.map((item) => {
                return (
                  <Table.Row verticalAlign="top" key={item.id}>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {item?.usage > 0 ? (
                        <Link
                          to={`/charging-stations?page=1&tariffProfiles=${item.id}`}>
                          {item.usage}
                        </Link>
                      ) : (
                        0
                      )}
                    </Table.Cell>
                    <Table.Cell>{item.currency}</Table.Cell>
                    <Table.Cell>
                      {supportsNewSchema && (
                        <TariffACSection item={item} currency={item.currency} />
                      )}
                      <TariffDCSection item={item} currency={item.currency} />
                    </Table.Cell>
                    <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <EditTariffProfile
                        initialValues={item}
                        currencies={currencies}
                        onDone={() => onRefresh()}
                        trigger={
                          <Button
                            disabled={!writeAccess}
                            basic
                            title="Edit Tariff Profile"
                            icon="pen-to-square"
                          />
                        }
                      />

                      <DeleteTariffProfile
                        writeAccess={writeAccess}
                        onDeleteItem={onDeleteItem}
                        item={item}
                        t={t}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
      <Divider hidden />
      {status.success && meta.total > limit && (
        <Center>
          <Pagination
            limit={limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) => {
              setPage(activePage).then(() => {
                window.scrollTo(0, 0);
              });
            }}
          />
        </Center>
      )}
      {status.request ||
        (!enrichedItems?.length && (
          <Segment style={{ height: '100px' }}>
            <Loader active>Loading</Loader>
          </Segment>
        ))}
      {status.error && <Message error content={status.error.message} />}
    </div>
  );
}

/**
 * @param {Object} props
 * @param {boolean} props.writeAccess - Whether the user has write access
 * @param {function} props.onDeleteItem - Callback to delete the item
 * @param {?TariffProfile} props.item - Tariff profile to delete
 * @param {function} props.t - Translation function
 * @return {React.JSX.Element}
 */
function DeleteTariffProfile({ writeAccess, onDeleteItem, item, t }) {
  if (item?.usage > 0) {
    return (
      <Modal
        style={{ width: '50em' }}
        header={t(
          'tariffProfiles.cannotDelete.title',
          'Cannot delete the tariff profile'
        )}
        content={
          <div style={{ padding: '2em' }}>
            <p>
              {t(
                'tariffProfiles.cannotDelete.descriptionOne',
                'This tariff profile is assigned to existing EVSEs.'
              )}
            </p>
            <p>
              {t(
                'tariffProfiles.cannotDelete.descriptionTwo',
                'Please remove this tariff profile from EVSEs first and then try to delete it again.'
              )}
            </p>
          </div>
        }
        trigger={
          <Button disabled={!writeAccess} basic icon="trash" title="Delete" />
        }
        closeIcon
        actions={[
          {
            key: 'close',
            primary: true,
            content: 'Close',
            onClick: () => {},
          },
        ]}
      />
    );
  }

  return (
    <Modal
      header={`Are you sure you want to delete?`}
      content="All data will be permanently deleted"
      trigger={
        <Button disabled={!writeAccess} basic icon="trash" title="Delete" />
      }
      closeIcon
      actions={[
        {
          key: 'delete',
          primary: true,
          content: 'Delete',
          onClick: () => onDeleteItem(item),
        },
      ]}
    />
  );
}

/**
 *
 * @param {Object} props
 * @param {TariffProfile} props.item
 * @param {string} props.currency
 * @return {React.JSX.Element}
 */
function TariffACSection({ currency, item }) {
  let additionalTariffCosts = [];
  if (item.enabledCustomTariffs === 'issuer') {
    for (const tariff of item.customIssuerTariffs) {
      if (!tariff.costSettings?.ac) {
        continue;
      }
      additionalTariffCosts.push(
        <div>
          <span>{tariff.issuerName}</span>
          {formatTariff(currency, tariff.costSettings.ac)}
        </div>
      );
    }
  }
  if (item.enabledCustomTariffs === 'infra-token-provider') {
    for (const tariff of item.customInfraTokenProviderTariffs) {
      if (!tariff.costSettings?.ac) {
        continue;
      }
      additionalTariffCosts.push(
        formatTariff(currency, tariff.costSettings.ac)
      );
    }
  }

  return (
    <>
      <span>AC Connector</span>

      <div className="connector-box">
        <p
          style={{
            margin: '0 0 5px 0',
            fontSize: '12px',
            fontWeight: '500',
          }}>
          Base tariff:
        </p>
        {item.costSettings?.ac &&
          formatTariff(item.currency, item.costSettings.ac)}
      </div>

      {additionalTariffCosts.length > 0 && (
        <div className="connector-box">{additionalTariffCosts}</div>
      )}
    </>
  );
}

const enrichItemsWithUsage = (items, tariffProfileUsage) => {
  if (!tariffProfileUsage?.length) {
    return items;
  }

  return items.map((item) => {
    item.usage = 0;
    tariffProfileUsage
      ?.filter((tpUsage) => tpUsage?.id === item?.id)
      ?.map((itemUsage) => {
        item.usage = itemUsage?.evseControllerUsage;
      });
    return item;
  });
};

/**
 * @param {Object} props
 * @param {TariffProfile} props.item
 * @param {string} props.currency
 * @return {React.JSX.Element}
 */
function TariffDCSection({ currency, item }) {
  const { hasFeature } = useFeatures();

  // TODO: Clean this after tariffProfile v2 migration
  const supportsNewSchema = hasFeature('tariff-profiles-v2');
  if (!supportsNewSchema) {
    // This is the source of truth in tariffProfiles v2, so we need to assign it so that we don't need to refactor under this widget.
    if (!item.costSettings?.dc) {
      item.costSettings.dc = {
        pricePerKwh: item.costSettings.pricePerKwh,
        pricePerHour: item.costSettings.pricePerHour,
        pricePerSession: item.costSettings.pricePerSession,
        _id: Math.random(),
      };
    }
  }

  let additionalTariffCosts = [];
  if (item.enabledCustomTariffs === 'issuer') {
    for (const tariff of item.customIssuerTariffs) {
      if (!tariff.costSettings?.dc) {
        continue;
      }
      additionalTariffCosts.push(
        <div>
          <span>{tariff.issuerName}</span>
          {formatTariff(currency, tariff.costSettings.dc)}
        </div>
      );
    }
  }
  if (item.enabledCustomTariffs === 'infra-token-provider') {
    for (const tariff of item.customInfraTokenProviderTariffs) {
      if (!tariff.costSettings?.dc) {
        continue;
      }
      additionalTariffCosts.push(
        <div>
          <span>{tariff.issuerName}</span>
          {formatTariff(currency, tariff.costSettings.dc)}
        </div>
      );
    }
  }

  return (
    <>
      {supportsNewSchema && <span>DC Connector</span>}

      <div className="connector-box">
        <p
          style={{
            margin: '0 0 5px 0',
            fontSize: '12px',
            fontWeight: '500',
          }}>
          Base tariff:
        </p>
        {item.costSettings?.dc &&
          formatTariff(item.currency, item.costSettings.dc)}
      </div>
      {additionalTariffCosts.length > 0 && (
        <div className="connector-box">{additionalTariffCosts}</div>
      )}
    </>
  );
}
