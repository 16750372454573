import React from 'react';

import { Dropdown } from 'semantic';

import InspectObject from 'components/modals/InspectObject';

import { useTranslation } from 'react-i18next';

import EditToken from 'components/modals/EditToken';
import BlockToken from 'components/modals/BlockToken';

import { request } from 'utils/api';
import { Confirm } from 'components';

import { currentUserCanAccess, currentUserIsSuperAdmin } from 'utils/roles';
import RemoteTokenSearch from '../../components/modals/RemoteTokenSearch';
import SyncLogStatus from '../../components/modals/SyncLogStatus';
import { OCPI } from 'components/RemoteTokenSearch';

export default function TokensActions({ item, onReload } = {}) {
  const { t } = useTranslation();

  const writeAccess = currentUserCanAccess('tokens', 'write');

  function onDelete() {
    return request({
      method: 'DELETE',
      path: `/1/tokens/${item.id}`,
    });
  }

  const fetchToken = async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/tokens/${item.id}`,
    });

    return data;
  };

  return (
    <>
      {writeAccess && (
        <EditToken
          onSave={onReload}
          getData={fetchToken}
          trigger={
            <Dropdown.Item
              text={t('tokens.editToken', 'Edit token')}
              icon="pen-to-square"
              disabled={!(writeAccess && item.customId)}
            />
          }
        />
      )}
      <BlockToken
        token={item}
        trigger={
          <Dropdown.Item
            text={t('tokens.blockUnblockToken', 'Block or unblock token')}
            icon="ban"
            disabled={!item.customId}
          />
        }
        onSave={onReload}
      />
      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: item.customId }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item icon="trash" text="Delete" disabled={!writeAccess} />
        }
        onConfirm={async () => {
          await onDelete(item);
          await onReload();
        }}
      />

      <InspectObject
        name="Token"
        getData={fetchToken}
        trigger={<Dropdown.Item text="Inspect" icon="code" />}
      />

      {currentUserIsSuperAdmin() && (
        <>
          <SyncLogStatus
            getData={fetchToken}
            modelName="token"
            size="fullscreen"
            trigger={
              <Dropdown.Item text="Sync status" icon="wand-magic-sparkles" />
            }
          />
          <RemoteTokenSearch
            name="RemoteToken"
            tokenUid={item.uid}
            allowedProtocols={[OCPI]}
            trigger={<Dropdown.Item text="Check Remote State" icon="cloud" />}
          />
        </>
      )}
    </>
  );
}
