import React from 'react';

import {
  formatEuro,
  formatKwh,
  formatSessionStatus,
  roundUpTwoDigits,
} from 'utils/formatting';
import { Link } from 'react-router-dom';
import { formatDateTime, formatDuration } from 'utils/date';
import { request } from 'utils/api';

import { Button, Dropdown, Icon, Table } from 'semantic';

import { useTranslation } from 'react-i18next';
import { CPO_SESSION_EXCLUDED_REASON } from 'types/sessions';
import { CpoSession } from 'types/cpo-session';
import { useSearchContext } from 'components/search/Context';
import AddSessionToAccessGroup from 'components/modals/AddSessionToAccessGroup';
import ViewSessionCalibration from 'components/modals/ViewSessionCalibration';
import EvsePaymentTerminalSessions from 'screens/SessionsCpo/EvsePaymentTerminalSessions';
import DropdownMenu from 'components/DropdownMenu';
import { PaymentMethod } from 'components/cpo-payment/paymentMethod';
import SessionDetails from 'screens/MyCpoUsage/SessionDetails';

export default function SessionsTable() {
  const { t } = useTranslation();
  const { items, loading, reload } = useSearchContext<CpoSession>();

  async function fetchSession(id: string) {
    const response = await request({
      method: 'GET',
      path: `/2/sessions/cpo/mine/${id}`,
    });
    return response.data;
  }

  async function fetchEvsePaymentTerminalSession({
    sessionId,
  }: {
    sessionId: string;
  }) {
    const result = await request({
      method: 'GET',
      path: `/2/sessions/cpo/mine/${sessionId}/evse-payment-terminal-session`,
    });

    return result.data;
  }

  function isExcluded(item: CpoSession) {
    return (
      item.excluded &&
      ![CPO_SESSION_EXCLUDED_REASON.EXTERNAL_PAYMENT_SOLUTION].includes(
        item.excludedReason
      )
    );
  }

  if (!items.length || loading) return null;

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>
            {t('sessionTable.columnId', 'ID')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('sessionTable.columnLocation', 'Location')}
          </Table.HeaderCell>
          <Table.HeaderCell width={3}>
            {t('sessionTable.columnDuration', 'Duration')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('sessionTable.columnPriceTotal', 'Total')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('sessionTable.columnkWh', 'kWh')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('sessionTable.columnStatus', 'Session Status')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('sessionTable.columnPaymentMethod', 'Payment Method')}
          </Table.HeaderCell>
          <Table.HeaderCell width={3}>
            {t('sessionTable.columnActions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item) => (
          <Table.Row key={item.id}>
            <Table.Cell>
              {item.rawRecord && item.rawRecord.signedData && (
                <Icon
                  title="Calibration verification available for this session"
                  name="key"
                  style={{
                    float: 'right',
                    color: '#ccc',
                    marginTop: '2px',
                  }}
                />
              )}
              {isExcluded(item) ? (
                <span style={{ textDecoration: 'line-through' }}>
                  {item.externalId}
                </span>
              ) : (
                item.externalId
              )}
            </Table.Cell>

            <Table.Cell>
              {item.location ? (
                <>
                  <Link
                    to={`/charging-stations/locations/${
                      item.location.id || item.locationId
                    }`}>
                    {item.location.name}
                  </Link>
                  {item.evseController && (
                    <p>
                      <small>
                        (
                        {item.evseController.id || item.evseControllerId ? (
                          <span>
                            <Link
                              to={`/charging-stations/${
                                item.evseController.id || item.evseControllerId
                              }`}>
                              {item.evseController.ocppIdentity}
                            </Link>{' '}
                            #{item.connectorId}
                          </span>
                        ) : (
                          `${item.evseController.ocppIdentity} #${item.connectorId}`
                        )}
                        )
                      </small>
                    </p>
                  )}
                </>
              ) : (
                '-'
              )}
            </Table.Cell>

            <Table.Cell width={3}>
              <div>{formatDuration(item.durationSeconds)}</div>
              <div style={{ fontSize: '10px' }}>
                {t('common.start', 'Start')}: {formatDateTime(item.startedAt)}
                <br />
                {item.endedAt ? (
                  <>
                    {t('common.end', 'End')}: {formatDateTime(item.endedAt)}
                  </>
                ) : (
                  <>
                    {t('common.end', 'End')}:{' '}
                    {t('common.inProgress', 'In progress')}
                  </>
                )}
              </div>
            </Table.Cell>

            <Table.Cell textAlign="right">
              {formatEuro(
                roundUpTwoDigits(
                  (item.timeCosts ?? 0) +
                    (item.startCosts ?? 0) +
                    (item.energyCosts ?? 0)
                )
              )}
            </Table.Cell>

            <Table.Cell title={item.kwh || 0} textAlign="right">
              {item.kwh ? formatKwh(item.kwh) : '-'}
            </Table.Cell>

            <Table.Cell textAlign="center">
              {formatSessionStatus(item.status)}
            </Table.Cell>

            <Table.Cell textAlign="center">
              <PaymentMethod
                source={item.authorization?.payment?.productPaymentFlow}
                t={t}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              <SessionDetails
                getData={async () => fetchSession(item._id || item.id)}
                trigger={
                  <Button
                    // @ts-ignore
                    basic
                    icon="magnifying-glass"
                    title={t('common.viewSession', 'View Session')}
                    style={{ marginBottom: '3px' }}
                  />
                }
              />

              <DropdownMenu text={t('sessions.dropdownMenuText', 'More')}>
                {item.rawRecord?.tokenVisualNumber && (
                  <AddSessionToAccessGroup
                    // @ts-ignore
                    size="tiny"
                    visualNumber={item.rawRecord.tokenVisualNumber}
                    trigger={
                      <Dropdown.Item
                        icon="key"
                        text={t(
                          'sessionTable.addToAccessGroup',
                          'Add To Access Group'
                        )}
                      />
                    }
                  />
                )}

                {item.rawRecord && item.rawRecord.signedData && (
                  <ViewSessionCalibration
                    getData={() => fetchSession(item._id || item.id)}
                    trigger={
                      <Dropdown.Item
                        icon="key"
                        text={t('common.viewVerification', 'View Verification')}
                      />
                    }
                  />
                )}

                {item.evsePaymentTerminalSessionId && (
                  <EvsePaymentTerminalSessions
                    getData={() =>
                      fetchEvsePaymentTerminalSession({
                        sessionId: item.id,
                      })
                    }
                    size="medium"
                    trigger={
                      <Dropdown.Item
                        text={t(
                          'evsePaymentTerminalSession.showDetails',
                          'Show payment details'
                        )}
                        icon="file"
                      />
                    }
                  />
                )}
              </DropdownMenu>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
