import { useBillingInfoFormikValidationSchema } from 'components/BillingInfo/formik-validation';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useUser } from 'contexts/user';
import { AccountDetails } from 'screens/Auth/SignupV2/steps/AccountDetails';
import { Button, Checkbox, Divider, Form, Header, Icon } from 'semantic';
import {
  BillingAndRequestCardFormValues,
  BillingInfo,
} from 'components/BillingInfo/types';
import { useProductInstrumentation } from 'contexts/productInstrumentation';
import TypeAndPlan from 'components/modals/RequestCard/v2/TypeAndPlan';
import { useChargeCardBillingPlans } from 'components/modals/RequestCard/v2/TypeAndPlan/useBillingPlans';
import { BillingPlan } from 'types/billingplan';
import { SelectCardHolderType } from 'components/modals/RequestCard/v2/CardHolderAndBilling/SelectCardHolderType';
import {
  NewCardHolderInfo,
  useCardHolderInfoFormikValidationSchema,
} from 'components/modals/RequestCard/v2/CardHolderAndBilling/CardHolderInfo';
import { StyledSegment } from 'components/modals/RequestCard/v2/CardHolderAndBilling/StyledSegment';
import { Grid } from 'semantic-ui-react';
import {
  DeliveryAddressFormPart,
  DeliveryAddressSameAsBilling,
  useDeliveryAddressFormikValidationSchema,
} from 'components/modals/RequestCard/v2/CardHolderAndBilling/DeliveryAddress';
import { BillingInfoFormikForm } from 'components/BillingInfo/formik-form';

export function billingInfoByAccountType(
  billingInfo: BillingInfo,
  accountType: AccountDetails['accountType']
): BillingInfo {
  const baseInfo = {
    paymentMethod: billingInfo.paymentMethod,
    accountHolderName: billingInfo.accountHolderName,
    ibanNumber: billingInfo.ibanNumber,
    bicNumber: billingInfo.bicNumber,
    countryCode: billingInfo.countryCode,
    street: billingInfo.street,
    number: billingInfo.number,
    postalCode: billingInfo.postalCode,
    city: billingInfo.city,
    sepaAccepted: billingInfo.sepaAccepted,
  };
  if (accountType === 'organization') {
    return {
      ...baseInfo,
      vatNumber: billingInfo.vatNumber || '',
      chamberOfCommerceNumber: billingInfo.chamberOfCommerceNumber || '',
      vatNotApplicable: billingInfo.vatNotApplicable || false,
    };
  }
  return baseInfo;
}

type BillingInfoProps = {
  value: BillingAndRequestCardFormValues;
  planId: string;
  onSubmit: (value: BillingAndRequestCardFormValues) => void;
  showErrorLabels?: boolean;
  formikRef?: MutableRefObject<FormikProps<BillingAndRequestCardFormValues> | null>;
};

export default function BillingInfoStep(props: BillingInfoProps) {
  const { t, i18n } = useTranslation();
  const vatPercentage = useVatPercentage();
  const billingPlanData = useChargeCardBillingPlans();

  // used to filter the billing plans shown to the user based on query params
  const orangePlanId = '63d268ed7f7b2e001242b01b';
  const payAsYouChargePlanId = '63d268ccdcf4020012397d5d';
  const stgOrangePlanID = '5d6fa1e82a84660f52d3c5f4';
  const stgPayAsYouChargePlanID = '5d6fa1e82a846615f4d3c5f3';

  // we cannot access FF when the user is not logged in so enableBicCollection is passed always as true
  const billingInfoValidationSchema = useBillingInfoFormikValidationSchema(
    props.value.accountDetails.accountType,
    true
  );

  const [billingPlansShown, setBillingPlansShown] = useState<BillingPlan[]>([]);

  useEffect(() => {
    const { data } = billingPlanData;

    if (!data || data.length === 0) {
      setBillingPlansShown([]);
      return;
    }

    if (props.planId === orangePlanId || props.planId === stgOrangePlanID) {
      // if the customer selected the orange plan, only show that plan
      setBillingPlansShown(data.filter((plan) => plan.id === props.planId));
    } else if (
      props.planId === payAsYouChargePlanId ||
      props.planId === stgPayAsYouChargePlanID
    ) {
      // if the customer selected the pay-as-you-charge plan, show both plans with pay-as-you-charge first
      setBillingPlansShown(
        data.sort((a, b) => {
          if (
            a.id === payAsYouChargePlanId ||
            a.id === stgPayAsYouChargePlanID
          ) {
            return -1;
          }
          if (
            b.id === payAsYouChargePlanId ||
            a.id === stgPayAsYouChargePlanID
          ) {
            return 1;
          }
          return 0;
        })
      );
    } else {
      // by default, show all plans available
      setBillingPlansShown(data);
    }
  }, [billingPlanData.data, props.planId]);

  const initialValues = useMemo(() => {
    const { data } = billingPlanData;

    // if the user selected a plan that is not available, reset the planId
    let billingPlanId = props.planId;
    if (
      props.planId !== orangePlanId &&
      props.planId !== stgOrangePlanID &&
      props.planId !== payAsYouChargePlanId &&
      props.planId !== stgPayAsYouChargePlanID
    ) {
      billingPlanId = '';
    }

    // Ensure data is available
    if (!data || data.length === 0) {
      return {
        planId: billingPlanId || '',
        accountDetails: props.value.accountDetails,
        card: {
          type: 'card' as 'card' | 'tag',
          billingPlanId: billingPlanId || '',
        },
        cardHolder: {
          ...props.value.cardHolder,
          type: 'existing' as const,
          userId:
            props.value.cardHolder.type === 'existing'
              ? props.value.cardHolder.userId
              : '',
        },
        delivery: {
          useSameAsBilling: true as const,
        },
        billing: {
          ...props.value.billing,
        },
      };
    }

    return {
      planId: billingPlanId === '' ? data[0].id : billingPlanId,
      accountDetails: props.value.accountDetails,
      card: {
        type: 'card' as 'card' | 'tag',
        billingPlanId: billingPlanId === '' ? data[0].id : billingPlanId,
      },
      cardHolder: props.value.cardHolder,
      delivery: {
        useSameAsBilling: true as const,
      },
      billing: {
        ...props.value.billing,
      },
    };
  }, [props.value, billingPlanData.data]);

  const cardHolderValidationSchema = useCardHolderInfoFormikValidationSchema();
  const deliveryValidationSchema = useDeliveryAddressFormikValidationSchema();

  const { productInstrumentation } = useProductInstrumentation();

  const sendClick = useCallback((target: string) => {
    productInstrumentation?.trackClick({
      target,
      journey: 'sign-up',
      step: 'billing-details',
      variant: '1.1.0',
    });
  }, []);

  useEffect(() => {
    productInstrumentation?.trackJourney({
      journey: 'sign-up',
      stage: 'started',
      step: 'billing-details',
      variant: '1.1.0',
      accountType: props.value.accountDetails.accountType,
    });
  }, []);

  return (
    <Formik<BillingAndRequestCardFormValues>
      enableReinitialize // this is needed to re-render the form when the initial values change
      innerRef={props.formikRef}
      initialValues={initialValues}
      onSubmit={props.onSubmit}
      validationSchema={Yup.object({
        billing: Yup.object(billingInfoValidationSchema),
        cardHolder: Yup.object(cardHolderValidationSchema),
        delivery: Yup.object(deliveryValidationSchema),
      })}>
      {({ handleSubmit, values, setValues }) => (
        <Form>
          <TypeAndPlan
            vatPercentage={vatPercentage}
            billingPlans={billingPlansShown}
          />

          <Divider hidden />

          {/*
          <Header as="h3" textAlign="center">
            {t('signup.selectCardHolder', 'Select Card Holder')}
          </Header>
          <SelectCardHolderType
            isPrimaryUser={true} // during signup, the only existing user is the primary user
            selected={values.cardHolder.type}
            onSelect={(nextCardHolderType) =>
              setValues({
                ...values,
                cardHolder: {
                  ...values.cardHolder,
                  type: nextCardHolderType,
                },
                delivery: {
                  useSameAsBilling: true,
                },
              })
            }
          />
         */}

          {values.cardHolder.type === 'new' && (
            <StyledSegment>
              <Grid padded="horizontally">
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div>
                      <Header
                        as="h3"
                        style={{ paddingLeft: 0, fontWeight: 600 }}>
                        {t('requestCardModal.addNewUserHeader', 'New User')}
                      </Header>
                      <NewCardHolderInfo
                        showErrorLabels={props.showErrorLabels}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </StyledSegment>
          )}

          <Divider hidden />

          <Header as="h3" textAlign="center">
            {t('billingInfo.billingDetailsHeader', 'Billing Details')}
          </Header>
          <StyledSegment>
            <Form>
              <BillingInfoFormikForm
                values={values.billing}
                accountType={
                  props.value?.accountDetails?.accountType || 'individual'
                }
                /* we cannot access FF when the user is not logged in so enableBicCollection is passed always as true */
                enableBicCollection={true}
                enableCountryCodeInput={!values?.billing?.countryCode}
                objectPath="billing"
                showErrorLabels={props.showErrorLabels}
              />
            </Form>
          </StyledSegment>

          <Divider hidden />

          <StyledSegment>
            <Grid padded="horizontally">
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3" style={{ fontWeight: 600 }}>
                    {t(
                      'addCardModal.deliveryAddressHeader',
                      'Delivery Address'
                    )}
                  </Header>
                  <Form.Field>
                    <Checkbox
                      toggle
                      checked={values.delivery.useSameAsBilling}
                      onChange={(e, { checked }) =>
                        setValues({
                          ...values,
                          delivery: {
                            ...values.delivery,
                            useSameAsBilling: checked,
                          },
                        })
                      }
                      label={
                        <label style={{ fontWeight: 'bold' }}>
                          {t(
                            'addCardModal.useBillingDeliveryAddress',
                            'Use same as billing'
                          )}
                        </label>
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider hidden />
            {values.delivery.useSameAsBilling ? (
              <DeliveryAddressSameAsBilling hiddenForm={true} />
            ) : (
              <DeliveryAddressFormPart
                objectPath="delivery"
                showErrorLabels={props.showErrorLabels}
              />
            )}
          </StyledSegment>

          <div>
            <Button
              as="button"
              type="submit"
              onClick={() => {
                sendClick('order-charge-card');
                handleSubmit();
              }}
              primary
              size="large"
              content={
                <>
                  {t('signup.orderChargeCard', 'Order Charge Card')}
                  <Icon name={'arrow-right'} style={{ marginLeft: '0.5em' }} />
                </>
              }
              fluid
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

function useVatPercentage() {
  const { provider } = useUser();
  return provider?.individualFees !== undefined
    ? (provider?.individualFees || 0) / 100
    : 0.21;
}
