import React, { useState } from 'react';
import { request } from 'utils/api';
import { Form, Modal, Header, Button, Message } from 'semantic';
import SearchDropDown from 'components/form-fields/SearchDropdown';
import { simpleOptions } from 'utils/form';
import { renderTokenSyncStatus } from 'utils/formatting';
import { currentUserIsSuperAdmin } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import ErrorMessage from 'components/ErrorMessage';
import AsyncModal from '../../helpers/async-modal';

function EditToken({ data, onSave, close }) {
  const [token, setToken] = useState({ ...data });
  const [loadMap, setLoadMap] = useState({});
  const [externalSync, setExternalSync] = useState(token?.externalSync);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  async function handleSync(externalSyncName, endpoint = 'default') {
    const key = externalSyncName + endpoint;
    setLoadMap({ ...loadMap, [key]: true });
    let docReloaded;
    try {
      const response = await request({
        method: 'POST',
        path: '/1/external-sync/request-sync',
        body: {
          id: token.id,
          modelName: 'token',
          externalSyncName,
          endpoint,
        },
      });
      docReloaded = response.data[0];
      setLoadMap({ ...loadMap, [key]: false });
      setExternalSync(docReloaded.externalSync);
    } catch (error) {
      setError(error);
    }
  }

  async function onSubmit() {
    setLoading(true);

    try {
      await request({
        method: 'PATCH',
        path: `/1/tokens/${token.id}`,
        body: {
          type: token.type,
          visualNumber: token.visualNumber,
          accountId: token.accountId,
          userId: token.userId,
          billingPlanId: token.billingPlanId,
          isActive: token.isActive,
          isBlocked: token.isBlocked,
          hasMaintenanceAccess: token.hasMaintenanceAccess,
        },
      });
      close();
      onSave();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  const setField = (name, value) => setToken({ ...token, [name]: value });
  const changeActiveState = (isActive) =>
    setToken({ ...token, ...{ isActive: isActive, isBlocked: !isActive } });

  const isVirtualToken = token.type === 'virtual';

  return (
    <>
      <Modal.Header>
        {t('editToken.titleEdit', 'Edit Token {{name}}', { name: token.uid })}
      </Modal.Header>
      <Modal.Content>
        <Form error={Boolean(error)} onSubmit={onSubmit}>
          <ErrorMessage error={error} />

          {isVirtualToken && (
            <Message color="blue">
              <p>
                {t(
                  'editToken.isVirtualToken',
                  'This is a virtual token. Therefore some fields cannot be edited.'
                )}
              </p>
            </Message>
          )}

          <Form.Select
            value={token.type}
            options={simpleOptions([
              'mifare',
              'calypso',
              'bar_code',
              'virtual',
            ])}
            name="type"
            label={t('editToken.type', 'Type')}
            required
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
          <Form.Input
            value={token.uid}
            name="uid"
            label={t('editToken.uid', 'UID')}
            type="text"
            disabled
          />
          <Form.Input
            value={token.customId}
            name="customId"
            label={t('editToken.customID', 'Custom ID')}
            type="text"
            disabled
          />
          <Form.Input
            value={token.visualNumber}
            name="visualNumber"
            label={t(
              'editToken.visualNumber',
              'Visual Number (overrides Custom ID)'
            )}
            type="text"
            disabled={!currentUserIsSuperAdmin() || isVirtualToken}
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Form.Field>
            <SearchDropDown
              value={token.billingPlanId}
              label={t('editToken.billingPlan', 'Billing Plan')}
              objectMode={false}
              getOptionLabel={(item) => item?.details?.en?.name}
              onDataNeeded={(body) =>
                request({
                  path: '/1/billing-plans/search',
                  method: 'POST',
                  body: body,
                })
              }
              disabled={isVirtualToken}
              onChange={(e, { value }) => setField('billingPlanId', value)}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('editToken.account', 'Account')}</label>
            <SearchDropDown
              value={token.accountId}
              objectMode={false}
              onDataNeeded={(body) =>
                request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body: body,
                })
              }
              onChange={(e, { value }) => setField('accountId', value)}
            />
          </Form.Field>

          <Form.Field>
            <label>{t('editToken.user', 'User')}</label>
            <SearchDropDown
              keywordField="searchPhrase"
              key={token.accountId}
              value={token.userId}
              getOptionLabel={(item) => `${item.name} (${item.email})`}
              objectMode={false}
              onDataNeeded={(body) =>
                request({
                  path: '/1/users/search/fast',
                  method: 'POST',
                  body: { ...body, accountId: token?.accountId },
                })
              }
              onChange={(e, { value }) => setField('userId', value)}
            />
          </Form.Field>

          <Form.Checkbox
            label={t('editToken.isActive', 'Is Active?')}
            name="isActive"
            checked={token.isActive || false}
            onChange={(e, { name, checked }) => changeActiveState(checked)}
          />
          <Form.Checkbox
            label={t('editToken.isBlocked', 'Is Blocked?')}
            name="isBlocked"
            checked={token.isBlocked || false}
            onChange={(e, { name, checked }) => changeActiveState(!checked)}
          />

          <Header as="h3">Sync Status</Header>
          {renderTokenSyncStatus(
            externalSync,
            (...args) => handleSync(...args),
            loadMap
          )}

          <Form.Checkbox
            label="Mark this token as a special maintenance token (allows charging of sessions before registration)"
            name="hasMaintenanceAccess"
            checked={token.hasMaintenanceAccess}
            onChange={(e, { name, checked }) => setField(name, checked)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={t('button.update')}
          onClick={onSubmit}
        />
      </Modal.Actions>
    </>
  );
}

export default AsyncModal(EditToken);
