import React, { useEffect, useState } from 'react';
import { Loader, Message, Statistic } from 'semantic';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';

export default function MspStats({ mspStatsApiRoute }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchStats(mspStatsApiRoute);
  }, [mspStatsApiRoute]);

  const fetchStats = async (mspStatsApiRoute) => {
    try {
      const { data } = await request({
        method: 'GET',
        path: mspStatsApiRoute,
      });
      setData(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader active inline="centered" />}
      {error && <Message error content={error.message} />}
      {data && (
        <React.Fragment>
          <Statistic.Group widths={5}>
            <Statistic value={data.active} label="active cards" />
            <Statistic value={data.pending} label="pending cards" />
            <Statistic value={data.sent} label="sent cards" />
            <Statistic value={data.disabled} label="disabled cards" />
            <Statistic value={data.total} label="total cards" />
          </Statistic.Group>
        </React.Fragment>
      )}
    </div>
  );
}
