import { useBillingInfoFormikValidationSchema } from 'components/BillingInfo/formik-validation';
import { useAccount } from 'contexts/account';
import { BillingInfo } from 'components/BillingInfo/types';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export type InviteEmployeeFormValues = {
  billing: BillingInfo;
  pricePerKwh?: number;
  requestUserGender: 'male' | 'female';
  requestUserId?: string;
  requestUserFirstName?: string;
  requestUserLastName?: string;
  requestUserEmail?: string;
};

type InviteEmployeeFormInitialState = {
  formData: InviteEmployeeFormValues;
  showBillingInfo: boolean;
};

export function useInviteEmployeeFormInitialState(): InviteEmployeeFormInitialState {
  const { account, isBillingUpdateRequired } = useAccount();

  const formData = useMemo<InviteEmployeeFormValues>(() => {
    return {
      requestUserGender: 'female',
      requestUserId: undefined,
      requestUserFirstName: undefined,
      requestUserLastName: undefined,
      requestUserEmail: undefined,
      pricePerKwh: undefined,
      billing: {
        accountHolderName: account?.billing?.accountHolderName || '',
        city: account?.billing?.city || '',
        countryCode: account?.billing?.countryCode?.toLowerCase() || '',
        ibanNumber: account?.billing?.ibanNo || '',
        bicNumber: account?.billing?.bicNo || '',
        number: account?.billing?.addressLine2 || '',
        paymentMethod: account?.billing?.paymentMethod || 'manual',
        postalCode: account?.billing?.postalCode || '',
        sepaAccepted: account?.billing?.sepaAccepted || false,
        street: account?.billing?.address || '',
        vatNumber: account?.vatNo || '',
        vatNotApplicable: account?.hasNoVatNo || false,
        chamberOfCommerceNumber: account?.chamberOfCommerceNo || '',
      },
    };
  }, [account]);

  return {
    formData,
    showBillingInfo: Boolean(isBillingUpdateRequired),
  };
}

export function useInviteEmployeeFormValidationSchema(
  showAccountBilling: boolean,
  enableBicCollection: boolean
) {
  const { account } = useAccount();
  const { t } = useTranslation();
  const billingInfoValidationSchema = useBillingInfoFormikValidationSchema(
    account?.type || 'individual',
    enableBicCollection
  );

  return Yup.object({
    billing: Yup.object(showAccountBilling ? billingInfoValidationSchema : {}),
    pricePerKwh: Yup.number().required(
      t('modals.inviteEmployee.pricePerKwh', 'Please enter a price per kWh!')
    ),
    requestUserId: Yup.string().required(
      t(
        'modals.inviteEmployee.requiredRequestUserId',
        'Please select an Employee!'
      )
    ),
    requestUserGender: Yup.string().when('requestUserId', {
      is: 'new',
      then: (schema) =>
        schema.required(
          t(
            'modals.inviteEmployee.requestUserGender',
            'Please select a gender!'
          )
        ),
    }),
    requestUserFirstName: Yup.string().when('requestUserId', {
      is: 'new',
      then: (schema) =>
        schema.required(
          t(
            'modals.inviteEmployee.requiredRequestUserFirstName',
            'Please enter a first name!'
          )
        ),
    }),
    requestUserLastName: Yup.string().when('requestUserId', {
      is: 'new',
      then: (schema) =>
        schema.required(
          t(
            'modals.inviteEmployee.requiredRequestUserLastName',
            'Please enter a last name!'
          )
        ),
    }),
    requestUserEmail: Yup.string()
      .email(
        t('modals.inviteEmployee.notValidRequestUserEmail', 'Invalid email!')
      )
      .when('requestUserId', {
        is: 'new',
        then: (schema) =>
          schema.required(
            t(
              'modals.inviteEmployee.requiredRequestUserEmail',
              'Please enter an email address!'
            )
          ),
      }),
  });
}
