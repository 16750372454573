import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import DataList from 'components/DataList';
import { request } from 'utils/api';
import Table from './Table';
import { Container, Header, Grid, Divider, Button } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import { userCanAccessProviderEndpoint } from 'utils/roles';
import EditTariffProfile from 'components/modals/EditTariffProfile/EditTariffProfile';
import { useUser } from 'contexts/user';

const itemLimit = 100;

const TariffProfiles = () => {
  const { provider, user } = useUser();

  const [dateKey, setDateKey] = useState(Date.now());

  const handleDeleteItem = (item) => {
    return request({
      method: 'DELETE',
      path: `/1/tariff-profiles/${item.id}`,
    })
      .then(() => refresh())
      .catch((error) => alert(`Error: ${error.message}`));
  };

  const handleRequest = () => {
    return request({
      method: 'POST',
      path: '/1/tariff-profiles/search',
      body: {
        limit: itemLimit,
      },
    });
  };

  const refresh = () => {
    setDateKey(Date.now());
  };

  const writeAccess = userCanAccessProviderEndpoint(
    user,
    provider,
    'tariffProfiles',
    'write'
  );

  return (
    <AppWrapper>
      <Container>
        <Breadcrumbs
          path={[<Link to="/charging-stations">Charging Stations</Link>]}
          active="Tariff Profiles"
        />
        <Grid reversed="computer">
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={8}
            textAlign="right"
            verticalAlign="middle">
            <EditTariffProfile
              currencies={provider.activeCurrencies}
              onDone={() => refresh()}
              trigger={
                <Button
                  disabled={!writeAccess}
                  primary
                  content="Create Tariff Profile"
                  icon="plus"
                />
              }
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Header as="h2" style={{ margin: '0' }}>
              Tariff Profiles
            </Header>
          </Grid.Column>
        </Grid>
        <Divider hidden />

        <p>
          Tariff Profiles can be used to manage tariffs across a wide population
          of EVSE devices and to specify special discount conditions.
        </p>

        <DataList dataKey={dateKey} limit={itemLimit} onRequest={handleRequest}>
          <Table
            currencies={provider.activeCurrencies}
            onDeleteItem={handleDeleteItem}
            onRefresh={() => refresh()}
          />
        </DataList>
        <Divider hidden />
      </Container>
    </AppWrapper>
  );
};

export default TariffProfiles;
