import React, { useRef } from 'react';
import { get } from 'lodash-es';
import EditToken from 'components/modals/EditToken';
import { Search, HelpTip, Confirm } from 'components';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import { Table, Divider, Header, Button } from 'semantic';
import SyncLogStatus from '../../../components/modals/SyncLogStatus';
import { currentUserIsSuperAdmin } from '../../../utils/roles';

const AccountTokens = ({ account }) => {
  const { t } = useTranslation();
  const searchRef = useRef(null);

  const onDelete = async (item) => {
    await request({
      method: 'DELETE',
      path: `/1/tokens/${item.id}`,
    });
    searchRef.current.reload();
  };

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: '/1/tokens/search/fast',
      body: {
        accountId: account.id,
        ...filters,
      },
    });
  };

  const fetchToken = async (id) => {
    const { data } = await request({
      method: 'GET',
      path: `/1/tokens/${id}`,
    });

    return data;
  };

  return (
    <>
      <Header as="h2">
        {t('accountTokens.title', 'Tokens')}
        <EditToken
          data={{
            accountId: account.id,
          }}
          onSave={() => searchRef.current.reload()}
          trigger={
            <Button
              primary
              floated="right"
              style={{ marginTop: '-5px' }}
              content="Add Token"
              icon="plus"
            />
          }
        />
      </Header>
      <Search.Provider ref={searchRef} onDataNeeded={onDataNeeded}>
        {({ items }) => {
          return (
            <>
              <Search.Status
                noItems={t('accountTokens.noTokensYet', 'No tokens added yet')}
              />
              {items.length !== 0 && (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={3}>
                        {t('accountTokens.identifier', 'Identifier')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('accountTokens.columnUser', 'User')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {t('accountTokens.columnBillingPlan', 'Billing Plan')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={1}>
                        {t('accountTokens.columnActive', 'Active?')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {t('accountTokens.columnCreated', 'Created')}
                        <HelpTip
                          title={t('accountTokens.columnCreated', 'Created')}
                          text={t(
                            'accountTokens.columnCreatedToolTip',
                            'This is the date and time the token was created.'
                          )}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        {t('accountTokens.columnActions', 'Actions')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {items.map((item) => (
                      <Table.Row key={item.id}>
                        <Table.Cell>
                          {item.visualNumber || item.customId}
                        </Table.Cell>
                        <Table.Cell>{item.user?.name}</Table.Cell>
                        <Table.Cell>
                          {get(item, 'billingPlan.name', 'No BillingPlan')}
                        </Table.Cell>
                        <Table.Cell>{item.isActive ? 'Yes' : 'No'}</Table.Cell>
                        <Table.Cell>
                          {formatDateTime(item.createdAt)}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <EditToken
                            getData={() => fetchToken(item.id)}
                            onSave={() => searchRef.current.reload()}
                            trigger={
                              <Button basic icon="pen-to-square" title="Edit" />
                            }
                          />
                          {currentUserIsSuperAdmin() && (
                            <SyncLogStatus
                              getData={() => fetchToken(item.id)}
                              modelName="token"
                              size="fullscreen"
                              trigger={
                                <Button
                                  basic
                                  icon="wand-magic-sparkles"
                                  title="Sync status"
                                />
                              }
                            />
                          )}
                          <Confirm
                            header={t(
                              'deleteDialog.header',
                              'Are you sure you want to delete {{name}}?',
                              { name: item.name }
                            )}
                            content={t(
                              'deleteDialog.content',
                              'All data will be permanently deleted'
                            )}
                            trigger={
                              <Button basic icon="trash" title="Delete" />
                            }
                            onConfirm={() => onDelete(item)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
              <Divider hidden />
              <div
                style={{
                  textAlign: 'center',
                }}>
                <Search.Pagination />
              </div>
            </>
          );
        }}
      </Search.Provider>
    </>
  );
};

export default AccountTokens;
