import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button, Label, Dropdown } from 'semantic';
import EditRole from 'components/modals/EditRole';
import { currentUserCanAccess } from 'utils/roles';
import { Search } from 'components';
import Actions from '../Actions';
import { formatDateTime } from 'utils/date';

const RolesTable = () => {
  const { t } = useTranslation();
  const { items, loading, reload } = useContext(Search.Context);
  const writeAccess = currentUserCanAccess('roles', 'write');
  const contexts = {
    global: { label: t('roles.labelGlobal', 'Global'), color: 'orange' },
    provider: { label: t('roles.labelProvider', 'Provider'), color: 'teal' },
    account: { label: t('roles.labelAccount', 'Account'), color: 'grey' },
    system: { label: t('roles.labelSystem', 'System'), color: 'yellow' },
  };

  if (!items.length || loading) return null;

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>
              {t('roles.columnName', 'Name')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('roles.columnContext', 'Context')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('roles.columnProvider', 'Provider')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('roles.columnCreated', 'Created')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('roles.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            const contextLabel = contexts[item.context] || contexts['account'];
            return (
              <Table.Row key={item.id}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>
                  <Label
                    color={contextLabel.color}
                    content={contextLabel.label}
                  />
                </Table.Cell>
                <Table.Cell>{item.provider?.name}</Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                <Table.Cell textAlign="center">
                  <EditRole
                    role={item}
                    onSave={reload}
                    trigger={
                      <Button
                        style={{ marginLeft: '20px' }}
                        basic
                        icon="pen-to-square"
                        title="Edit"
                        disabled={!writeAccess}
                      />
                    }
                  />

                  <Dropdown button basic text="More">
                    <Dropdown.Menu direction="left">
                      <Actions
                        item={item}
                        onReload={reload}
                        writeAccess={writeAccess}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default RolesTable;
