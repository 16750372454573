import { getToken, request } from 'utils/api';
import { parseToken } from 'utils/token';
import React from 'react';
import {
  Button,
  Header,
  Modal,
  ModalActions,
  ModalContent,
  ModalDescription,
  ModalHeader,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export const SsoLoginModal = () => {
  const { t } = useTranslation();

  return (
    <Modal open={true} trigger={<div />}>
      <ModalHeader>
        {t('authentication.token_expired.title', 'Session expired')}
      </ModalHeader>
      <ModalContent>
        <ModalDescription>
          <Header>
            {t(
              'authentication.token_expired.header',
              'For security reasons your token has expired.'
            )}
          </Header>
          <p>
            {t(
              'authentication.token_expired.message',
              'Please login again to continue.'
            )}
          </p>
        </ModalDescription>
      </ModalContent>
      <ModalActions>
        <Button
          onClick={async () => {
            const redirectUrl = new URL(window.location.href);

            const { data } = await request({
              method: 'POST',
              path: '/1/auth/login/sso',
              body: {
                userId: parseToken(getToken()).userId,
                redirectUrl: redirectUrl.pathname,
              },
            });

            if (data.ssoEnabled && data.redirectRequired) {
              window.location = data.redirectUrl;
              return;
            }
          }}>
          {t('authentication.token_expired.button_text', 'Login with SSO')}
        </Button>
      </ModalActions>
    </Modal>
  );
};
