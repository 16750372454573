import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { formatCardStatus } from 'utils/formatting';
import { useTranslation } from 'react-i18next';
import { Container, Table, Dropdown, Divider } from 'semantic';
import { Search, HelpTip } from 'components';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import Actions from 'screens/Cards/Actions';

const AccountCards = (props) => {
  const { t } = useTranslation();

  const onDataNeeded = (body) => {
    return request({
      method: 'POST',
      path: '/1/cards/search/fast',
      body: {
        accountId: props.account.id,
        ...body,
      },
    });
  };
  const searchRef = useRef(null);

  return (
    <Container>
      <Search.Provider ref={searchRef} onDataNeeded={onDataNeeded}>
        {({ items, reload }) => {
          return (
            <>
              <Search.Status noResults={t('accountCards.messageNoCards')} />

              {items.length !== 0 && (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1}>
                        {t('accountCards.columnPlan', 'Billing Plan')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={1}>
                        {t('accountCards.columnStatus', 'Status')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {t('accountCards.columnUser', 'User')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {t('accountCards.columnToken', 'Token')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        {t('accountCards.columnIdentifier', 'Identifier')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('accountCards.columnCreated', 'Created')}

                        <HelpTip
                          title={t('accountCards.columnCreated', 'Created')}
                          text={t(
                            'accountCards.columnCreatedHelp',
                            'This is the date and time the card was created.'
                          )}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('accountCards.columnAction', 'Actions')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {items.map((item) => {
                      return (
                        <Table.Row key={item.id}>
                          <Table.Cell>{item.billingPlan?.name}</Table.Cell>
                          <Table.Cell textAlign="center">
                            {formatCardStatus(item.status, t)}
                          </Table.Cell>
                          <Table.Cell>
                            {item.user ? (
                              <Link to={`/accounts/users/${item.userId}`}>
                                {item.user && item.user.name}
                              </Link>
                            ) : (
                              <span>{t('accountCards.noUser', 'No User')}</span>
                            )}
                          </Table.Cell>
                          <Table.Cell>{item.token?.uid}</Table.Cell>
                          <Table.Cell>
                            {item.token?.visualNumber || item.token?.customId}
                          </Table.Cell>
                          <Table.Cell>
                            {formatDateTime(item.createdAt)}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <Dropdown button basic text="More">
                              <Dropdown.Menu direction="left">
                                <Actions item={item} onReload={reload} />
                              </Dropdown.Menu>
                            </Dropdown>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}

              <Divider hidden />
              <div
                style={{
                  textAlign: 'center',
                }}>
                <Search.Pagination />
              </div>
            </>
          );
        }}
      </Search.Provider>
    </Container>
  );
};

export default AccountCards;
