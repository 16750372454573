import { Link } from 'react-router-dom';
import React, { useMemo } from 'react';
import { Header, Icon, Label, Message, Popup, Table } from 'semantic';
import * as PropTypes from 'prop-types';
import {
  formatCostOverride,
  formatIntegrityClassification,
  formatIntegrityDescriptions,
  formatPowerType,
  formatSessionExcludedReason,
  formatSessionProvider,
  formatSessionStatus,
  formatSyncStatusCell,
  round,
  roundUpTwoDigits,
  translateExcludedReason,
} from 'utils/formatting';
import { isSuperAdmin } from 'utils/roles';
import HelpTip from './HelpTip';
import SyncLogStatus from './modals/SyncLogStatus';
import { useTranslation } from 'react-i18next';
import { formatDateTime, formatDuration } from 'utils/date';
import Session from './Session';
import Currency from './Currency';
import { ChargingSession, CpoSession } from 'types/charging-session';
import { UserContextType } from 'contexts/user';
import { PaymentStatus } from 'components/cpo-payment/paymentStatus';
import { PaymentMethod } from './cpo-payment/paymentMethod';
import ExternalLink from './Link/ExternalLink';

type AnalysisDescriptions = {
  [key: string]: string | undefined;
};

const ANALYSIS_DESCRIPTIONS: AnalysisDescriptions = {
  kwh: 'The kWh is within expected bounds',
  kwhDuration: 'The kWh for the charge duration is plausable',
  evseConnectivityMode:
    'The charge station connection was private and encrypted',
  evseAuthorization: 'The charge station used OCPP authentication',
  locationPrivate: 'The charge station location was private',
  fraudPassed: 'The fraud detectors passed',
};

type FormatterParam = {
  eligable?: boolean;
  travelSpeedKph?: number;
  passed?: boolean;
};

type FormatterReturnType = FormatterParam & {
  label: string;
  description: string;
};

type FraudAnalysisFormatters = {
  [key: string]: (
    result?: FormatterParam | undefined
  ) => FormatterReturnType | undefined;
};

const FRAUD_ANALYSIS_FORMATTERS: FraudAnalysisFormatters = {
  // TODO: define the shape of result
  geoChronological: (result) => {
    let description = 'No signs of fraud detected';

    if (result?.eligable) {
      const distanceText = `Calculated speed from previous charge event was ${
        (result.travelSpeedKph ?? 0) < 2
          ? 'less than two'
          : result.travelSpeedKph
      } kilometers per hour`;
      if (result.passed) {
        description = `No unprobable travel speed detected. ${distanceText}`;
      } else {
        description = `Unprobable travel speed detected. ${distanceText}`;
      }
    }

    return {
      ...result,
      label: 'Geo Chronological Analysis',
      description,
    };
  },
};

SessionTable.propTypes = {
  session: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

type SessionTableProps = {
  session: ChargingSession;
  user: UserContextType;
  includeIntegrityInfo?: boolean;
};

export default function SessionTable({
  session,
  user,
  includeIntegrityInfo = false,
}: SessionTableProps) {
  const { t } = useTranslation();
  const isMsp = session.providerContext === 'msp';

  return (
    <div>
      <Header as="h3">Basic Details</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}>ID</Table.Cell>
            <Table.Cell>{session.id}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>External ID</Table.Cell>
            <Table.Cell>
              {session.externalId} ({session.externalUniqueId})
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Provider Context</Table.Cell>
            <Table.Cell>
              <Label content={session.providerContext?.toUpperCase()} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h3">Usage</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}>kWh</Table.Cell>
            <Table.Cell>
              {session.status === 'ACTIVE' ? '-' : session.kwh}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Duration</Table.Cell>
            <Table.Cell>{formatDuration(session.durationSeconds)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Started At</Table.Cell>
            <Table.Cell>{formatDateTime(session.startedAt)}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Ended At</Table.Cell>
            <Table.Cell>
              {session.endedAt ? formatDateTime(session.endedAt) : '-'}
              &nbsp;&nbsp;
              {!isMsp && <IdlePeriodInfo session={session} />}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Power Type</Table.Cell>
            <Table.Cell>{formatPowerType(session.powerType)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h3">Costs &amp; Billing</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}>Billing Status</Table.Cell>
            <Table.Cell>
              {session.excluded ? (
                <>
                  <Label
                    content="Excluded"
                    title={translateExcludedReason(session.excludedReason, t)}
                    color="orange"
                  />
                  {session.excludedReason &&
                    formatSessionExcludedReason(session.excludedReason, t)}
                </>
              ) : (
                <Label content="Included" />
              )}
            </Table.Cell>
          </Table.Row>
          {/** I couldn't find startCosts in Mongo, so I didn't add it to MSP type */}
          {!isMsp &&
            typeof session.startCosts === 'number' &&
            session.startCosts >= 0 && (
              <Table.Row>
                <Table.Cell>Start Cost</Table.Cell>
                <Table.Cell>
                  <Currency
                    value={roundUpTwoDigits(session.startCosts)}
                    currency={session.currency}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          {/** I couldn't find timeCosts in Mongo, so I didn't add it to MSP type */}
          {!isMsp &&
            typeof session.timeCosts === 'number' &&
            session.timeCosts >= 0 && (
              <Table.Row>
                <Table.Cell width={4}>Time Cost</Table.Cell>
                <Table.Cell>
                  <Currency
                    value={roundUpTwoDigits(session.timeCosts)}
                    currency={session.currency}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          {/** I couldn't find timeCosts in Mongo, so I didn't add it to MSP type */}
          {!isMsp &&
            typeof session.idleCosts === 'number' &&
            session.idleCosts >= 0 && (
              <Table.Row>
                <Table.Cell>Idle Cost</Table.Cell>
                <Table.Cell>
                  <Currency
                    value={roundUpTwoDigits(session.idleCosts)}
                    currency={session.currency}
                  />
                </Table.Cell>
              </Table.Row>
            )}

          {!isMsp &&
            typeof session.energyCosts === 'number' &&
            session.energyCosts >= 0 && (
              <Table.Row>
                <Table.Cell>Energy Cost</Table.Cell>
                <Table.Cell>
                  <Currency
                    value={roundUpTwoDigits(session.energyCosts)}
                    currency={session.currency}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          {!isMsp &&
            typeof session.energyCosts === 'number' &&
            session.energyCosts >= 0 && (
              <Table.Row>
                <Table.Cell>Total Cost</Table.Cell>
                <Table.Cell>
                  <Currency
                    value={roundUpTwoDigits(
                      (session.timeCosts ?? 0) +
                        (session.startCosts ?? 0) +
                        session.energyCosts +
                        (session.idleCosts || 0)
                    )}
                    currency={session.currency}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          {isSuperAdmin(user) && (
            <Table.Row>
              <Table.Cell width={4}>External Calculated Price</Table.Cell>
              <Table.Cell>
                <Session.Price session={session} />
              </Table.Cell>
            </Table.Row>
          )}
          {isMsp && session.calculatedDiscount && (
            <Table.Row>
              <Table.Cell width={4}>Calculated Discount</Table.Cell>
              <Table.Cell>
                <Currency
                  value={roundUpTwoDigits(session.calculatedDiscount)}
                  currency={session.currency}
                />
              </Table.Cell>
            </Table.Row>
          )}
          <SessionCostSettings session={session} />
          {isMsp && session.vatInfo && (
            <Table.Row>
              <Table.Cell>VAT Info</Table.Cell>
              <Table.Cell style={{ padding: 0 }}>
                <Table definition style={{ borderRadius: '0', border: 'none' }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>VAT Rule</Table.Cell>
                      <Table.Cell>
                        <Label
                          content={
                            session.vatInfo.matchedRuleId ||
                            session.vatInfo.default?.matchedRuleId ||
                            'Unknown'
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>VAT Rate</Table.Cell>
                      <Table.Cell>
                        {(
                          (session.vatInfo.vatPercentage ||
                            session.vatInfo.default?.vatPercentage ||
                            0) / 100
                        ).toLocaleString('nl')}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      {!isMsp && (
        <>
          <Header as="h3">Payments Transaction Information</Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Payment Id</Table.Cell>
                <Table.Cell>
                  {session.authorization?.payment?.pspPaymentLink && (
                    <ExternalLink
                      href={session.authorization?.payment?.pspPaymentLink}>
                      {session.authorization?.payment?.paymentId}
                    </ExternalLink>
                  )}
                  {!session.authorization?.payment?.pspPaymentLink &&
                    session.authorization?.payment?.paymentId}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Payment Method</Table.Cell>
                <Table.Cell>
                  <PaymentMethod
                    source={session.authorization?.payment?.productPaymentFlow}
                    t={t}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Payment Status</Table.Cell>
                <Table.Cell>
                  <PaymentStatus
                    status={session.authorization?.payment?.status}
                    t={t}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}

      <Header as="h3">User &amp; Authentication</Header>
      <Table definition>
        <Table.Body>
          {isMsp && (
            <>
              <Table.Row>
                <Table.Cell>Provider</Table.Cell>
                <Table.Cell>
                  {session.externalProvider ? (
                    <span>
                      {session.externalProvider.name}{' '}
                      <Label
                        content={session.infraProviderId}
                        style={{ float: 'right' }}
                      />
                    </span>
                  ) : (
                    <Label content={session.infraProviderId} />
                  )}
                </Table.Cell>
              </Table.Row>
              {session.user && (
                <Table.Row>
                  <Table.Cell width={4}>User</Table.Cell>
                  <Table.Cell>{`${session.user.contact?.firstName} ${session.user.contact?.lastName}`}</Table.Cell>
                </Table.Row>
              )}
            </>
          )}
          {!isMsp && (
            <>
              {session.rawRecord && (
                <Table.Row>
                  <Table.Cell width={4}>Token Type</Table.Cell>
                  <Table.Cell>{session.rawRecord.tokenType}</Table.Cell>
                </Table.Row>
              )}
              {session.rawRecord && session.rawRecord.tokenVisualNumber && (
                <Table.Row>
                  <Table.Cell width={4}>Token Visual Number</Table.Cell>
                  <Table.Cell>{session.rawRecord.tokenVisualNumber}</Table.Cell>
                </Table.Row>
              )}
              {session.tokenUid && (
                <Table.Row>
                  <Table.Cell width={4}>Token UID</Table.Cell>
                  <Table.Cell>{session.tokenUid}</Table.Cell>
                </Table.Row>
              )}
              {session.rawRecord && (
                <Table.Row>
                  <Table.Cell width={4}>Provider</Table.Cell>
                  <Table.Cell>{formatSessionProvider(session)}</Table.Cell>
                </Table.Row>
              )}
              <Table.Row>
                <Table.Cell width={4}>Location</Table.Cell>
                <Table.Cell>
                  {session.location ? (
                    <>
                      <Link
                        to={`/charging-stations/locations/${session.location.id}`}>
                        {session.location.name}
                      </Link>{' '}
                      {session.evseController && (
                        <p>
                          <small>
                            (
                            {session.evseController.id ? (
                              <span>
                                <Link
                                  to={`/charging-stations/${session.evseController.id}`}>
                                  {session.evseController.ocppIdentity}
                                </Link>{' '}
                                #{session.connectorId}
                              </span>
                            ) : (
                              `${session.evseController.ocppIdentity} #${session.connectorId}`
                            )}
                            )
                          </small>
                        </p>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>EVSE ID</Table.Cell>
                <Table.Cell>
                  {session.evseId || session.rawRecord?.evseId || '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Connector ID</Table.Cell>
                <Table.Cell>{session.connectorId || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Status</Table.Cell>
                <Table.Cell>{formatSessionStatus(session.status)}</Table.Cell>
              </Table.Row>
              {session.rawRecord && (
                <>
                  <Table.Row>
                    <Table.Cell width={4}>
                      {t('sessionTable.columnSyncOCPI', 'Sync OCPI')}
                    </Table.Cell>
                    <Table.Cell>
                      {formatSyncStatusCell(
                        session,
                        'session',
                        'push-ocpi-session',
                        SyncLogStatus,
                        {
                          showExternalRefs: true,
                          externalRefFields: [
                            'credentialName',
                            'credentialIds',
                          ],
                        }
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>
                      {t('sessionTable.columnSyncEclearing', 'Sync Eclearing')}
                    </Table.Cell>
                    <Table.Cell>
                      {formatSyncStatusCell(
                        session,
                        'session',
                        'push-eclearing-cpo-session',
                        SyncLogStatus
                      )}
                    </Table.Cell>
                  </Table.Row>
                </>
              )}
            </>
          )}
        </Table.Body>
      </Table>

      {includeIntegrityInfo && (
        <React.Fragment>
          <Header as="h3">Automatic Integrity Analysis</Header>
          {session.providerContext !== 'cpo' ||
            (!session.integrityValidation && (
              <Message content="No integrity analysis has been performed on this session yet" />
            ))}
          {!isMsp && session.integrityValidation && (
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={4}>Integrity Classification</Table.Cell>
                  <Table.Cell>
                    {formatIntegrityClassification(
                      session.integrityValidation.classification
                    )}
                    <HelpTip
                      {...formatIntegrityDescriptions(
                        session.integrityValidation.classification
                      )}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4} verticalAlign="top">
                    Integrity Analysis Results
                  </Table.Cell>
                  <Table.Cell>
                    <Table collapsing size="small">
                      <Table.Body>
                        {Object.keys(
                          session.integrityValidation.validations ?? {}
                        ).map((key) => {
                          const passed =
                            session.integrityValidation?.validations &&
                            session.integrityValidation.validations[key];
                          return (
                            <Table.Row key={key}>
                              <Table.Cell size={3}>
                                {ANALYSIS_DESCRIPTIONS[key] || key}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {passed ? (
                                  <Icon name="circle-check" color="olive" />
                                ) : (
                                  <Icon name="circle-minus" color="red" />
                                )}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                </Table.Row>
                {session.fraudDetection && (
                  <Table.Row>
                    <Table.Cell width={4} verticalAlign="top">
                      Fraud Detector Analysis Details
                    </Table.Cell>
                    <Table.Cell>
                      <Table collapsing size="small">
                        <Table.Body>
                          {Object.keys(
                            session.fraudDetection.analysisResults ?? {}
                          )
                            .filter((key) => FRAUD_ANALYSIS_FORMATTERS[key])
                            .map((key) => {
                              const formatter = FRAUD_ANALYSIS_FORMATTERS[key];
                              if (!formatter) {
                                // Should never trigger because of the filter above
                                return <></>;
                              }

                              const result =
                                session.fraudDetection?.analysisResults;
                              if (!result) {
                                return <></>;
                              }

                              // Analysis Results are defined, but can't be indexed by key (TS), so I'm using this as a workaround
                              const item = (result as any)[key];
                              const formatted = formatter(item);

                              const { label, passed, description } =
                                formatted || {};
                              return (
                                <Table.Row key={key} celled>
                                  <Table.Cell size={3}>{label}</Table.Cell>
                                  <Table.Cell>{description}</Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    style={{
                                      borderLeft:
                                        '1px solid rgba(34, 36, 38, 0.15)',
                                    }}>
                                    {passed ? (
                                      <Icon name="circle-check" color="olive" />
                                    ) : (
                                      <Icon name="circle-minus" color="red" />
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export function IdlePeriodInfo({ session }: { session: CpoSession }) {
  const { t } = useTranslation();
  return useMemo(() => {
    if (!session.eligibleIdleMinutes) {
      return <></>;
    }
    const duration = formatDuration(session.eligibleIdleMinutes * 60);
    return (
      <Popup
        trigger={<Icon name="circle-info" />}
        content={t(
          'sessionTable.idleTimeInfo',
          `Exceeded idle wait time by {{duration}}`,
          { duration }
        )}
      />
    );
  }, [session.eligibleIdleMinutes]);
}

export function SessionCostSettings({ session }: { session: ChargingSession }) {
  if (!session.rawRecord?.costSetting) {
    return <></>;
  }
  let pricePerKwh = session.rawRecord.costSetting?.pricePerKwh;
  if (
    !isNaN(pricePerKwh) &&
    !isNaN(session.rawRecord.costSetting?.surchargePerKwh)
  ) {
    pricePerKwh =
      Number(pricePerKwh) +
      Number(session.rawRecord.costSetting.surchargePerKwh);
  }
  if (!isNaN(session.rawRecord.advancedCosts?.pricePerKwh)) {
    pricePerKwh = session.rawRecord.advancedCosts.pricePerKwh;
  }
  return (
    <>
      <Table.Row>
        <Table.Cell width={4}>Cost Setting</Table.Cell>
        <Table.Cell>
          <Currency value={round(pricePerKwh, 2)} currency={session.currency} />{' '}
          per kWh
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell width={4}>Cost Override</Table.Cell>
        <Table.Cell>
          {formatCostOverride(session.rawRecord.costSetting)}
        </Table.Cell>
      </Table.Row>
    </>
  );
}
