import { EvseController } from 'components/modals/EditEvseController/types';
import { useState } from 'react';

export default function useFormState(evseController: EvseController) {
  const [formValues, setFormValues] = useState<Partial<EvseController>>({
    ...evseController,
  });

  // TODO move to reusable hook
  const setNestedField =
    (level = '') =>
    (name, value, callBack = () => undefined) => {
      if (!level) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [name]: value,
        }));
      } else {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [level]: {
            ...prevFormValues[level],
            [name]: value,
          },
        }));
      }
      callBack();
    };

  const setField = setNestedField();

  return {
    isUpdate: !!evseController.id,
    formValues,
    setField,
    setNestedField,
  };
}
