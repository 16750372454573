import React from 'react';
import { Grid, Form, Table, Label, Button } from 'semantic';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import SearchDropdown from 'components/SearchDropdown';
import { currentUserIsSuperAdmin } from 'utils/roles';
import { capitalize } from 'utils/formatting';

const percentageSum = (list = []) =>
  list.reduce((acc, { percentage }) => acc + parseInt(percentage), 0);

const EntitySearchDropdown = ({ value, name, accessPolicy, onChange }) => {
  const identityKey = value?.type === 'user' ? 'userId' : 'accountId';

  if (value?.type && value[identityKey]?._id !== undefined) {
    onChange(null, {
      name,
      value: {
        type: value.type,
        id: value[identityKey]._id,
        [identityKey]: value[identityKey]._id,
        name: value[identityKey].name,
      },
    });
  }

  return (
    <SearchDropdown
      value={value?.id}
      name={name}
      style={{ width: '100%' }}
      options={
        value?.id
          ? [
              {
                ...value,
                name: `${capitalize(value.type)}: ${value.name}`,
              },
            ]
          : []
      }
      getOptionValue={(value) => value}
      valueGetter={({ options, value: id }) => {
        return options.find((option) => {
          if (option.type === 'user') {
            return option.id === id && option.type === 'user';
          }
          if (option.type === 'account') {
            return option.id === id && option.type === 'account';
          }
        });
      }}
      onDataNeeded={async (body) => {
        const { data } = await request({
          path: `/2/locations/cpo/billing-policy-members/search`,
          method: 'POST',
          body: {
            accessPolicy,
            name: body?.name,
          },
        });
        return {
          data: data.map((item) => ({
            ...item,
            name: `${capitalize(item.type)}: ${item.name}`,
          })),
        };
      }}
      onChange={(e, { name, value, valueObject }) => {
        const entity = value || valueObject;
        onChange(e, {
          name,
          value: {
            type: entity?.type,
            [`${entity?.type}Id`]: entity?.id,
          },
        });
      }}
    />
  );
};

const AccessPolicyConfiguration = ({
  onChange,
  configurablePercentage,
  accessPolicy,
  value = { usageReimbursement: [], subscriptionBilling: [] },
}) => {
  const { t } = useTranslation();

  const removeItem = (i, source) => {
    onChange('billingPolicy', {
      ...value,
      [source]: [
        ...value[source].slice(0, i),
        ...value[source].slice(i + 1, value[source].length),
      ],
    });
  };

  const updateIndexValue = (i, source, name, fieldValue) => {
    onChange('billingPolicy', {
      ...value,
      [source]: [
        ...value[source].slice(0, i),
        {
          ...value[source][i],
          [name]: fieldValue,
        },
        ...value[source].slice(i + 1, value[source].length),
      ],
    });
  };

  return (
    <Grid style={{ padding: '1rem' }} celled>
      <Grid.Row style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
        <Label>Usage reimbursement</Label>
      </Grid.Row>
      <Grid.Row>
        <Table>
          <Table.Row>
            <Table.Cell>
              {t(
                'accessPolicyConfiguration.reimbursePercentageTitle',
                '% to reimburse'
              )}
            </Table.Cell>
            <Table.Cell>
              {t(
                'accessPolicyConfiguration.reimbursePercentageEntity',
                'User or Account to reimburse'
              )}
            </Table.Cell>
            <Table.Cell>
              {t('accessPolicyConfiguration.action', 'Action')}
            </Table.Cell>
          </Table.Row>
          {value?.usageReimbursement?.map(({ percentage, entity }, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                <Form.Input
                  required
                  disabled={!configurablePercentage}
                  type="number"
                  name="percentage"
                  onChange={(e, { name, value }) =>
                    updateIndexValue(i, 'usageReimbursement', name, value)
                  }
                  value={configurablePercentage ? percentage : 100}
                />
              </Table.Cell>
              <Table.Cell>
                <EntitySearchDropdown
                  value={entity}
                  name="entity"
                  accessPolicy={accessPolicy}
                  onChange={(e, { name, value }) =>
                    updateIndexValue(i, 'usageReimbursement', name, value)
                  }
                />
              </Table.Cell>
              <Table.Cell>
                <Button
                  basic
                  icon="trash"
                  title="Delete"
                  onClick={() => removeItem(i, 'usageReimbursement')}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
        <div style={{ marginBottom: '1rem', flex: 1, display: 'flex' }}>
          <Button
            icon="plus"
            disabled={percentageSum(value?.usageReimbursement) >= 100}
            label={t(
              'accessPolicyConfiguration.addReimbursement',
              'Add user or account to reimburse'
            )}
            onClick={() =>
              onChange('billingPolicy', {
                ...value,
                usageReimbursement: [
                  ...(value?.usageReimbursement || []),
                  {
                    percentage: configurablePercentage ? 0 : 100,
                    type: 'credit',
                    entity: undefined,
                  },
                ],
              })
            }
          />
          {percentageSum(value?.usageReimbursement) !== 100 && (
            <p
              style={{
                textAlign: 'right',
                flex: 1,
                color: 'red',
              }}>
              The sum of the percentage reimbursed must equal 100%, but was{' '}
              {percentageSum(value?.usageReimbursement)}%
            </p>
          )}
        </div>
      </Grid.Row>
      {currentUserIsSuperAdmin() && (
        <>
          <Grid.Row style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <Label>Subscription billing</Label>
          </Grid.Row>
          <Grid.Row>
            <Table>
              <Table.Row>
                <Table.Cell>
                  {t(
                    'accessPolicyConfiguration.subscriptionPercentageTitle',
                    '% to charge'
                  )}
                </Table.Cell>
                <Table.Cell>
                  {t(
                    'accessPolicyConfiguration.subscriptionPercentageEntity',
                    'User or Account to charge'
                  )}
                </Table.Cell>
                <Table.Cell>Action</Table.Cell>
              </Table.Row>
              {value?.subscriptionBilling?.map(({ percentage, entity }, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    <Form.Input
                      required
                      disabled={!configurablePercentage}
                      type="number"
                      name="percentage"
                      onChange={(e, { name, value }) =>
                        updateIndexValue(i, 'subscriptionBilling', name, value)
                      }
                      value={configurablePercentage ? percentage : 100}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <EntitySearchDropdown
                      name="entity"
                      accessPolicy={accessPolicy}
                      value={entity}
                      onChange={(e, { name, value }) =>
                        updateIndexValue(i, 'subscriptionBilling', name, value)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      basic
                      icon="trash"
                      title="Delete"
                      onClick={() => removeItem(i, 'subscriptionBilling')}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table>
            <div style={{ marginBottom: '1rem', flex: 1, display: 'flex' }}>
              <Button
                icon="plus"
                disabled={percentageSum(value?.subscriptionBilling) >= 100}
                label={t(
                  'accessPolicyConfiguration.addSubscription',
                  'Add user or account to charge'
                )}
                onClick={() =>
                  onChange('billingPolicy', {
                    ...value,
                    subscriptionBilling: [
                      ...(value?.subscriptionBilling || []),
                      {
                        percentage: configurablePercentage ? 0 : 100,
                        entity: undefined,
                      },
                    ],
                  })
                }
              />
              {percentageSum(value?.subscriptionBilling) !== 100 && (
                <p
                  style={{
                    textAlign: 'right',
                    flex: 1,
                    color: 'red',
                  }}>
                  The sum of the percentage to charge must equal 100%, but was{' '}
                  {percentageSum(value?.subscriptionBilling)}%
                </p>
              )}
            </div>
          </Grid.Row>
        </>
      )}
    </Grid>
  );
};

export default AccessPolicyConfiguration;
