import { BillingInfoFormikForm } from 'components/BillingInfo/formik-form';
import { useAccount } from 'contexts/account';
import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Checkbox, Divider, Form, Grid, Header } from 'semantic';
import {
  NewCardHolderInfo,
  SelectCardHolder,
} from 'components/modals/RequestCard/v2/CardHolderAndBilling/CardHolderInfo';
import { SelectCardHolderType } from 'components/modals/RequestCard/v2/CardHolderAndBilling/SelectCardHolderType';
import { RequestCardFormValues } from 'components/modals/RequestCard/v2/formData';
import { StyledSegment } from 'components/modals/RequestCard/v2/CardHolderAndBilling/StyledSegment';
import {
  DeliveryAddressFormPart,
  DeliveryAddressSameAsBilling,
} from 'components/modals/RequestCard/v2/CardHolderAndBilling/DeliveryAddress';
import { useFeatures } from 'contexts/features';
import styles from './styles.module.less';

type CardHolderAndBillingProps = {
  value: RequestCardFormValues;
  accountType: 'individual' | 'organization';
  sepaUrl: string;
  showErrorLabels?: boolean;
  showBilling?: boolean;
};

export default function CardHolderAndBilling(props: CardHolderAndBillingProps) {
  const { t } = useTranslation();
  const { account } = useAccount();
  const { values, setValues } = useFormikContext<RequestCardFormValues>();

  const { hasFeature, isReady: isFeatureReady } = useFeatures();
  const isBicCollectionEnabled = hasFeature('billing_bic_collection');

  return (
    <>
      <Header textAlign="center" as="h3">
        {t('addCardModal.selectCardHolderHeader', 'Select Card Holder')}
      </Header>
      <SelectCardHolderType
        selected={values.cardHolder.type}
        onSelect={(nextCardHolderType) =>
          setValues({
            ...values,
            cardHolder: {
              ...values.cardHolder,
              type: nextCardHolderType,
            },
          })
        }
      />
      <StyledSegment>
        <Grid padded="horizontally">
          {values.cardHolder.type === 'existing' && (
            <Grid.Row>
              <Grid.Column width={8}>
                <Form>
                  <Form.Field required>
                    <label>
                      {t(
                        'requestCardModel.existingCardHolderHeader',
                        'Card Holder'
                      )}
                    </label>
                    <SelectCardHolder
                      value={values.cardHolder.userId}
                      onChange={(nextUserId, nextUser) =>
                        setValues({
                          ...values,
                          cardHolder: {
                            ...values.cardHolder,
                            userId: nextUserId,
                            user: nextUser,
                          },
                        })
                      }
                      showErrorLabels={props.showErrorLabels}
                    />
                  </Form.Field>
                </Form>
              </Grid.Column>
            </Grid.Row>
          )}
          {values.cardHolder.type === 'new' && (
            <Grid.Row>
              <Grid.Column className={styles.responsiveWidth}>
                <Form>
                  <Header as="h3" style={{ paddingLeft: 0, fontWeight: 600 }}>
                    {t('requestCardModal.addNewUserHeader', 'New User')}
                  </Header>
                  <NewCardHolderInfo showErrorLabels={props.showErrorLabels} />
                </Form>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </StyledSegment>
      {props.showBilling && isFeatureReady && (
        <>
          <Header textAlign="center" as="h3">
            {t('addCardModal.billingDetailsHeader', 'Billing Details')}
          </Header>
          <StyledSegment>
            <Form>
              <BillingInfoFormikForm
                values={props.value.billing}
                accountType={account?.type || 'individual'}
                enableBicCollection={isBicCollectionEnabled}
                enableCountryCodeInput={!account?.billing?.countryCode}
                objectPath="billing"
                showErrorLabels={props.showErrorLabels}
              />
            </Form>
          </StyledSegment>
        </>
      )}
      <StyledSegment>
        <Grid padded="horizontally">
          <Grid.Row>
            <Grid.Column>
              <Header as="h3" style={{ fontWeight: 600 }}>
                {t('addCardModal.deliveryAddressHeader', 'Delivery Address')}
              </Header>
              <Form.Field>
                <Checkbox
                  toggle
                  checked={values.delivery.useSameAsBilling}
                  onChange={(e, { checked }) =>
                    setValues({
                      ...values,
                      delivery: {
                        ...values.delivery,
                        useSameAsBilling: checked,
                      },
                    })
                  }
                  label={
                    <label style={{ fontWeight: 'bold' }}>
                      {t(
                        'addCardModal.useBillingDeliveryAddress',
                        'Use same as billing'
                      )}
                    </label>
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        {!values.delivery.useSameAsBilling && (
          <DeliveryAddressFormPart
            objectPath="delivery"
            showErrorLabels={props.showErrorLabels}
          />
        )}
        {values.delivery.useSameAsBilling && <DeliveryAddressSameAsBilling />}
      </StyledSegment>
    </>
  );
}
