import React from 'react';

import { EvseControllerModalTabViewProps } from 'components/modals/EditEvseController/types';
import SelectTariffProfile from 'components/modals/EditEvseController/SelectTariffProfile';
import { Form, Message } from 'semantic';
import { formatTariffProfile } from 'utils/formatting';
import CostSettings from 'components/tariffs/EvseTariff';
import { useTranslation } from 'react-i18next';

export default function EditEvseControllerTariffsTab(
  props: EvseControllerModalTabViewProps
) {
  const { t } = useTranslation();
  const showEditTariff =
    !props.formValues.tariffProfileId ||
    (props.formValues.enableTariffProfileOverride &&
      props.tariffProfile &&
      props.tariffProfile.allowEvseControllerOverride);

  return (
    <>
      <SelectTariffProfile {...props} />

      {props.tariffProfile && (
        <Message info content={formatTariffProfile(props.tariffProfile)} />
      )}

      {props.tariffProfile?.allowEvseControllerOverride && (
        <Form.Checkbox
          label={t(
            'editEvseControllers.enableTariffProfileOverrideLabel',
            'Override tariff profile base tariff for this charge station'
          )}
          name="enableTariffProfileOverride"
          checked={props.formValues.enableTariffProfileOverride}
          onChange={(e, { name, checked }) => props.setField(name, checked)}
        />
      )}

      {showEditTariff && (
        <CostSettings
          currency={props.currency}
          disabled={
            !props.formValues.enablePublicCharging ||
            props.formValues.enablePublicFreeCharging ||
            !props.currency
          }
          value={props.formValues.costSettings!}
          energyDeliveryArea={props.data.energyDeliveryArea!}
          connectors={props.data.connectors!}
          evseConfiguration={props.evseConfiguration}
          evseConnectivityState={props.data.connectivityState!}
          evseControllerId={props.data.id!}
          numConnectors={props.formValues.numConnectors!}
          label={'Energy Price per kWh'}
          refetchEvseConfiguration={props.refetchEvseConfiguration}
          setIsReady={props.onReadyChange}
          setRebootRequired={props.onRebootRequiredChange}
          onChange={(costSettings) => {
            props.setField('costSettings', costSettings);
          }}
        />
      )}
    </>
  );
}
