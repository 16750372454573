import React from 'react';
import { FieldValidator, useField } from 'formik';
import { Form } from 'semantic';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  validate?: FieldValidator;
  id: string;
  // Set this to true if you want the valdiation occur immediately, instead of after touched;
  // i.e touched = input has been focused, or form submitted
  validateImmediately?: boolean;
  // set this to true if you do not want to show the error label below the input if the field is not valid. Note that
  // the field will still be styled as invalid
  hideErrorLabel?: boolean;
}

const RadioButtonField: React.FC<Props> = ({
  name,
  label,
  required,
  validate,
  hideErrorLabel,
  validateImmediately,
  id,
}) => {
  const [field, meta, helpers] = useField({ name, validate });
  const hasTouched = validateImmediately ? true : meta.touched;
  return (
    <Form.Radio
      checked={field.value === id}
      required={required}
      label={label}
      name={name}
      id={id}
      error={
        hasTouched && meta.error
          ? hideErrorLabel
            ? true
            : meta.error
          : undefined
      }
      onChange={(e, { id }) => helpers.setValue(id)}
    />
  );
};

export default RadioButtonField;
