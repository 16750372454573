import React, { useEffect, useState } from 'react';

import { Button, Card } from 'semantic';
import { request } from 'utils/api';
import {
  colorForSeverity,
  useIssueTypeDetectedText,
  useEvseIssueSeverityText,
  useEvseIssueDescription,
} from 'utils/evse-issues';
import { useTranslation } from 'react-i18next';

export default function EvseIssues(props) {
  const [data, setData] = useState(null);

  const fetch = () => {
    return request({
      method: 'POST',
      path: `/1/evse-issues/search`,
      body: {
        evseControllerId: props.evseControllerId,
      },
    }).then(({ data }) => {
      setData(data);
    });
  };

  useEffect(() => {
    fetch().catch(console.error);
  }, []);

  const resolve = (item) => {
    return request({
      method: 'POST',
      path: `/1/evse-issues/${item.id}/resolve`,
    }).then(() => fetch());
  };

  if (!data) return <span />;

  return (
    <div>
      {data.map((item) => (
        <EvseIssue evseIssue={item} resolve={resolve} key={item.id} />
      ))}
    </div>
  );
}

function EvseIssue({ evseIssue, resolve }) {
  const header = useIssueTypeDetectedText(evseIssue.type);
  const severityText = useEvseIssueSeverityText(evseIssue);
  const description = useEvseIssueDescription(evseIssue);
  return (
    <Card
      key={evseIssue.id}
      fluid
      color={colorForSeverity(evseIssue.severity)}
      style={{ marginBottom: '1.5rem' }}>
      <Card.Content>
        <Card.Header>{header}</Card.Header>
        <Card.Meta>{severityText}</Card.Meta>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          basic
          icon="check"
          content="Mark as Resolved"
          onClick={() => resolve(evseIssue)}
        />
      </Card.Content>
    </Card>
  );
}
