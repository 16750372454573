import React from 'react';

import InspectObject from 'components/modals/InspectObject';
import { Dropdown } from 'semantic';

import { Table, Modal, Label } from 'semantic';

import SearchContext from 'components/old_search/Context';
import { SearchProvider } from 'components';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/date';

export default function InvitesTable({
  onResendInvite,
  hideInspect,
  onDeleteItem,
}) {
  const { items, loading, reload } = React.useContext(SearchContext);
  const { t } = useTranslation();

  return (
    <div className="list">
      <SearchProvider.Status noItems={t('common.noResults', 'No Results')} />

      {!loading && items.length !== 0 && (
        <>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell width={3}>Role</Table.HeaderCell>
                <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                <Table.HeaderCell width={3}>Created</Table.HeaderCell>
                <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>{item.email}</Table.Cell>
                    <Table.Cell>
                      {item.accountRole && (
                        <Label content={item.accountRole.name} />
                      )}
                      {item.providerRole && (
                        <Label color="teal" content={item.providerRole.name} />
                      )}
                    </Table.Cell>
                    <Table.Cell>{item.status}</Table.Cell>
                    <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Dropdown button basic text="More">
                        <Dropdown.Menu direction="left">
                          {onResendInvite && (
                            <Dropdown.Item
                              basic
                              icon="arrow-rotate-right"
                              content="Resend Invite"
                              onClick={async () => {
                                await onResendInvite(item);
                                await reload();
                              }}
                            />
                          )}
                          {!hideInspect && (
                            <InspectObject
                              name="Location"
                              data={item}
                              trigger={
                                <Dropdown.Item
                                  basic
                                  content="Inspect"
                                  icon="file-code"
                                />
                              }
                            />
                          )}
                          <Modal
                            header={`Are you sure you want to delete?`}
                            content="All data will be permanently deleted"
                            trigger={
                              <Dropdown.Item
                                basic
                                icon="trash"
                                content="Delete"
                              />
                            }
                            closeIcon
                            actions={[
                              {
                                key: 'delete',
                                primary: true,
                                content: 'Delete',
                                onClick: async () => {
                                  await onDeleteItem(item);
                                  await reload();
                                },
                              },
                            ]}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <SearchProvider.Pagination />
        </>
      )}
    </div>
  );
}
