import React, { useEffect, useState } from 'react';
import { useField, FieldValidator, FieldArray } from 'formik';
import { Button, Form, Popup, Table } from 'semantic';
import { get, omit } from 'lodash-es';
import SelectField, {
  SelectFieldOption,
} from 'components/form-fields/formik/SelectField';
import { useTranslation } from 'react-i18next';
import InputField from 'components/form-fields/formik/InputField';
import { useProvider } from 'screens/Auth/SignupV2/useProvider';
import { Message } from 'semantic-ui-react';

interface AttributeMapping {
  role: string;
  attribute: string;
  operand: 'equals' | 'exists' | 'contains';
  value?: string;
}

interface Role {
  kind: 'account' | 'provider';
  value: string;
  text: string;
}

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  roles?: Array<Role> | (() => Promise<Role[]>);
  validate?: FieldValidator;
}

const SAMLRoleMappingField: React.FC<Props> = ({
  name,
  label,
  disabled,
  validate,
  roles,
}) => {
  const { t } = useTranslation();
  const provider = useProvider();
  const [field, meta, helpers] = useField({ name, validate });
  const [ready, setReady] = useState(false);
  const [options, setOptions] = useState<SelectFieldOption[]>([]);

  useEffect(() => {
    setReady(false);
    const fetchRoles = async () => {
      const resolvedRoles = await (typeof roles === 'function'
        ? roles()
        : Promise.resolve(roles));
      setOptions(
        (Array.isArray(resolvedRoles) ? resolvedRoles : []).map((role) => ({
          key: role.value,
          value: role.value,
          text: role.text,
        }))
      );
    };
    fetchRoles().then(() => setReady(true));
  }, []);

  // The list of operands which are supported by the SAML role mapping
  const supportedOperands = [
    {
      key: 'equals',
      value: 'equals',
      disableCondition: false,
      text: t('samlrolemappingfield.equals', 'Equals'),
    },
    {
      key: 'exists',
      value: 'exists',
      disableCondition: true,
      text: t('samlrolemappingfield.exists', 'Exists'),
    },
    {
      key: 'contains',
      value: 'contains',
      disableCondition: false,
      text: t('samlrolemappingfield.contains', 'Contains'),
    },
  ];

  const hasNoRoles = options.length === 0 && ready;
  if (hasNoRoles) {
    return (
      <Form.Field>
        {label && <label>{label}</label>}
        <Message error>
          {t('samlrolemappingfield.noRoles', 'No assignable roles were found.')}
        </Message>
      </Form.Field>
    );
  }

  return (
    <Form.Field>
      {label && <label>{label}</label>}
      <FieldArray
        name={name}
        render={(helpers) => {
          const values = get(helpers.form.values, name);
          return (
            <>
              <Table style={{ tableLayout: 'fixed' }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>
                      {t('samlrolemappingfield.assignRole', 'Assign role')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {t('samlrolemappingfield.attribute', 'Attribute')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      {t('samlrolemappingfield.operand', 'Operand')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {t('samlrolemappingfield.value', 'Value')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {values?.map((value: AttributeMapping, i: number) => {
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>
                          <SelectField
                            options={options}
                            disabled={!ready || disabled}
                            placeholder={t(
                              'samlrolemappingfield.selectPlaceholder',
                              'Select attribute'
                            )}
                            name={`${name}.${i}.roleId`}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <InputField
                            disabled={disabled}
                            name={`${name}.${i}.attribute`}
                            type="text"
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <SelectField
                            disabled={disabled}
                            name={`${name}.${i}.operand`}
                            placeholder={t(
                              'samlrolemappingfield.selectPlaceholder',
                              'Select role'
                            )}
                            options={supportedOperands.map((supportedOperand) =>
                              omit(supportedOperand, 'disableCondition')
                            )}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <InputField
                            disabled={
                              supportedOperands.find(
                                (x) => x.value === value.operand
                              )?.disableCondition || disabled
                            }
                            name={`${name}.${i}.value`}
                            type="text"
                          />
                        </Table.Cell>
                        <Table.HeaderCell>
                          {!disabled && (
                            <Popup
                              content={t(
                                'samlrolemappingfield.remove role',
                                'Remove role mapping'
                              )}
                              trigger={
                                <Button
                                  icon="trash"
                                  role="button"
                                  onClick={() => helpers.remove(i)}
                                />
                              }
                            />
                          )}
                        </Table.HeaderCell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              <div style={{ float: 'right' }}>
                <Button
                  as="button"
                  icon="plus"
                  disabled={!ready || disabled}
                  role="button"
                  label={t('samlrolemappingfield.add', 'Add role mapping')}
                  onClick={() =>
                    helpers.push({
                      roleId: '',
                      when: '',
                      operand: 'equals',
                      condition: '',
                    })
                  }
                />
              </div>
            </>
          );
        }}
      />
    </Form.Field>
  );
};

export default SAMLRoleMappingField;
