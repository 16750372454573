import React from 'react';
import Pagination from 'components/Pagination';

import styled from 'styled-components';
import ExportButton from 'components/ExportButton';
import { Link } from 'react-router-dom';
import { truncate } from 'utils/formatting';
import { colorForStatus, labelForStatus } from 'utils/locations';
import { withTranslation } from 'react-i18next';

import { Label, Loader, Message, Segment, Table } from 'semantic';
import { formatLocationAddress } from '../../utils/formatting';
import { ConnectivityStatus } from '../../components/ConnectivityStatus';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const evseControllerStatusOrdered = [
  'UNKNOWN',
  'AVAILABLE',
  'CHARGING',
  'OUTOFORDER',
  'NOT_AVAILABLE',
  'UNAVAILABLE',
  'INOPERATIVE',
  'SUSPENDEDEV',
  'BLOCKED',
  'FAULTED',
];
const evseControllerStatusMap = {
  UNAVAILABLE: 'NOT_AVAILABLE',
  OUTOFORDER: 'NOT_AVAILABLE',
  INOPERATIVE: 'NOT_AVAILABLE',
  BLOCKED: 'NOT_AVAILABLE',
  SUSPENDEDEV: 'CHARGING',
};

function determineEvseControllerStatus(item) {
  let status = 'UNKNOWN';

  if (item.connectorStatus) {
    // we will pluck all connector statuses and select the worst one
    const allStatuses = Object.values(item.connectorStatus)
      .map((connectorStatus) => {
        return connectorStatus.status?.toUpperCase() || 'UNKNOWN';
      })
      .filter((s) => !!s);

    const worstStatus = allStatuses.reduce((accumulator, current) => {
      return evseControllerStatusOrdered.indexOf(current) >
        evseControllerStatusOrdered.indexOf(accumulator)
        ? current
        : accumulator;
    }, 'UNKNOWN');

    status = evseControllerStatusMap[worstStatus] || worstStatus;
  }

  return (
    <Label
      content={labelForStatus(status.toUpperCase())}
      color={colorForStatus(status.toUpperCase())}
    />
  );
}

class DataTable extends React.Component {
  render() {
    const { status, data: items, meta = {}, page } = this.props;
    const { t } = this.props;

    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>
            {t(
              'evseControllerMaintenance.noEvseController',
              'No EVSE Controllers assigned yet'
            )}
          </Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>OCPP ID</Table.HeaderCell>
                <Table.HeaderCell width={3}>Location</Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t(
                    'evseControllerMaintenance.chargerStatus',
                    'Charger Status'
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('evseControllerMaintenance.connectivity', 'Connectivity')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <Link to={`/charging-stations/${item.id}`}>
                        {truncate(item.ocppIdentity, 20)}
                      </Link>
                      <p>
                        <small>Evse ID: {item.evseId || '-'}</small> <br />
                        <small>Serial number: {item.serialNumber || '-'}</small>
                      </p>
                    </Table.Cell>
                    <Table.Cell>
                      {item.location ? (
                        <>
                          {item.location.name},{' '}
                          {formatLocationAddress(item.location)}
                          <br />
                        </>
                      ) : (
                        '-'
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {determineEvseControllerStatus(item)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <ConnectivityStatus item={item} />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Loader active>Loading</Loader>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
        {status.success && meta.total > 0 && (
          <ExportButton
            content="Export All"
            path={'/1/evse-controllers/maintenance/search'}
            filename="evse-controllers"
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(DataTable);
