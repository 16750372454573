import React from 'react';

import { useTranslation } from 'react-i18next';
import { Divider, Message } from 'semantic';
import { Link } from 'react-router-dom';

import { ListHeader, Breadcrumbs } from 'components';
import {
  EvseControllerBulkActionExecution,
  EvseControllerBulkActionStatus,
  EvseControllerBulkActionType,
} from 'types/evse-controller-bulk-action-execution';

import useFetch from 'hooks/useFetch';
import Table from './Table';

type EvseControllerBulkActionExecutionsResponse =
  EvseControllerBulkActionExecution[];

export default function EvseControllersBackgroundJobsList() {
  const { t } = useTranslation();

  let {
    data: evseControllerBulkActionExecutionsData,
    loading: evseControllerBulkActionExecutionsLoading,
    error: evseControllerBulkActionExecutionsError,
  } = useFetch<EvseControllerBulkActionExecutionsResponse>({
    path: `/1/evse-bulk-actions/`,
  });

  const numResults = evseControllerBulkActionExecutionsData?.length || 0;

  return (
    <div>
      <Breadcrumbs
        path={[
          <Link to="/charging-stations">
            {t(
              'evseControllersBackgroundJobs.breadcrumbs',
              'Charging Stations'
            )}
          </Link>,
        ]}
        active={t('evseControllersBackgroundJobs.header', 'Background Jobs')}
      />
      <div style={{ marginTop: 0, marginBottom: 20 }}>
        <ListHeader
          title={t('evseControllersBackgroundJobs.title', 'Background Jobs')}
        />
      </div>

      {evseControllerBulkActionExecutionsError && (
        <Message
          error
          content={evseControllerBulkActionExecutionsError.message}
        />
      )}
      {!evseControllerBulkActionExecutionsError && (
        <div>
          <p style={{ color: '#6C727F', marginRight: '1em' }}>
            {t(
              'evseControllersBackgroundJobs.resultsFound',
              '{{numResults}} result(s)',
              { numResults }
            )}
          </p>
          <Table
            evseControllerBulkActionExecutions={
              evseControllerBulkActionExecutionsData || []
            }
            loading={evseControllerBulkActionExecutionsLoading}
          />
        </div>
      )}
      <Divider hidden />
    </div>
  );
}
