import React from 'react';

import { useTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';

import {
  EvseControllerBulkActionExecution,
  EvseControllerBulkActionStatus,
} from 'types/evse-controller-bulk-action-execution';

type Props = {
  evseControllerBulkActionExecution: EvseControllerBulkActionExecution;
};

export default function EvseControllerBulkActionExecutionStatus({
  evseControllerBulkActionExecution,
}: Props) {
  const { t } = useTranslation();

  switch (evseControllerBulkActionExecution.status) {
    case EvseControllerBulkActionStatus.InProgress:
      return (
        <Label
          color="blue"
          content={t(
            'evseControllerBulkActionExecutionStatus.inProgress',
            'In Progress'
          )}
        />
      );
    case EvseControllerBulkActionStatus.PartlyCompleted:
      return (
        <Label
          color="orange"
          content={t(
            'evseControllerBulkActionExecutionStatus.partlyCompleted',
            'Partly Completed'
          )}
        />
      );
    case EvseControllerBulkActionStatus.Completed:
      return (
        <Label
          color="green"
          content={t(
            'evseControllerBulkActionExecutionStatus.completed',
            'Completed'
          )}
        />
      );
    case EvseControllerBulkActionStatus.Failed:
      return (
        <Label
          color="red"
          content={t(
            'evseControllerBulkActionExecutionStatus.failed',
            'Failed'
          )}
        />
      );
    default:
      return (
        <Label
          color="grey"
          content={t(
            'evseControllerBulkActionExecutionStatus.unknown',
            'Unknown'
          )}
        />
      );
  }
}
