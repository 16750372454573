import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button, Dropdown, Icon, Label } from 'semantic';
import { Link } from 'react-router-dom';
import { currentUserCanAccess } from 'utils/roles';
import { Search, HelpTip } from 'components';
import EditUser from 'components/modals/EditUser';
import Actions from '../Actions';
import { formatDateTime } from 'utils/date';
import { formatRoles } from '../../../utils/formatting';
import { request } from '../../../utils/api';
import ResourceLink from 'components/Link/ResourceLink';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';

export default function UsersTable({ displayContext = 'users', onDelete }) {
  const { items, loading, reload } = useContext(Search.Context);
  const { t } = useTranslation();

  const writeAccess = currentUserCanAccess('users', 'write');

  const fetchUser = async (id) => {
    const { data } = await request({
      method: 'GET',
      path: `/1/users/${id}`,
    });

    return data;
  };

  if (!items.length || loading) return null;

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>
              {t('users.columnName', 'Name')}
            </Table.HeaderCell>

            {displayContext === 'users' && (
              <>
                <Table.HeaderCell width={3}>
                  {t('users.columnAccountName', 'Account Name')}
                </Table.HeaderCell>
              </>
            )}

            <Table.HeaderCell width={3}>
              {t('users.columnEmail', 'E-mail')}
            </Table.HeaderCell>

            {displayContext === 'user' && (
              <>
                <Table.HeaderCell>
                  {t('accountUsers.columnRoles', 'Roles')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('accountUsers.columnSecurity', 'Security')}
                </Table.HeaderCell>
              </>
            )}

            <Table.HeaderCell>
              {t('users.columnJoined', 'Joined')}
              <HelpTip
                title={t('users.columnJoined', 'Joined')}
                text={t(
                  'users.columnJoinedToolTip',
                  'This is the date and time the user was created.'
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('users.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.length === 0 && (
            <Table.Row>
              <Table.Cell>
                {t('users.noUsersAdded', 'No users added yet')}
              </Table.Cell>
            </Table.Row>
          )}

          {items.map((item) => {
            const externalLinkRoute = `/accounts/users/${item.id}`;
            const itemProviderId = item.providerId;
            const linkName =
              item.name || t('users.unknownName', 'Unknown Name');

            const itemHasGlobalRoles = !!(item.globalRoles || []).length;
            const itemHasSuperAdminRole = item.roles.includes('super_admin');

            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <ResourceLink
                    route={externalLinkRoute}
                    searchItemProviderId={itemProviderId}
                    linkName={''}
                    style={{
                      color: `${
                        itemHasGlobalRoles || itemHasSuperAdminRole
                          ? 'orange'
                          : '#008cd9' // blue
                      }`,
                    }}
                    children={
                      <>
                        {item.roles.includes('super_admin') && (
                          <>
                            <Icon name="crown" />{' '}
                          </>
                        )}

                        {linkName}

                        {item.mfaMethod && (
                          <span title={'MFA is enabled'}>
                            <Icon name="lock" />
                          </span>
                        )}
                      </>
                    }
                  />
                </Table.Cell>
                {displayContext === 'users' && (
                  <>
                    <Table.Cell>
                      {item.accountId ? (
                        <ResourceLink
                          route={`/accounts/${item.accountId}`}
                          searchItemProviderId={itemProviderId}
                          linkName={
                            item.account
                              ? item.account.name
                              : t('users.notNamed', 'Not named')
                          }
                        />
                      ) : (
                        <span>{t('users.noAccount', 'No Account')}</span>
                      )}
                    </Table.Cell>
                  </>
                )}
                <Table.Cell>{item.email}</Table.Cell>

                {displayContext === 'user' && (
                  <>
                    <Table.Cell>
                      {formatRoles(
                        item.accountRoles,
                        item.providerRoles,
                        item.globalRoles,
                        item.systemRoles
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {item.mfaMethod ? <Label content="MFA on" /> : '-'}
                    </Table.Cell>
                  </>
                )}

                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                <Table.Cell textAlign="center">
                  <EditUser
                    getData={() => fetchUser(item.id)}
                    onClose={removeResourceProviderIdInSessionStorage}
                    trigger={
                      <Button
                        title="Edit"
                        basic
                        onClick={() =>
                          setResourceProviderIdInSessionStorage(
                            item?.providerId
                          )
                        }
                        icon="pen-to-square"
                        disabled={!writeAccess}
                      />
                    }
                    onSave={() => reload()}
                  />
                  <Dropdown button basic text="More">
                    <Dropdown.Menu direction="left">
                      <Actions
                        getData={() => fetchUser(item.id)}
                        item={item}
                        onDelete={onDelete}
                        onReload={reload}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
