import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useParams } from 'react-router-dom';

import { Divider, Message, Dropdown, Button } from 'semantic';
import Menu from './Menu';

import Overview from './Overview';
import Logs from './Logs';
import Examples from './Examples';

import { request } from 'utils/api';
import { Breadcrumbs, ListHeader, PageLoader } from 'components';
import EditOcpiCredential from 'components/modals/EditOcpiCredential';

import { useTranslation } from 'react-i18next';
import Actions from '../Actions';

export default function OcpiCredential() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchItem = async () => {
    try {
      setError(null);
      setLoading(true);
      const { data } = await request({
        method: 'GET',
        path: `/1/ocpi-credentials/${id}`,
      });
      setItem(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItem();
  }, [id]);

  const refresh = () => {
    fetchItem();
  };

  return (
    <>
      <Breadcrumbs
        path={[
          <Link key="OCPI" to="/credentials/ocpi">
            OCPI Credentials
          </Link>,
        ]}
        active={
          item?.id
            ? `${item.name} (as a ${
                item.providerContext === 'cpo' ? 'MSP' : 'CPO'
              })`
            : 'Loading...'
        }
      />

      <ListHeader title={item?.name || '...'}>
        <Dropdown
          button
          basic
          disabled={!item}
          text="Actions"
          style={{ marginTop: '-5px' }}>
          <Dropdown.Menu direction="left">
            {item && <Actions item={item} onReload={refresh} />}
          </Dropdown.Menu>
        </Dropdown>

        <EditOcpiCredential
          initialValues={item}
          trigger={
            <Button
              disabled={!item}
              content={t('ocpiCredential.configure', 'Configure')}
              icon="gears"
            />
          }
          onSave={() => refresh()}
        />
      </ListHeader>

      <Divider hidden />

      {item && item.deletedAt && (
        <Message error content="This OCPI credential has been deleted" />
      )}

      <Menu itemId={id} />

      <Divider hidden />
      {!item && loading && <PageLoader />}

      {item && !loading && (
        <Switch>
          <Route
            exact
            path="/credentials/ocpi/:id"
            item={item}
            component={(props) => <Overview {...props} ocpiCredential={item} />}
          />
          <Route
            exact
            path="/credentials/ocpi/:id/logs"
            component={(props) => <Logs {...props} ocpiCredential={item} />}
          />
          <Route
            exact
            path="/credentials/ocpi/:id/examples"
            component={(props) => <Examples {...props} ocpiCredential={item} />}
          />
        </Switch>
      )}
    </>
  );
}
