import React from 'react';
import Pagination from 'components/Pagination';
import { Link } from 'react-router-dom';

import { Table, Loader, Segment, Message, Button, Label } from 'semantic';
import {
  FormattedIssueSeverity,
  FormattedIssueType,
  useEvseIssueDescription,
  useEvseIssueSeverityText,
} from 'utils/evse-issues';
import { formatDuration } from 'utils/date';

import { useTranslation, withTranslation } from 'react-i18next';
import { fromNow } from 'utils/date';

function FormattedStatus({ evseIssue }) {
  const { t } = useTranslation();
  if (!evseIssue.isResolved) {
    return <Label content={t('evseIssuesTable.statusOpen', 'Open')} />;
  }
  const durationSeconds =
    (Date.parse(evseIssue.resolvedAt) - Date.parse(evseIssue.createdAt)) / 1000;

  return (
    <>
      <Label
        content={t('evseIssuesTable.statusResolved', 'Resolved')}
        color="olive"
        icon="check"
      />
      <p>
        <small>in {formatDuration(durationSeconds)}</small>
      </p>
    </>
  );
}

class DataTable extends React.Component {
  toggleAll(items, checked) {
    items.forEach((item) => {
      this.props.onToggleItem(item.id, checked);
    });
  }
  render() {
    const { status, data: items, meta = {}, page, t, isResolved } = this.props;

    return (
      <div className="list">
        {status.success && !items.length && (
          <Message
            icon="beer-mug-empty"
            header={t('evseIssuesTable.noIssues', 'No issues detected')}
            content={t('evseIssuesTable.noIssuesContent', 'Excellent!')}
          />
        )}

        {status.success && items.length > 0 && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>
                  {t('evseIssuesTable.columnEvse', 'EVSE')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('evseIssuesTable.columnIssueType', 'Issue Type')}
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  {t('evseIssuesTable.columnSeverity', 'Severity')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('evseIssuesTable.columnStatus', 'Status')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('evseIssuesTable.columnDescription', 'Description')}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  {t('evseIssuesTable.columnDetected', 'Detected')}
                </Table.HeaderCell>
                {isResolved && (
                  <Table.HeaderCell width={2}>
                    {t('evseIssuesTable.columnResolvedBy', 'Resolved By')}
                  </Table.HeaderCell>
                )}
                {!isResolved && (
                  <Table.HeaderCell width={1}>
                    {t('evseIssuesTable.columnActions', 'Actions')}
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {item.evseController && (
                      <Link to={`/charging-stations/${item.evseController.id}`}>
                        {item.evseController.ocppIdentity}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <FormattedIssueType evseIssue={item} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <FormattedIssueSeverity evseIssue={item} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <FormattedStatus evseIssue={item} />
                  </Table.Cell>
                  <Table.Cell>
                    <DescriptionCell evseIssue={item} />
                  </Table.Cell>
                  <Table.Cell>{fromNow(item.createdAt)}</Table.Cell>
                  {isResolved && (
                    <Table.Cell>
                      {item.resolvedByUser ? (
                        <Link to={`/accounts/users/${item.resolvedByUser.id}`}>
                          {item.resolvedByUser.name}
                        </Link>
                      ) : (
                        <Label>System</Label>
                      )}
                    </Table.Cell>
                  )}
                  {!isResolved && (
                    <Table.Cell textAlign="center">
                      <Button
                        basic
                        icon="check"
                        title={t('evseIssuesTable.resolve', 'Resolve')}
                        onClick={() => this.props.onResolveItem(item)}
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Pagination
            limit={this.props.limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) =>
              this.props.setPage(activePage).then(() => {
                window.scrollTo(0, 0);
              })
            }
          />
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Loader active>{t('loading.loading')}</Loader>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </div>
    );
  }
}

function DescriptionCell({ evseIssue }) {
  const message = useEvseIssueDescription(evseIssue);
  return <>{message}</>;
}

export default withTranslation()(DataTable);
