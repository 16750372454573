import React from 'react';

import { Label } from 'semantic';
import { useTranslation } from 'react-i18next';
import { fromNow } from 'utils/date';

export const issueTypes = {
  noConnectivity: 'No Connectivity',
  socketError: 'Socket Error',
  bootLoop: 'Boot Loop',
  authorizationFailures: 'Authorization Failures',
  concurrentTx: 'Concurrent TX',
  commandErrors: 'Command Errors',
  underVoltage: 'Under Voltage',
  powerMeterFailure: 'Power Meter Failure',
  tooManyCommands: 'Too Many Commands',
  tooManySessions: 'Too Many Sessions',
  doubleOcppIdentity: 'Double OCPP Identity',
  doubleOcppIdentityBySerialNumber: 'Double OCPP Identity By Serial Number',
  idleFeeWithIncompatibleEvseSettings: 'Idle Fee',
  costSettingsMisconfigured: 'Cost Settings Not Configured',
  issuesReportedByChargingStation: 'Issues Reported By Charging Station',
  txStartPointMisconfigured: 'TX Start Point Not Configured Correctly',
  invalidStartDatetime:
    'Invalid Start Session Date Time Sent By Charging Station',
};

export function colorForSeverity(severity) {
  let color = 'grey';
  if (severity === 'medium') {
    color = 'yellow';
  }
  if (severity === 'high') {
    color = 'orange';
  }
  if (severity === 'critical') {
    color = 'red';
  }
  return color;
}

export function FormattedIssueType({ evseIssue }) {
  const text = useIssueTypeDetectedText(evseIssue.type);
  return <Label content={text} color={colorForSeverity(evseIssue.severity)} />;
}

export function FormattedIssueSeverity({ evseIssue }) {
  const text = useEvseIssueSeverityText(evseIssue);
  return (
    <Label
      content={text}
      color={colorForSeverity(evseIssue.severity)}
      size="tiny"
    />
  );
}

export function useIssueTypeDetectedText(issueType) {
  const { t } = useTranslation();
  switch (issueType) {
    case 'noConnectivity':
      return t('evseIssues.noConnectivity', 'No Connectivity Issue Detected');
    case 'socketError':
      return t('evseIssues.socketError', 'Socket Error Issue Detected');
    case 'bootLoop':
      return t('evseIssues.bootLoop', 'Boot Loop Issue Detected');
    case 'authorizationFailures':
      return t(
        'evseIssues.authorizationFailures',
        'Authorization Failures Issue Detected'
      );
    case 'concurrentTx':
      return t('evseIssues.concurrentTx', 'Concurrent TX Issue Detected');
    case 'commandErrors':
      return t('evseIssues.commandErrors', 'Command Errors Issue Detected');
    case 'underVoltage':
      return t('evseIssues.underVoltage', 'Under Voltage Issue Detected');
    case 'powerMeterFailure':
      return t(
        'evseIssues.powerMeterFailure',
        'Power Meter Failure Issue Detected'
      );
    case 'tooManyCommands':
      return t(
        'evseIssues.tooManyCommands',
        'Too Many Commands Issue Detected'
      );
    case 'tooManySessions':
      return t(
        'evseIssues.tooManySessions',
        'Too Many Sessions Issue Detected'
      );
    case 'doubleOcppIdentity':
      return t(
        'evseIssues.doubleOcppIdentity',
        'Double OCPP Identity Issue Detected'
      );
    case 'doubleOcppIdentityBySerialNumber':
      return t(
        'evseIssues.doubleOcppIdentityBySerialNumber',
        'Double OCPP Identity By Serial Number Issue Detected'
      );
    case 'idleFeeWithIncompatibleEvseSettings':
      return t(
        'evseIssues.idleFeeWithIncompatibleEvseSettings',
        'Idle Fee Issue Detected'
      );
    case 'costSettingsMisconfigured':
      return t(
        'evseIssues.costSettingsMisconfigured',
        'Cost Settings Not Configured'
      );
    case 'issuesReportedByChargingStation':
      return t(
        'evseIssues.issuesReportedByChargingStation',
        'Issues Reported By Charging Station'
      );
    case 'txStartPointMisconfigured':
      return t(
        'evseIssues.txStartPointMisconfigured',
        'TX Start Point Not Configured Correctly'
      );
    case 'invalidStartDatetime':
      return t(
        'evseIssues.invalidStartDatetime',
        'Invalid Start Session Date Time Sent By Charging Station'
      );
    default:
      return t('evseIssues.unknown', 'Unknown Issue Detected');
  }
}

export function useEvseIssueSeverityText(evseIssue) {
  const { t } = useTranslation();
  let severity = t('evseIssues.severityUnknown', 'Unknown');
  switch (evseIssue.severity) {
    case 'critical':
      severity = t('evseIssues.severityCritical', 'Critical');
      break;
    case 'high':
      severity = t('evseIssues.severityHigh', 'High');
      break;
    case 'medium':
      severity = t('evseIssues.severityMedium', 'Medium');
      break;
    case 'low':
      severity = t('evseIssues.severityLow', 'Low');
      break;
    default:
      break;
  }

  return t(
    'evseIssues.severitySummary',
    'Severity: {{severity}}, First Detected: {{firstDetected}}',
    { severity, firstDetected: fromNow(evseIssue.createdAt) }
  );
}

export function useEvseIssueDescription(evseIssue) {
  const { t } = useTranslation();
  if (!evseIssue.context) {
    return evseIssue.description;
  }
  switch (evseIssue.type) {
    case 'noConnectivity':
      return t(
        'evseIssues.noConnectivityDescription',
        'Has not received a connectivity heartbeat in 24 hours. Last heartbeat was about {{numHours}} hours ago',
        evseIssue.context
      );
    case 'socketError':
      return t(
        'evseIssues.socketErrorDescription',
        'One or more of the sockets has an error status. Connector has bad status: "{{status}}", Error: {{errorCode}} - {{info}}',
        evseIssue.context
      );
    case 'underVoltage':
      return t(
        'evseIssues.underVoltageDescription',
        'One or more of the sockets has an UnderVoltage fault. Connector has an undervoltage fault: Status "{{status}}", Error: {{errorCode}} - {{info}}',
        evseIssue.context
      );
    case 'powerMeterFailure':
      return t(
        'evseIssues.powerMeterFailureDescription',
        'One or more of the sockets has a power meter failure. Connector has a power meter failure: Status "{{status}}", Error: {{errorCode}} - {{info}}',
        evseIssue.context
      );
    case 'authorizationFailures':
      return t(
        'evseIssues.authorizationFailuresDescription',
        'Multiple authorization failures happened recently. All last {{numInvalid}} Authorize command results were Invalid',
        evseIssue.context
      );
    case 'concurrentTx':
      return t(
        'evseIssues.concurrentTxDescription',
        'A double session error was detected. All last {{numConcurrentTx}} Authorize command results were ConcurrentTx',
        evseIssue.context
      );
    case 'commandErrors':
      return t(
        'evseIssues.commandErrorsDescription',
        'Multiple errors were detected in recent commands. Detected {{numErrors}} command errors in the past {{numHours}} hours. Commands: {{commands}}',
        evseIssue.context
      );
    case 'tooManyCommands':
      return t(
        'evseIssues.tooManyCommandsDescription',
        'Too many commands occurred in a short period of time. Detected {{numRecentCommands}} commands in the past {{numHours}} hours which exceeds expected maximum of {{maxCommands}} (over {{numConnectors}} sockets)',
        evseIssue.context
      );
    case 'tooManySessions':
      return t(
        'evseIssues.tooManySessionsDescription',
        'Too many sessions occurred in a short period of time. Detected {{numRecentSessions}} sessions in the past {{numHours}} hours from {{ocppIdentity}} which exceeds expected maximum of {{maxSessions}} (over {{numConnectors}} sockets)',
        evseIssue.context
      );
    case 'doubleOcppIdentity':
      return t(
        'evseIssues.doubleOcppIdentityDescription',
        'EVSE is using an OCPP Identity which is already in use. Detected multiple uses of ocppIdentity: {{evseIds}}',
        {
          ...evseIssue.context,
          evseIds: formatEvseIdsList(t, evseIssue.context.evseIds),
        }
      );
    case 'doubleOcppIdentityBySerialNumber':
      return t(
        'evseIssues.doubleOcppIdentityBySerialNumberDescription',
        'A second serial number is connected using the same OCPP Identity. Detected multiple uses of ocppIdentity: {{connected}}',
        {
          ...evseIssue.context,
          connected: formatConnectedList(t, evseIssue.context.connected),
        }
      );
    case 'txStartPointMisconfigured':
      return t(
        'evseIssues.txStartPointMisconfiguredDescription',
        'The EVSE is not configured to start a transaction at the correct point. Detected on {{ocppIdentity}} with the value of {{txStartPointValues}}',
        evseIssue.context
      );
    case 'issuesReportedByChargingStation':
      return t(
        'evseIssues.issuesReportedByChargingStationDescription',
        'Charging station issues. Received {{numOngoingIssues}} notifications: {{issuesText}}',
        evseIssue.context
      );
    case 'invalidStartDatetime':
      return t(
        'evseIssues.invalidStartDatetimeDescription',
        'Detected {{numErrors}} {{commands}} command(s) in the past 24 hours from {{ocppIdentity}} which have an invalid date time. Error codes: {{errorCodes}}',
        evseIssue.context
      );
    default:
      return evseIssue.description;
  }
}

function formatEvseIdsList(t, evseIds = []) {
  return evseIds
    .map((evse) =>
      t(
        'evseIssues.doubleOcppIdentityEvseIdFormatted',
        'providerName: {{providerName}}, ID: {{id}}',
        evse
      )
    )
    .join(' | ');
}

function formatConnectedList(t, connected = []) {
  return connected
    .map((evse) =>
      t(
        'evseIssues.doubleOcppIdentityBySerialNumberFormatted',
        'Received boot notification with serial number {{serialNumber}} {{numHours}} hours ago',
        evse
      )
    )
    .join(' | ');
}
