import React from 'react';
import { Divider, Grid, Segment, Statistic } from 'semantic';
import { withTranslation } from 'react-i18next';
import {
  AggregateTimeSeries,
  ErrorBoundary,
  SeriesChart,
} from 'react-tectonic';

import {
  changeFilterId,
  defaultColors,
  mergeFilters,
  sessionsExclusionFilter,
} from 'utils/visualizations';
import CardinalityStatistic from 'components/analytics/CardinalityStatistic';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import { numberWithCommas } from 'utils/formatting';

class CpoOverview extends React.Component {
  render() {
    const { t, includeLocations = true, baseFilter = {} } = this.props;

    const locationFilter = changeFilterId(baseFilter, 'locationId');

    return (
      <ErrorBoundary>
        <Divider hidden />
        <Divider hidden />

        <Statistic.Group
          widths={includeLocations ? 3 : 2}
          style={{ justifyContent: 'space-between' }}>
          {includeLocations && (
            <CardinalityStatistic
              collection="locations"
              field="id"
              label="Locations"
              filter={locationFilter}
            />
          )}
          <CardinalityStatistic
            collection="sessions"
            field="evseControllerId"
            label="EVSEs"
            filter={baseFilter}
          />
          <CardinalityStatistic
            collection="sessions"
            label="Sessions"
            filter={baseFilter}
          />
        </Statistic.Group>

        <Divider hidden />
        <Divider hidden />

        <AggregateTimeSeries
          collection="sessions"
          operation="count"
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          <SeriesChart
            title={t('analyticsSessions.sessions', 'Sessions')}
            titleAlign="center"
            chartType="bar"
            height={350}
            valueField="count"
            valueFieldLabel={t('analyticsSessions.sessions', 'Sessions')}
            color={defaultColors[0]}
          />
        </AggregateTimeSeries>

        {includeLocations && (
          <React.Fragment>
            <Divider hidden />
            <Divider hidden />

            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Segment basic>
                    <TermsDonutChart
                      collection="locations"
                      aggField="status"
                      title="Current Location Status"
                      filter={locationFilter}
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </React.Fragment>
        )}

        <Divider hidden />
        <Divider hidden />

        <AggregateTimeSeries
          collection="sessions"
          operation="sum"
          field="kwh"
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          <SeriesChart
            title={t('analyticsSessions.totalkWh', 'Total kWh')}
            titleAlign="center"
            chartType="area"
            height={350}
            valueFieldLabel="kWh"
            valueFormatter={(value) => numberWithCommas(Math.round(value))}
            color={defaultColors[0]}
          />
        </AggregateTimeSeries>

        <Divider hidden />
        <Divider hidden />
      </ErrorBoundary>
    );
  }
}

export default withTranslation()(CpoOverview);
