const systemMenuDefinitions = [
  {
    id: 'system_new', // temporary until we remove the existing `system`
    title: (t) => t('menu.system.main', 'Main Menu'),
    items: [
      {
        content: (t) => t('menu.system.providers.main', 'Providers'),
        icon: 'building',
        to: '/system/providers',
        endpoint: 'system/providers',
        requiresFeatureFlag: 'system_menu_providers',
        systemRolesOnly: true,
      },
      {
        content: (t) => t('menu.system.finance.main', 'Finance'),
        icon: 'coins',
        to: '/system/finance',
        endpoint: 'system/finance',
        requiresFeatureFlag: 'system_menu_finance',
        systemRolesOnly: true,
        subItems: [
          {
            content: (t) => t('menu.system.finance.invoices', 'Invoices'),
            icon: 'file-invoice',
            to: '/system/finance/invoices',
            endpoint: 'system/finance/invoices',
            requiresFeatureFlag: 'system_menu_finance_invoices',
            systemRolesOnly: true,
          },
          {
            content: (t) => t('menu.system.finance.roaming', 'Roaming'),
            icon: 'globe',
            to: '/system/finance/roaming',
            endpoint: 'system/finance/roaming',
            requiresFeatureFlag: 'system_menu_finance_roaming',
            systemRolesOnly: true,
          },
          {
            content: (t) =>
              t('menu.system.finance.billingPlans', 'Billing Plans'),
            icon: 'user-tag',
            to: '/system/finance/billing-plans',
            endpoint: 'system/finance/billingPlans',
            requiresFeatureFlag: 'system_menu_finance_billing_plans',
            systemRolesOnly: true,
          },
          {
            content: (t) =>
              t('menu.system.finance.exchangeRates', 'Exchange Rates'),
            icon: 'chart-line',
            to: '/system/finance/exchange-rates',
            endpoint: 'system/finance/exchangeRates',
            requiresFeatureFlag: 'system_menu_finance_exchange_rates',
            systemRolesOnly: true,
          },
          {
            content: (t) =>
              t(
                'menu.system.finance.roamingCpoCostOverride',
                'Roaming overrides'
              ),
            icon: 'globe',
            to: '/system/finance/roaming-overrides',
            endpoint: 'system/finance/roamingOverrides',
            requiresFeatureFlag: 'system_menu_finance_roaming_overrides',
            systemRolesOnly: true,
          },
          {
            content: (t) =>
              t('menu.system.finance.roamingLimits', 'Roaming limits'),
            icon: 'thumbtack',
            to: '/system/finance/roaming-limits',
            endpoint: 'system/finance/roamingLimits',
            requiresFeatureFlag: 'system_menu_finance_roaming_limits',
            systemRolesOnly: true,
          },
        ],
      },
      {
        content: (t) => t('menu.system.roaming.main', 'Roaming'),
        icon: 'globe',
        to: '/system/roaming',
        endpoint: 'system/roaming',
        requiresFeatureFlag: 'system_menu_roaming',
        systemRolesOnly: true,
        subItems: [
          {
            content: (t) =>
              t('menu.system.roaming.mspLocations', 'MSP Locations'),
            icon: 'location-dot',
            to: '/system/roaming/msp/locations',
            endpoint: 'system/roaming/msp/locations',
            requiresFeatureFlag: 'system_menu_roaming_msp_locations',
            systemRolesOnly: true,
          },
          {
            content: (t) =>
              t('menu.system.roaming.mspProviders', 'MSP Providers'),
            icon: 'building',
            to: '/system/roaming/msp/providers',
            endpoint: 'system/roaming/msp/providers',
            requiresFeatureFlag: 'system_menu_roaming_msp_providers',
            systemRolesOnly: true,
          },
          {
            content: (t) => t('menu.system.roaming.tokens', 'Tokens'),
            icon: 'tag',
            to: '/system/roaming/tokens',
            endpoint: 'system/roaming/tokens',
            requiresFeatureFlag: 'system_menu_roaming_tokens',
            systemRolesOnly: true,
          },
          {
            content: (t) =>
              t('menu.system.roaming.ocpiCredentials', 'OCPI Credentials'),
            icon: 'right-left',
            to: '/system/roaming/ocpi',
            endpoint: 'system/roaming/ocpiCredentials',
            requiresFeatureFlag: 'system_menu_roaming_ocpi_credentials',
            systemRolesOnly: true,
          },
        ],
      },
      {
        content: (t) =>
          t('menu.system.global.chargingStations', 'Charging stations'),
        icon: 'charging-station',
        to: '/search/charging-stations',
        endpoint: 'system/search/charging-stations',
        providerOrGlobalRolesOnly: true,
      },
      {
        content: (t) => t('menu.system.iam.main', 'IAM'),
        icon: 'key',
        to: '/system/iam',
        endpoint: 'system/iam',
        requiresFeatureFlag: 'system_menu_iam',
        systemRolesOnly: true,
        subItems: [
          {
            content: (t) => t('menu.system.iam.accessRoles', 'Access Roles'),
            icon: 'user-tag',
            to: '/system/iam/roles',
            endpoint: 'system/iam/accessRoles',
            requiresFeatureFlag: 'system_menu_iam_access_roles',
            systemRolesOnly: true,
          },
        ],
      },
      {
        content: (t) => t('menu.system.systemLogs.main', 'System Logs'),
        icon: 'server',
        to: '/system/sync-log',
        endpoint: 'systemLogs',
        requiresFeatureFlag: 'system_menu_system_logs',
        systemRolesOnly: true,
        subItems: [
          {
            content: (t) =>
              t('menu.system.systemLog.syncIssues', 'Sync Issues'),
            icon: 'circle-exclamation',
            to: '/system/sync-log/sync-issues',
            endpoint: 'syncIssues',
            requiresFeatureFlag: 'system_menu_system_logs_sync_issues',
            systemRolesOnly: true,
          },
          {
            content: (t) =>
              t('menu.system.systemLog.auditTrail', 'Audit Trail'),
            icon: 'list-ol',
            to: '/system/sync-log/audit-trail',
            endpoint: 'auditTrail',
            requiresFeatureFlag: 'system_menu_system_logs_audit_trail',
            systemRolesOnly: true,
          },
        ],
      },
      {
        content: (t) => t('menu.system.migrations', 'Migrations'),
        icon: 'clone',
        to: '/system/migrations/enterprise-accounts',
        endpoint: 'enterprise-account-migrations',
        efluxOnly: true,
        superAdminOnly: true,
        requiresFeatureFlag: 'system_menu_enterprise_account_migrations',
      },
    ],
  },
];

module.exports = { systemMenuDefinitions };
