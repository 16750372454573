import React from 'react';
import { startCase, find, isEmpty } from 'lodash-es';
import {
  Header,
  Table,
  Divider,
  Loader,
  Segment,
  Button,
  Label,
  Popup,
} from 'semantic';

import { OCPP_VERSION_16, OCPP_VERSION_201 } from 'utils/ocpp';
import { request } from 'utils/api';
import { determineLastConnectivityMode } from 'utils/evse-controllers';
import UpdateFirmware from 'components/modals/UpdateFirmware';
import AutoConfigureEvseController from 'components/modals/AutoConfigureEvseController';
import EditEvseControllerConnectors from 'components/modals/EditEvseControllerConnectors';
import {
  currentUserCanAccessProviderEndpoint,
  currentUserCanAccess,
  currentUserIsSuperAdmin,
} from 'utils/roles';
import { FeatureFlag } from 'components';
import { ConnectivityStatus } from 'components/ConnectivityStatus';
import { fromNow } from 'utils/date';
import ErrorMessage from 'components/ErrorMessage';

function formatAuthorizationState(authorizationInfo) {
  const { state, error } = authorizationInfo;
  if (state === 'error') {
    return (
      <Popup
        content={error.message}
        trigger={
          <Label
            title={error.message}
            content={startCase(state)}
            color="error"
          />
        }
      />
    );
  }
  if (state === 'success') {
    return <Label content={startCase(state)} color="olive" />;
  }
  if (state === 'skipped') {
    return <Label content={startCase(state)} color="olive" />;
  }
  return <Label content="Unknown" />;
}

export default class Diagnostics extends React.Component {
  state = {
    loading: false,
    error: null,
    loadingEnableBasicAuth: false,
    errorEnableBasicAuth: null,
    evseController: null,
  };

  componentDidMount() {
    this.fetchItem(this.props.evseController.id);
    this.interval = setInterval(() => {
      this.fetchItem(this.props.evseController.id);
    }, 4000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchItem = async (itemId) => {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/evse-controllers/${itemId}`,
      });
      if (
        this.state.evseController?.ocppAuthorizationMethod !==
        data?.ocppAuthorizationMethod
      ) {
        this.setState({ loadingEnableBasicAuth: false });
      }

      this.setState({ evseController: data, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  };

  getDiagnostics() {
    this.setState({ loading: true });
    return request({
      method: 'POST',
      path: `/1/evse-controllers/${this.props.evseController.id}/diagnostics`,
      body: {},
    })
      .then(() => {
        this.fetchItem(this.props.evseController.id);
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  resetSerialNumbers() {
    return request({
      method: 'POST',
      path: `/1/evse-controllers/${this.props.evseController.id}/reset-serial-numbers`,
      body: {},
    })
      .then(({ data }) => {
        this.setState({ evseController: data, loading: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  changeOcppAuthorizationMethod() {
    const authentication =
      this.state.evseController?.ocppAuthorizationMethod === 'none'
        ? 'basic'
        : 'none';
    this.setState({ loadingEnableBasicAuth: true, errorEnableBasicAuth: null });

    return request({
      method: 'PATCH',
      path: `/1/evse-controllers/${this.props.evseController.id}/authentication`,
      body: { authentication },
    })
      .then(() => {
        this.fetchItem(this.props.evseController.id);
      })
      .catch((error) => {
        this.setState({
          errorEnableBasicAuth: error,
          loadingEnableBasicAuth: false,
        });
      });
  }

  canEnableBasicAuthButton() {
    const { evseController } = this.state;

    // allows to disable basic auth in case it's enabled and charging station is not connected
    if (evseController.ocppAuthorizationMethod === 'basic') {
      return;
    }

    if (
      ![OCPP_VERSION_16, OCPP_VERSION_201].includes(
        evseController.ocppProtocolVersion
      )
    ) {
      return new Error(
        `Basic authentication is not supported for charging stations using ${evseController.ocppProtocolVersion}`
      );
    }

    if (evseController.httpRequestProtocol !== 'https') {
      return new Error(
        'Basic authentication is not supported if charging station is not connected with wss protocol'
      );
    }

    const variableName = 'SecurityProfile';
    if (evseController.ocppProtocolVersion === OCPP_VERSION_201) {
      const securityProfileConfig = find(
        evseController.configuration,
        (config) =>
          config.component?.name === 'SecurityCtrlr' &&
          config.variable?.name === variableName
      );
      if (
        securityProfileConfig?.value !== '2' &&
        securityProfileConfig?.value !== 2
      ) {
        return new Error(
          'Basic authentication is only supported in ocpp2.0.1 if charging station has security profile 2'
        );
      }
    }

    // Since security profile came after the ocpp1.6's first release, we need to check if the charging station in ocpp1.6 has SecurityProfile implemented
    // and we do this by checking if the SecurityProfile is present in the configuration
    if (evseController.ocppProtocolVersion === OCPP_VERSION_16) {
      const securityProfileConfig = find(
        evseController.configuration,
        (config) => config.key === variableName
      );
      if (isEmpty(securityProfileConfig)) {
        return new Error('Basic authentication is not supported');
      }
    }
  }

  render() {
    const { evseController, errorEnableBasicAuth } = this.state;
    if (!evseController) return <Loader active />;
    const coordinatorStatus = evseController.coordinatorStatus || {
      status: 'unassigned',
    };
    const evseControllerId = this.props.evseController.id;
    const {
      authorizationInfo,
      ocppIdentity,
      bootInfoSerialNumbers,
      ocppAuthorizationMethod,
    } = evseController;
    const { connectionInfo } = coordinatorStatus;
    const providerEvseControllersAccess = currentUserCanAccessProviderEndpoint(
      'evseControllers',
      'write'
    );

    const providerEvseControllerMaintenanceAccess =
      currentUserCanAccessProviderEndpoint(
        'evseControllersMaintenance',
        'read-write'
      );

    const canAccessEvseConnectors =
      this.props.maintainerMode ||
      providerEvseControllerMaintenanceAccess ||
      currentUserIsSuperAdmin();

    const userIsAllowedToToggleBasicAuth =
      currentUserIsSuperAdmin() &&
      currentUserCanAccess('evseControllers', 'write');
    const enableBasicAuthButtonError = this.canEnableBasicAuthButton();
    const enableBasicAuthButton = !enableBasicAuthButtonError;
    const basicAuthEnabled = ocppAuthorizationMethod === 'basic';

    const connectivityMode = determineLastConnectivityMode(evseController);
    const googleCloudLogsUrl = `https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.container_name%3D%22ocpp-server%22%0AtextPayload%3D~%22${ocppIdentity}%22?project=eflux-production`;
    return (
      <div>
        <Header as="h3">Coordinator Status</Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>OCPP Identity</Table.Cell>
              <Table.Cell>{evseController.ocppIdentity}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>OCPP FTP Password</Table.Cell>
              <Table.Cell>{evseController.ocppFtpPassword || '-'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Connectivity Status</Table.Cell>
              <Table.Cell>
                <ConnectivityStatus item={evseController} oneLine />
              </Table.Cell>
            </Table.Row>
            {providerEvseControllersAccess && (
              <Table.Row>
                <Table.Cell>Connectivity Mode</Table.Cell>
                <Table.Cell>
                  <Label {...connectivityMode} />
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>Assigned To</Table.Cell>
              <Table.Cell>
                {coordinatorStatus.identifier || '-'} (
                <a
                  href={googleCloudLogsUrl}
                  rel="noopener external noreferrer"
                  target="_blank">
                  Open Logs
                </a>
                )
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Connected</Table.Cell>
              <Table.Cell>
                {evseController.coordinatorConnectedAt
                  ? fromNow(evseController.coordinatorConnectedAt)
                  : '-'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Heartbeat</Table.Cell>
              <Table.Cell>
                {evseController.heartbeatReceivedAt
                  ? fromNow(evseController.heartbeatReceivedAt)
                  : '-'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Token Authorize</Table.Cell>
              <Table.Cell>
                {evseController.tokenAuthorizeReceivedAt
                  ? fromNow(evseController.tokenAuthorizeReceivedAt)
                  : '-'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Disconnected</Table.Cell>
              <Table.Cell>
                {evseController.coordinatorDisconnectedAt
                  ? fromNow(evseController.coordinatorDisconnectedAt)
                  : '-'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Connection Info</Table.Cell>
              <Table.Cell>
                {connectionInfo ? (
                  <>
                    <p>Remote Address: {connectionInfo.remoteAddress}</p>
                    <p>Protocol: {connectionInfo.protocol}</p>
                    <p>Host: {connectionInfo.host}</p>
                  </>
                ) : (
                  '-'
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Authorization Info</Table.Cell>
              <Table.Cell>
                {authorizationInfo ? (
                  <>
                    <div>
                      State: {formatAuthorizationState(authorizationInfo)}
                    </div>
                    <p>
                      Last Attempt: {fromNow(authorizationInfo.lastAttemptedAt)}
                    </p>
                  </>
                ) : (
                  '-'
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Serial Number History</Table.Cell>
              <Table.Cell>
                {bootInfoSerialNumbers && bootInfoSerialNumbers.length
                  ? bootInfoSerialNumbers.map((item) => (
                      <p key={item.serialNumber}>
                        Last boot notification with serial number{' '}
                        <strong>{item.serialNumber}</strong> was{' '}
                        {fromNow(item.lastBootedAt)}
                      </p>
                    ))
                  : '-'}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Divider hidden />
        <Header as="h3">Setup</Header>
        {userIsAllowedToToggleBasicAuth && errorEnableBasicAuth && (
          <ErrorMessage error={errorEnableBasicAuth} />
        )}
        <Segment>
          <AutoConfigureEvseController
            onClose={() => this.fetchItem(evseControllerId)}
            onDone={() => this.fetchItem(evseControllerId)}
            evseController={evseController}
            trigger={
              <Button
                icon="wand-magic-sparkles"
                content="Auto Configuration"
                disabled={status.state === 'disconnected'}
                primary
              />
            }
          />
          {canAccessEvseConnectors && (
            <EditEvseControllerConnectors
              onClose={() => this.fetchItem(evseControllerId)}
              evseController={evseController}
              trigger={
                <Button icon="plug" content="Configure Connectors" primary />
              }
            />
          )}
          {userIsAllowedToToggleBasicAuth && (
            <FeatureFlag feature="hardware_evsecontroller_basicauth">
              <>
                {enableBasicAuthButton && (
                  <Button
                    loading={this.state.loadingEnableBasicAuth}
                    icon="lock"
                    primary={!basicAuthEnabled}
                    basic={basicAuthEnabled}
                    content={`${!basicAuthEnabled ? 'Enable' : 'Disable'} Basic Auth`}
                    onClick={() => this.changeOcppAuthorizationMethod()}
                  />
                )}
                {!enableBasicAuthButton && (
                  <Popup
                    content={enableBasicAuthButtonError.message}
                    trigger={
                      <Button
                        icon="lock"
                        content="Enable Basic Auth"
                        style={{ opacity: 0.45 }}
                      />
                    }
                  />
                )}
              </>
            </FeatureFlag>
          )}
        </Segment>
        <Divider hidden />
        <Header as="h3">Device Diagnostics</Header>
        <Segment>
          {evseController.diagnosticsRequestedAt && (
            <p>
              Diagnostics upload requested:{' '}
              {fromNow(evseController.coordinatorSeenAt)}
            </p>
          )}
          {evseController.diagnosticsRequestedAt &&
            !evseController.diagnosticsUpload && (
              <p>
                <i>Waiting for diagnostics upload from device.</i>
              </p>
            )}

          {evseController.diagnosticsUpload && (
            <p>
              Diagnostics uploaded{' '}
              <Button
                basic
                content={`Download ${evseController.diagnosticsUpload.filename}`}
                as={'a'}
                rel="noopener"
                target="_blank"
                href={evseController.diagnosticsUpload.rawUrl}
              />
            </p>
          )}
          <Button
            onClick={() => this.getDiagnostics()}
            disabled={status.state === 'disconnected'}
            loading={this.state.loading}
            icon="cloud-arrow-up"
            content="Request Diagnostics"
            primary
          />
          <Button
            onClick={() => this.resetSerialNumbers()}
            icon="arrow-rotate-left"
            content="Reset Serial Numbers"
            primary
          />
        </Segment>
        <Divider hidden />
        <Header as="h3">Firmware Update</Header>
        <Segment>
          {evseController.firmwareUpload && (
            <p>
              Last firmware binary uploaded{' '}
              {fromNow(evseController.firmwareUpload.createdAt)} (filename:{' '}
              {evseController.firmwareUpload.filename}, mimeType:{' '}
              {evseController.firmwareUpload.mimeType})
            </p>
          )}
          <UpdateFirmware
            onDone={() => this.fetchItem(evseControllerId)}
            initialValues={{}}
            evseControllerId={evseController.id}
            trigger={
              <Button
                disabled={status.state === 'disconnected'}
                title="Download"
                icon="download"
                content="Update Firmware"
                primary
              />
            }
          />
        </Segment>
      </div>
    );
  }
}
