import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import { Divider, Message, Dropdown, Loader, Button } from 'semantic';

import Menu from './Menu';
import Overview from './Overview';
import Tokens from './Tokens';
import Users from './Users';
import Invites from './Invites';
import Invoices from './Invoices';
import Sessions from './Sessions';
import Cards from './Cards';
import CpoSessions from './CpoSessions';
import EvseControllers from './EvseControllers';
import Locations from './Locations';
import AccessGroups from './AccessGroups';
import EditAccount from 'components/modals/EditAccount';

import { request } from 'utils/api';

import { withTranslation } from 'react-i18next';

import Actions from '../Actions';

import { Breadcrumbs, ListHeader, ErrorMessage } from 'components';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';

class AccountDetail extends React.Component {
  state = {
    item: null,
    error: null,
    loading: true,
  };

  componentDidMount() {
    this.fetchAccount();
  }

  componentDidUpdate(lastProps) {
    const { id } = this.props.match.params;
    if (id !== lastProps.match.params.id) {
      this.fetchAccount();
    }
  }

  fetchAccount = async () => {
    const { id } = this.props.match.params;
    this.setState({
      error: null,
      loading: true,
    });
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/accounts/${id}`,
      });

      this.setState({
        item: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { t } = this.props;
    const { loading, error, item } = this.state;

    return (
      <>
        <Breadcrumbs
          path={[
            <Link key="accounts" to="/accounts">
              {t('accountDetail.breadcrumbAccounts', 'Accounts')}
            </Link>,
          ]}
          active={item?.name || '...'}
        />
        <ListHeader title={item?.name || '...'}>
          <Dropdown
            button
            basic
            disabled={!item}
            text="Actions"
            style={{ marginTop: '-5px' }}>
            <Dropdown.Menu direction="left">
              {item && (
                <Actions
                  item={item}
                  onDelete={this.onDelete}
                  onReload={this.fetchAccount}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>

          <EditAccount
            data={item}
            onSave={this.fetchAccount}
            onClose={removeResourceProviderIdInSessionStorage}
            trigger={
              <Button
                disabled={!item}
                style={{ marginTop: '-5px' }}
                primary
                icon="gears"
                content="Settings"
                onClick={() =>
                  setResourceProviderIdInSessionStorage(item?.providerId)
                }
              />
            }
          />
        </ListHeader>

        <Divider hidden />

        {loading && <Loader active />}
        {error && <ErrorMessage error={error} />}

        {item && item.deletedAt && (
          <Message
            error
            content={
              <>
                {t(
                  'accountDetail.deletedAccount',
                  'This account has been deleted'
                )}
              </>
            }
          />
        )}

        {item && <Menu itemId={item.id} />}
        <Divider hidden />

        {item && (
          <Switch>
            <Route
              exact
              path="/accounts/:id/tokens"
              component={(props) => <Tokens {...props} account={item} />}
            />
            <Route
              exact
              path="/accounts/:id/users"
              item={item}
              component={(props) => <Users {...props} account={item} />}
            />
            <Route
              exact
              path="/accounts/:id/invites"
              item={item}
              component={(props) => <Invites {...props} account={item} />}
            />
            <Route
              exact
              path="/accounts/:id/invoices"
              item={item}
              component={(props) => <Invoices {...props} account={item} />}
            />

            <Route
              exact
              path="/accounts/:id/sessions"
              item={item}
              component={(props) => <Sessions {...props} account={item} />}
            />

            <Route
              exact
              path="/accounts/:id/cards"
              item={item}
              component={(props) => <Cards {...props} account={item} />}
            />

            <Route
              exact
              path="/accounts/:id/sessions/cpo"
              item={item}
              component={(props) => <CpoSessions {...props} account={item} />}
            />
            <Route
              exact
              path="/accounts/:id/evse-controllers"
              item={item}
              component={(props) => (
                <EvseControllers {...props} account={item} />
              )}
            />

            <Route
              exact
              path="/accounts/:id/locations"
              item={item}
              component={(props) => <Locations {...props} account={item} />}
            />

            <Route
              exact
              path="/accounts/:id/access-groups"
              item={item}
              component={(props) => <AccessGroups {...props} account={item} />}
            />

            <Route
              exact
              path="/accounts/:id"
              item={item}
              component={(props) => <Overview {...props} account={item} />}
            />
          </Switch>
        )}
      </>
    );
  }
}

export default withTranslation()(AccountDetail);
