import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCardStatus } from 'utils/formatting';
import { Search, SecretSpan, HelpTip } from 'components';
import { Table, Dropdown } from 'semantic';
import Actions from '../Actions';
import { formatDateTime } from 'utils/date';
import ResourceLink from 'components/Link/ResourceLink';
import { labelPrintUrlForCard } from 'utils/cards';

export default function CardTable() {
  const { items = [], loading, reload } = useContext(Search.Context);
  const { t } = useTranslation();

  if (!items.length || loading) return null;

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>
            {t('cards.columnBillingPlan', 'BillingPlan')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('cards.columnStatus', 'Status')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('cards.columnUser', 'User')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('cards.columnToken', 'Token')}
          </Table.HeaderCell>
          <Table.HeaderCell width={3}>
            {t('cards.columnIdentifier', 'Identifier')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('cards.columnTokenAssignedAt', 'Token Assigned At')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('cards.columnCreated', 'Created')}

            <HelpTip
              title={t('cards.columnCreated', 'Created')}
              text={t(
                'cards.columnCreatedToolTip',
                'This is the date and time the card was created.'
              )}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('cards.columnDelivery', 'Delivery Address')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('cards.columnActions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item) => {
          // Search item provider id
          const externalLinkItemProviderId = item?.providerId;

          // External Link routes
          const externalLinkUserRoute = `/accounts/users/${item?.userId}`;
          const externalLinkAccountRoute = `/accounts/${item?.accountId}`;

          // External Link names
          const externalLinkUserName =
            item?.user?.name || t('cards.NoUser', 'No User');
          const externalLinkAccountName = item?.account?.name;

          return (
            <Table.Row key={item.id}>
              <Table.Cell>{item.billingPlan?.name}</Table.Cell>
              <Table.Cell textAlign="center">
                {formatCardStatus(item.status, t)}
              </Table.Cell>
              <Table.Cell>
                {item.user ? (
                  <>
                    <ResourceLink
                      route={externalLinkUserRoute}
                      searchItemProviderId={externalLinkItemProviderId}
                      linkName={externalLinkUserName}
                    />

                    {item.account && (
                      <p>
                        <small>
                          (
                          <ResourceLink
                            route={externalLinkAccountRoute}
                            searchItemProviderId={externalLinkItemProviderId}
                            linkName={externalLinkAccountName}
                          />
                          )
                        </small>
                      </p>
                    )}
                  </>
                ) : (
                  <span>{t('cards.noUser', 'No User')}</span>
                )}
              </Table.Cell>
              <Table.Cell>
                {item.token?.uid && <SecretSpan value={item.token.uid} />}
              </Table.Cell>
              <Table.Cell>
                {item.token?.visualNumber || item.token?.customId}
              </Table.Cell>
              <Table.Cell>
                {item.tokenAssignedAt
                  ? formatDateTime(item.tokenAssignedAt)
                  : '-'}
              </Table.Cell>
              <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
              <Table.Cell>
                {labelPrintUrlForCard(item, true).toString() || '-'}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Dropdown button basic text="More">
                  <Dropdown.Menu direction="left">
                    <Actions item={item} onReload={reload} />
                  </Dropdown.Menu>
                </Dropdown>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
