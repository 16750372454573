import React from 'react';

import Dropzone from 'react-dropzone';
import { get } from 'lodash-es';
import { request } from 'utils/api';
import { formatDuration } from 'utils/date';

import { Form, Modal, Icon, Progress, Table, Message, Button } from 'semantic';

import {
  checkDupeField,
  checkUniqueField,
  processFileContent,
  readFile,
} from 'utils/csv';
import { pricingToolImportMapping } from 'utils/constants';
import { withTranslation } from 'react-i18next';
import modal from 'helpers/modal';
import sleep from 'utils/sleep';

import { Search } from 'components';

function extractBatchErrors(batchResults) {
  return batchResults?.filter((item) => item.error).map((item) => item.error);
}

async function storeBatchWithRetry(batch, importBatchId, attempt = 1) {
  try {
    const result = await request({
      method: 'POST',
      path: '/1/cdrs/pricing/batch',
      body: { batch: batch.map((body) => ({ ...body, importBatchId })) },
    });
    const batchResults = result?.data;
    if (attempt > 3) {
      return extractBatchErrors(batchResults);
    }
    return extractBatchErrors(batchResults);
  } catch (error) {
    if (error.message.match(/failed to fetch/i)) {
      await sleep(1000);
      return await storeBatchWithRetry(batch, importBatchId, attempt + 1);
    } else if (error.name.match(/ApiError/i)) {
      return error.details.map((detail) => ({
        message: error.name,
        details: detail.message,
      }));
    }

    return [error];
  }
}

function chunk(arr, len) {
  const chunks = [];
  let i = 0;
  const n = arr.length;
  while (i < n) chunks.push(arr.slice(i, (i += len)));
  return chunks;
}

async function batchCreate(objects, percentFn, batchSize = 100) {
  let errors = [];
  percentFn({ total: objects.length, percent: 1, current: 0 });
  let i = 0;
  const importBatchId = `Import of ${objects.length} CDR pricing events by ${
    window.user ? window.user.name : 'Unknown'
  } on ${new Date().toLocaleString()}`;
  const batches = chunk(objects, batchSize);
  for (const batch of batches) {
    const batchErrors = await storeBatchWithRetry(batch, importBatchId);
    errors = errors.concat(batchErrors);
    percentFn({
      percent: (i / objects.length) * 100,
      total: objects.length,
      current: i,
    });
    i += batchSize;
    await sleep(20);
  }
  percentFn({ percent: 100, total: objects.length, current: objects.length });
  return errors;
}

const defaultState = {
  step: 1,
  loading: false,
  items: null,
  mapping: null,
  progressPercent: 0,
  error: null,
  progress: { percent: 0, total: 0, current: 0 },
};

class PricingTool extends React.Component {
  state = { ...defaultState };

  drop(acceptedFiles, rejectedFiles) {
    const { t } = this.props;
    this.setState({ loading: true, error: null });
    const loading = false;
    let error = null;
    if (rejectedFiles.length) {
      error = new Error(
        t(
          'pricingTool.fileCriteriaError',
          'File did not meet criteria: {{name}}',
          {
            name:
              rejectedFiles[0].errors?.[0]?.message ||
              rejectedFiles[0]?.file?.name,
          }
        )
      );
      return this.setState({ error, loading });
    }
    if (acceptedFiles.length > 1) {
      error = new Error(
        t(
          'csvImports.fileLimitError',
          'Oops, you can only upload 1 file at a time'
        )
      );
      return this.setState({ error, loading });
    }

    readFile(acceptedFiles[0]).then((fileContent) => {
      this.setState({ step: 2, loading: true, rawCsv: fileContent });
      processFileContent(pricingToolImportMapping, fileContent)
        .then((result) => {
          checkDupeField(result, 'cdrId', t);
          checkUniqueField(result, 'partyId', t);
          this.setState({ loading: false, ...result });
        })
        .catch((error) => this.setState({ loading: false, error }));
    });
  }

  commit() {
    const { step, items, progress } = this.state;
    this.setState({
      step: step + 1,
      loading: true,
      startTs: Date.now(),
      progress: { ...progress, total: items.length },
    });
    batchCreate(items, (progress) => this.setState({ progress }))
      .then((errors) => this.setState({ loading: false, errors }))
      .catch((error) => this.setState({ loading: false, error }));
  }

  onDataNeeded = async (filters) => {
    let errors = this.state.errors;

    if (filters.errorMessage) {
      errors = this.state.errors?.filter(
        (error) => error.message === filters.errorMessage
      );
    }

    return { data: errors };
  };

  renderErrorSummary() {
    const { t } = this.props;

    return (
      <Search.Provider onDataNeeded={this.onDataNeeded}>
        {({ items }) => (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t('csvImports.columnMessage', 'Message')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('csvImports.columnDetails', 'Details')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.length ? (
                items.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{item.message}</Table.Cell>
                    <Table.Cell textAlign="right">{item.details}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="2">
                    {t(
                      'csvImports.noErrorsFiltered',
                      'No errors found for this filter.'
                    )}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        )}
      </Search.Provider>
    );
  }

  renderCommit() {
    const { t } = this.props;
    const { loading, progress, error, errors, items, startTs } = this.state;
    return (
      <div>
        {error && <Message error content={error.message} />}
        {!loading && startTs > 0 && (
          <p>
            Import took{' '}
            {formatDuration(Math.round((Date.now() - startTs) / 1000))}.
          </p>
        )}
        {loading ? (
          <Progress
            label={`${t('csvImports.importingData', 'Importing Data')} (${
              progress.current
            }/${progress.total})`}
            percent={progress.percent}
            indicating
          />
        ) : errors && errors.length ? (
          <div>
            <p>
              {t(
                'csvImports.importDataError',
                'Received {{errorCount}} errors while importing {{itemsCount}} records:',
                { errorCount: errors.length, itemsCount: items.length }
              )}
            </p>
            {this.renderErrorSummary(errors)}
          </div>
        ) : (
          <p>
            {t(
              'csvImports.importDataSuccess',
              'Imported {{itemsCount}} records successfully!',
              { itemsCount: items.length }
            )}
          </p>
        )}
      </div>
    );
  }

  renderPreview() {
    const { t } = this.props;
    const { error, loading, items, mapping, numColumnsMatched } = this.state;
    return (
      <div>
        {error && <Message error content={error.message} />}
        {loading && (
          <Progress
            label={t('csvImports.analyzingData', 'Analyzing Data')}
            percent={100}
            indicating
          />
        )}
        {items && (
          <div>
            <p>
              {t(
                'csvImports.analyzingDataSuccess',
                'Matched up {{numColumnsMatched}} columns over {{itemsCount}} records. Preview:',
                { numColumnsMatched, itemsCount: items.length }
              )}
            </p>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    {Object.keys(mapping).map((key) => (
                      <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.slice(0, 5).map((item, i) => (
                    <Table.Row key={i}>
                      {Object.keys(mapping).map((key) => (
                        <Table.Cell key={key}>{get(item, key)}</Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderUploadForm() {
    const { t } = this.props;
    const { error } = this.state;

    return (
      <div>
        <Form>
          <Form.Field>
            <label>Override Infra Provider ID (Optional)</label>
            {error && <Message error content={error.message} />}
          </Form.Field>
          <Dropzone
            maxSize={25 * 1024 * 1024}
            onDrop={(acceptedFiles, rejectedFiles) =>
              this.drop(acceptedFiles, rejectedFiles)
            }>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                className={`ui icon message ${
                  isDragActive
                    ? 'upload-dropzone-active blue'
                    : 'upload-dropzone'
                }`}
                style={{ cursor: 'pointer', outline: 0 }}>
                <Icon name="file regular" />
                <input {...getInputProps()} />
                <div className="content">
                  {isDragActive ? (
                    <p>{t('csvImports.dropfiles', 'Drop files here...')}</p>
                  ) : (
                    <p>
                      {t(
                        'csvImports.dropfilesCSV',
                        'Drop a CSV file here, or click to select one for upload.'
                      )}
                    </p>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </Form>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { step, loading, error } = this.state;
    return (
      <>
        <Modal.Header>
          {t('cdrs.pricingToolHeader', 'Pricing Tool: Update CDR Total Costs')}
        </Modal.Header>
        <Modal.Content>
          {step === 1 && this.renderUploadForm()}
          {step === 2 && this.renderPreview()}
          {step === 3 && this.renderCommit()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t('csvImports.reset', 'Reset')}
            icon="arrow-rotate-right"
            disabled={step === 1 || step > 2}
            onClick={() => this.setState({ ...defaultState })}
          />
          {step === 2 && (
            <Button
              content={t('csvImports.import', 'Import')}
              icon="check"
              primary
              disabled={loading || error}
              onClick={() => this.commit()}
            />
          )}
          {step === 3 && (
            <Button
              content={t('csvImports.done', 'Done')}
              primary
              disabled={loading}
              loading={loading}
              onClick={() =>
                this.setState(
                  { open: false, touched: false, ...defaultState },
                  () => this.props.close()
                )
              }
            />
          )}
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(PricingTool));
