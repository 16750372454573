import React, { useEffect, useRef, useState } from 'react';
import { useSubmitCardRequest } from 'components/modals/RequestCard/v2/api';
import {
  CardRequestErrorMessage,
  useRequestCardErrorFormatter,
} from 'components/modals/RequestCard/v2/errors';
import { Formik, FormikProps } from 'formik';
import { useUser } from 'contexts/user';
import { useAccount } from 'contexts/account';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Divider, Loader, Dimmer } from 'semantic';
import CardHolderAndBilling from 'components/modals/RequestCard/v2/CardHolderAndBilling';
import { useCardHolderOptions } from 'components/modals/RequestCard/v2/CardHolderAndBilling/CardHolderInfo';
import {
  RequestCardFormValues,
  useRequestCardFormValidationSchema,
  useRequestCardFormInitialState,
} from 'components/modals/RequestCard/v2/formData';
import TypeAndPlan from 'components/modals/RequestCard/v2/TypeAndPlan';
import { useChargeCardBillingPlans } from 'components/modals/RequestCard/v2/TypeAndPlan/useBillingPlans';
import ProgressSteps from 'components/ProgressSteps';
import { useSepaUrl } from 'hooks/useSepaUrl';
import { useProductInstrumentation } from 'contexts/productInstrumentation';
import { useFeatures } from 'contexts/features';

type RequestCardV2Props = {
  title?: string;
  initialValues?: {
    type: 'card' | 'tag';
  };
  trigger?: React.ReactNode;
  isOpen?: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
  duringSignup?: boolean;
};

export default function RequestCardV2(props: RequestCardV2Props) {
  const { t } = useTranslation();
  const { account } = useAccount();
  const [open, setOpen] = useState(props.isOpen);
  const { hasFeature } = useFeatures();
  const isBicCollectionEnabled = hasFeature('billing_bic_collection');

  const billingPlanData = useChargeCardBillingPlans();
  const cardHolderOptions = useCardHolderOptions();

  const sepaUrl = useSepaUrl();
  // Pre-fetch CardHolder options
  useCardHolderOptions();

  const vatPercentage = useVatPercentage();

  const [currentStep, setCurrentStep] = useState(0);

  const formRef = useRef<FormikProps<RequestCardFormValues> | null>(null);
  const formInitialState = useRequestCardFormInitialState();
  const formValidationSchema = useRequestCardFormValidationSchema(
    isBicCollectionEnabled
  );
  const errorFormatter = useRequestCardErrorFormatter();

  const { productInstrumentation } = useProductInstrumentation();

  const params = new URLSearchParams(window.location.search);
  const queryParams: Record<string, string> = {};
  params.forEach((value, key) => {
    queryParams[key] = value;
  });

  useEffect(() => {
    if (props.duringSignup) {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        stage: 'started',
        step: 'plan-selection',
        version: '1.1.0',
        ...queryParams,
      });
    }
  }, [props.duringSignup]);

  const nextStep = () => {
    if (currentStep < 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const closeModal = () => {
    setOpen(false);
    props.onClose?.();
  };

  const submitCardRequest = useSubmitCardRequest({
    onSuccess: () => {
      cardHolderOptions.refetch();
      if (props.onSuccess) {
        productInstrumentation?.trackJourneyStep({
          journey: 'sign-up',
          stage: 'completed',
          step: 'plan-selection',
          version: '1.1.0',
          ...queryParams,
        });

        props.onSuccess();
      } else {
        closeModal();
      }
    },
    onError: (error) => {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        stage: 'errored',
        step: 'plan-selection',
        version: '1.1.0',
        ...queryParams,
      });

      error.innerError.details?.forEach((detail) =>
        errorFormatter(detail, formRef.current)
      );
    },
  });

  const onSubmit = (values: RequestCardFormValues) => {
    submitCardRequest.mutate(values);
    const dataLayer = (window as any).dataLayer;
    if (dataLayer) {
      dataLayer.push({
        event: 'card_requested',
        accountBillingCountry: values.billing?.countryCode?.toUpperCase(),
        billingPlanId: values.card.billingPlanId,
        billingPlanName: billingPlanData.data?.find(
          (c) => c.id === values.card.billingPlanId
        )?.name,
      });
    }
  };

  return (
    <Modal
      closeIcon
      trigger={props.trigger}
      onClose={() => {
        setOpen(false);
        props.onClose?.();
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Dimmer inverted active={submitCardRequest.isLoading}>
        <Loader />
      </Dimmer>
      <Modal.Header>
        {props.title || t('addCardModal.title', 'Add a Charge Card')}
      </Modal.Header>
      <Formik<RequestCardFormValues>
        innerRef={formRef}
        onSubmit={onSubmit}
        validationSchema={formValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={formInitialState.formData}>
        {({ values, handleSubmit }) => (
          <>
            <Modal.Content style={{ padding: 0 }}>
              <div style={{ paddingLeft: '20%', paddingRight: '20%' }}>
                <ProgressSteps
                  steps={[
                    { label: 'Type & plan' },
                    { label: 'Card holder & billing' },
                  ]}
                  currentStep={currentStep}
                  preventForwardNavigation
                  onClickStep={(step, index) => {
                    setCurrentStep(index);
                  }}
                />
              </div>
              <Divider />
            </Modal.Content>
            <Modal.Content style={{ paddingTop: '0.5rem' }}>
              {currentStep === 0 && (
                <TypeAndPlan
                  vatPercentage={vatPercentage}
                  billingPlans={billingPlanData.data}
                />
              )}
              {currentStep === 1 && (
                <CardHolderAndBilling
                  value={formInitialState.formData}
                  accountType={account?.type || 'individual'}
                  sepaUrl={sepaUrl}
                  showBilling={formInitialState.showBillingInfo}
                  showErrorLabels
                />
              )}
              <CardRequestErrorMessage
                error={submitCardRequest.error}
                onDismiss={() => submitCardRequest.reset()}
              />
            </Modal.Content>
            <Modal.Actions>
              {currentStep === 0 && (
                <>
                  <Button basic onClick={closeModal}>
                    {t('addCardModal.closeButton', 'Close')}
                  </Button>
                  <Button
                    primary
                    disabled={!values.card.type || !values.card.billingPlanId}
                    onClick={nextStep}>
                    {t('addCardModal.continueButton', 'Continue')}
                  </Button>
                </>
              )}
              {currentStep === 1 && (
                <Button primary type="submit" onClick={handleSubmit}>
                  {t('addCardModal.confirmOrderButton', 'Confirm Order')}
                </Button>
              )}
            </Modal.Actions>
          </>
        )}
      </Formik>
    </Modal>
  );
}

function useVatPercentage() {
  const { provider } = useUser();
  return provider?.individualFees !== undefined
    ? (provider?.individualFees || 0) / 100
    : 0.21;
}
