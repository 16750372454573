import React from 'react';
import { Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { request } from 'utils/api';

import Table from 'components/tables/Locations';

import { SearchProvider, Filters } from 'components';

import { Container, Header, Segment, Grid, Divider } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';

import { useTranslation } from 'react-i18next';

export default function LocationsFacilitator() {
  const { t } = useTranslation();

  function handleRequest(body) {
    return request({
      method: 'POST',
      path: '/2/locations/cpo/facilitator/search',
      body,
    });
  }

  const labels = {
    hasErrors: t('common.hasErrors', 'Has Errors'),
    createdAt: t('common.createdAt', 'Created At'),
  };

  return (
    <AppWrapper>
      <SearchProvider limit={100} onDataNeeded={handleRequest}>
        <Container>
          <Breadcrumbs
            path={[<Link to="/facilitator/locations">Facilitator</Link>]}
            active={t('locations.title', 'Locations')}
          />
          <Grid reversed="computer">
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Header as="h2" style={{ margin: '0' }}>
                {t('locations.title', 'Locations')}
              </Header>
            </Grid.Column>
          </Grid>
          <Divider hidden />
          <Segment>
            <Grid>
              <Grid.Row width={16}>
                <Grid.Column width={10}>
                  <Filters.Modal>
                    <Filters.DateRange
                      label={labels.createdAt}
                      name="createdAt"
                    />
                  </Filters.Modal>
                  <Filters.Overview labels={labels} />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Filters.Search
                    fluid
                    placeholder={t(
                      'locations.searchPlaceholder',
                      'Search by ID, EVSE ID, etc.'
                    )}
                    name="searchId"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Table
            hideInspect
            onRefresh={() => this.refresh()}
            exportUrl="/2/locations/cpo/facilitator/search"
          />

          <Divider hidden />
        </Container>
      </SearchProvider>
    </AppWrapper>
  );
}
