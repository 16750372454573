import React, { useEffect, useState, useRef } from 'react';
import { request } from 'utils/api';
import { Layout, Search, SearchFilters } from 'components';
import Table from './Table';
import ExecuteEvseCommand from 'components/modals/ExecuteEvseCommand';
import { determineCoordinatorStatus } from 'utils/evse-controllers';

import { Divider, Button, Loader, Segment, Grid } from 'semantic';
import { ConnectivityStatus } from 'components/ConnectivityStatus';
import { uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';

const itemLimit = 100;

const defaultFilter = {
  hideHeartBeats: true,
  hideMeterValues: true,
};

function EvseControllerCommands({ evseController }) {
  const { t } = useTranslation();
  const [dataKey, setDataKey] = useState(Date.now());
  const [protocolData, setProtocolData] = useState(null);
  const [protocolLoading, setProtocolLoading] = useState(false);
  const [protocolError, setProtocolError] = useState(null);
  const [coordinatorStatus, setCoordinatorStatus] = useState(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    Promise.all([
      fetchEvseController(evseController.id),
      fetchProtocolOptions(),
    ]);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [evseController.id]);

  const fetchProtocolOptions = async () => {
    setProtocolLoading(true);
    try {
      const { data } = await request({
        method: 'GET',
        path: getOCPPProtocolPath(),
      });
      setProtocolData(data);
      setProtocolLoading(false);
    } catch (error) {
      setProtocolError(error);
      setProtocolLoading(false);
    }
  };

  const fetchEvseController = async (itemId) => {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/evse-controllers/${itemId}`,
      });

      const coordinatorStatus = data.coordinatorStatus || {
        status: 'inactive',
      };

      setCoordinatorStatus(coordinatorStatus);

      timeoutRef.current = setTimeout(() => {
        fetchEvseController(evseController.id);
      }, 4000);
    } catch (error) {
      setProtocolError(error);
    }
  };

  const onDataNeeded = (filters) => {
    const { showHeartBeats, showMeterValues, ...rest } = filters;
    const body = {
      ...defaultFilter,
      ...rest,
    };
    if (showHeartBeats) {
      delete body.hideHeartBeats;
    }
    if (showMeterValues) {
      delete body.hideMeterValues;
    }

    return request({
      method: 'POST',
      path: `/1/evse-controllers/${evseController.id}/commands/search`,
      body,
    });
  };

  function getOCPPProtocolPath() {
    const getOCPPVersion = evseController?.ocppProtocolVersion || null;

    switch (getOCPPVersion) {
      case 'ocpp1.5':
      case 'ocpp1.6':
        return `/1/ocpp/1.6/protocol`;
      case 'ocpp2.0.1':
        return `/1/ocpp/2.0.1/protocol`;
      default:
        setProtocolError(new Error(`Unknown OCPP version: ${getOCPPVersion}`));
        return null;
    }
  }

  const refresh = () => {
    setDataKey(Date.now());
  };

  const getFilterMapping = () => ({
    showHeartBeats: {
      type: 'boolean',
      label: 'Show Heartbeats',
    },
    showMeterValues: {
      type: 'boolean',
      label: 'Show Meter Values',
    },
    method: {
      type: 'string',
      label: 'Method',
      multiple: true,
    },
    createdAt: {
      type: 'date',
      label: 'Created At',
      range: true,
    },
  });

  if (!coordinatorStatus) return <Loader active />;
  const status = determineCoordinatorStatus(evseController);
  const labels = {
    createdAt: t('common.createdAt'),
  };

  const protocolOptions = uniqBy(
    [
      ...(protocolData?.CENTRAL_SYSTEM_COMMANDS || []),
      ...(protocolData?.CHARGE_POINT_COMMANDS || []),
      { name: 'Connected' },
    ],
    'name'
  );

  return (
    <div>
      <Search.Provider
        live
        key={dataKey}
        filterMapping={getFilterMapping()}
        onDataNeeded={onDataNeeded}
        limit={itemLimit}>
        <Layout horizontal spread>
          <ExecuteEvseCommand
            onDone={() => refresh()}
            evseControllerId={evseController.id}
            chargingStationId={evseController.ocppChargingStationId}
            ocppProtocolPath={getOCPPProtocolPath()}
            trigger={
              <Button
                disabled={status.state === 'disconnected'}
                primary
                icon="terminal"
                content="Execute Command"
              />
            }
          />
          <Search.Export content="Export Log" filename="commands" />
        </Layout>

        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <SearchFilters.Modal>
                  <SearchFilters.Checkbox
                    label={'Show Heartbeats'}
                    name="showHeartBeats"
                    style={{ marginRight: '10px' }}
                    toggle
                  />
                  <SearchFilters.Checkbox
                    label={'Show MeterValues'}
                    name="showMeterValues"
                    style={{ marginRight: '10px' }}
                    toggle
                  />
                  <Divider hidden />

                  <SearchFilters.Dropdown
                    label="Method"
                    search
                    multiple
                    error={protocolError}
                    loading={protocolLoading}
                    options={protocolOptions.map((command) => {
                      return {
                        key: command.name,
                        text: command.name,
                        value: command.name,
                      };
                    })}
                    name="method"
                    clearable
                  />

                  <SearchFilters.DateRange
                    label={labels.createdAt}
                    name="createdAt"
                  />
                </SearchFilters.Modal>
              </Grid.Column>
              <Grid.Column width={4} className={'right aligned'}>
                Connectivity Status:{' '}
                <ConnectivityStatus item={evseController} oneLine />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Table />
        <Search.Status
          noResults={t(
            'evseControllerCommands.noResults',
            'No commands executed yet'
          )}
        />
        <Divider hidden />
        <div
          style={{
            textAlign: 'center',
          }}>
          <Search.Pagination />
        </div>
      </Search.Provider>
    </div>
  );
}

export default EvseControllerCommands;
