import React, { useState, useEffect, useMemo } from 'react';
import { get, set } from 'lodash-es';

import { Form, Message, Modal, Button } from 'semantic';
import SearchDropdown from 'components/form-fields/SearchDropdown';

import PlanSelector from 'components/PlanSelector';
import CountriesField from 'components/form-fields/Countries';
import PostalCode from 'components/form-fields/PostalCode';
import { canAccess } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { useUser } from 'contexts/user';
import { useProductInstrumentation } from 'contexts/productInstrumentation';

export default function RequestCardV1({
  title = undefined,
  initialValues = {
    type: 'card',
    requestUserGender: 'female',
  },
  trigger,
  onSuccess,
  isOpen = false,
  onClose = undefined,
  duringSignup,
} = {}) {
  const { productInstrumentation } = useProductInstrumentation();

  const params = new URLSearchParams(window.location.search);
  const queryParams = {};
  params.forEach((value, key) => {
    queryParams[key] = value;
  });

  useEffect(() => {
    if (duringSignup) {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        stage: 'started',
        step: 'plan-selection',
        version: '1.1.0',
        ...queryParams,
      });
    }
  }, [duringSignup]);

  const { user, provider } = useUser();

  const initialType = useMemo(() => {
    return (
      provider.enabledChargeProducts.find(
        (product) => product === initialValues.type
      ) ||
      provider.enabledChargeProducts.find(
        (product) => product !== 'charge-station'
      )
    );
  }, []);

  const [formValues, setFormValues] = useState({
    ...initialValues,
    type: initialType,
    requestUserId: user.id,
  });

  const [open, setOpen] = useState(isOpen);
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState(false);
  const [sameAsBilling, setSameAsBilling] = useState(true);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  function setField(name, value) {
    const newValues = { ...formValues };
    set(newValues, name, value);
    setFormValues(newValues);
  }

  async function handleSubmit() {
    setSubmitted(true);
    setStatus({
      request: true,
    });

    try {
      await request({
        method: 'POST',
        path: '/1/cards/request',
        body: {
          ...formValues,
          ...(formValues.requestUserId === 'new'
            ? { requestUser: true, requestUserId: undefined }
            : {}),
        },
      });

      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        stage: 'completed',
        step: 'plan-selection',
        version: '1.1.0',
        ...queryParams,
      });

      setStatus({
        sucess: true,
      });

      setOpen(false);
      onSuccess();
    } catch (error) {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        stage: 'errored',
        step: 'plan-selection',
        version: '1.1.0',
        ...queryParams,
      });

      setStatus({
        error,
      });
    }
  }

  const canCreateCards = canAccess(
    user,
    provider.id,
    user.accountId,
    'cards',
    'write'
  );

  const isUpdate = !!initialValues.id;

  const isCardEnabled = provider.enabledChargeProducts.includes('card');
  const isTagEnabled = provider.enabledChargeProducts.includes('tag');

  return (
    <Modal
      size="small"
      closeIcon
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        onClose?.();
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {title || t('requestCardModel.title', 'Request Extra Card')}
      </Modal.Header>
      <Modal.Content>
        <Form
          error={submitted && Boolean(status.error)}
          onSubmit={() => handleSubmit()}>
          {status.error && <Message error content={status.error.message} />}
          <Form.Field required>
            <label>
              {t('requestCardModel.billingPlanHeader', 'Billing Plan')}
            </label>
            <PlanSelector
              languageCode={i18n.language}
              billingPlanId={formValues.billingPlanId}
              onSelect={(plan) => setField('billingPlanId', plan.id)}
            />
          </Form.Field>
          {provider.enabledChargeProducts.length > 1 && (
            <Form.Select
              value={formValues.type}
              options={[
                {
                  icon: 'credit-card',
                  text: t('cardType.card', 'Card'),
                  value: 'card',
                  disabled: !isCardEnabled,
                },
                {
                  icon: 'tag',
                  text: t('cardType.tag', 'Tag'),
                  value: 'tag',
                  disabled: !isTagEnabled,
                },
              ]}
              name="type"
              label={t('requestCardModel.cardType', 'Type')}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          )}
          {canCreateCards && (
            <Form.Field>
              <label>{t('requestCardModel.cardReceiver', 'Card Holder')}</label>
              <SearchDropdown
                keywordField="searchPhrase"
                value={formValues.requestUserId}
                search={false}
                objectMode={false}
                onDataNeeded={() => {
                  return request({
                    path: '/1/users/search/fast',
                    method: 'POST',
                    body: {
                      accountId: user.accountId,
                    },
                  }).then(({ data }) => {
                    return {
                      data: [
                        ...data.map((obj) => {
                          return {
                            ...obj,
                            name:
                              obj.id === user.id
                                ? `${obj.name} (${t(
                                    'labels.myself',
                                    'MYSELF'
                                  )})`
                                : obj.name,
                          };
                        }),
                        {
                          name: t(
                            'requestCardModel.forSomebodyElse',
                            'For somebody else (new)'
                          ),
                          id: 'new',
                        },
                      ],
                    };
                  });
                }}
                onChange={(e, { value }) => setField('requestUserId', value)}
              />
            </Form.Field>
          )}

          {formValues.requestUserId === 'new' && (
            <>
              <Form.Field required>
                <label>{t('formLabel.salutation', 'Salutation')}</label>
                <Form.Group style={{ marginTop: '5px' }}>
                  <Form.Radio
                    label={t('options.salutationMadam', 'Madam')}
                    value="female"
                    checked={formValues.requestUserGender === 'female'}
                    onChange={(e, { value }) =>
                      setField('requestUserGender', value)
                    }
                  />
                  <Form.Radio
                    label={t('options.salutationSir', 'Sir')}
                    value="male"
                    checked={formValues.requestUserGender === 'male'}
                    onChange={(e, { value }) =>
                      setField('requestUserGender', value)
                    }
                  />
                </Form.Group>
              </Form.Field>
              <Form.Input
                value={formValues.requestUserFirstName}
                name="requestUserFirstName"
                label={t('formLabel.firstName', 'First Name')}
                type="text"
                required
                onChange={(e, { name, value }) => setField(name, value)}
              />
              <Form.Input
                value={formValues.requestUserLastName}
                name="requestUserLastName"
                label={t('formLabel.lastName', 'Last Name')}
                type="text"
                required
                onChange={(e, { name, value }) => setField(name, value)}
              />
              <Form.Input
                value={formValues.requestUserEmail}
                name="requestUserEmail"
                label={t('formLabel.email', 'Email Address')}
                type="text"
                required
                onChange={(e, { name, value }) => setField(name, value)}
              />
            </>
          )}

          <Form.Field>
            <label>
              {t('requestCardModel.headerDeliveryAddress', 'Delivery Address')}
            </label>
            <Form.Checkbox
              label={
                <label>{t('formLabel.sameAsBilling', 'Same as billing')}</label>
              }
              checked={sameAsBilling}
              onChange={(e, { checked }) => setSameAsBilling(checked)}
            />
          </Form.Field>
          {!sameAsBilling && (
            <>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  label={t('formLabel.firstName', 'First Name')}
                  value={get(formValues, 'delivery.firstName', '')}
                  name="delivery.firstName"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />
                <Form.Input
                  required
                  label={t('formLabel.lastName', 'Last Name')}
                  value={get(formValues, 'delivery.lastName', '')}
                  name="delivery.lastName"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />
              </Form.Group>
              <Form.Group widths="3">
                <Form.Input
                  width="12"
                  required
                  value={get(formValues, 'delivery.address', '')}
                  name="delivery.address"
                  label={t('formLabel.address', 'Address')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />

                <Form.Input
                  required
                  value={get(formValues, 'delivery.addressLine2', '')}
                  name="delivery.addressLine2"
                  label={t('formLabel.addressLine2', 'No.')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />
              </Form.Group>

              <CountriesField
                required
                label={t('formLabel.country', 'Country')}
                name="country"
                value={get(formValues, 'delivery.countryCode', '')}
                onChange={(value) => setField('delivery.countryCode', value)}
              />

              <Form.Group widths="equal">
                <PostalCode
                  required
                  countryCode={get(formValues, 'delivery.countryCode', '')}
                  value={get(formValues, 'delivery.postalCode', '')}
                  name="delivery.postalCode"
                  label={t('formLabel.postCode', 'Postal Code')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />

                <Form.Input
                  required
                  value={get(formValues, 'delivery.city', '')}
                  name="delivery.city"
                  label={t('formLabel.city', 'City/Town')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />
              </Form.Group>
            </>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={status.request === true}
          disabled={status.request === true}
          primary
          content={
            isUpdate
              ? t('button.update', 'Update')
              : t('button.create', 'Create')
          }
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}
