import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic';
import modal from 'helpers/modal';
import { request } from 'utils/api';
import { Dropdown } from 'semantic';
import { currentUserCanAccess, currentUserHasGlobalRoles } from 'utils/roles';
import { useUser } from 'contexts/user';

interface Props {
  onSelect: (item: any) => void;
  defaultValue: string;
}

const ProviderSelector: React.FC<Props> = ({ onSelect, defaultValue }) => {
  const canListUnfiltered =
    currentUserCanAccess('providers', 'read') || currentUserHasGlobalRoles();
  const user = useUser();
  const [state, setState] = useState<{
    data:
      | null
      | undefined
      | {
          id: string;
          name: string;
        }[];
    loading: boolean;
    error: any;
  }>({
    data: null,
    loading: true,
    error: null,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await request({
          method: 'GET',
          path: `/1/providers/list`,
        });
        setState({ data, error: null, loading: false });
      } catch (error) {
        setState({ error, loading: false, data: null });
      }
    };
    fetch();
  }, []);

  const options = (state.data || []).map((item) => {
    return {
      key: item.id,
      value: item.id,
      text: item.name,
    };
  });

  return (
    <React.Fragment>
      <Modal.Header>Select Provider</Modal.Header>
      <Modal.Content>
        <Dropdown
          selection
          fluid
          search
          loading={state.loading}
          error={state.error}
          options={options}
          onChange={(e, { value }) => {
            const item = state?.data?.filter((c) => c.id === value)[0];
            onSelect(item);
            window.location.reload();
          }}
          defaultValue={defaultValue}
        />
      </Modal.Content>
    </React.Fragment>
  );
};

export default modal(ProviderSelector);
