import React from 'react';

import { Search } from 'components';

import { Table, Divider } from 'semantic';
import { round, formatLocationAddress } from 'utils/formatting';

import { formatDateTime } from 'utils/date';

import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { Layout } from 'components';
import Session from '../../components/Session';
import { UserContext } from 'contexts/user';
import { formatDuration } from 'utils/date';
import { safeFileName } from 'utils/formatting';

/**
 * TODO: Refactor this into a functional component can reduce the complexity of the code, especially the usage of 2 Contexts (UserContext and).
 */
class MyMspUsageSessions extends React.Component {
  static contextType = UserContext;

  onDataNeeded = (filters) => {
    return request({
      method: 'POST',
      path: '/1/sessions/mine',
      body: {
        ...filters,
        sort: {
          order: 'desc',
          field: 'startedAt',
        },
      },
    });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Search.Provider ref={this.searchRef} onDataNeeded={this.onDataNeeded}>
          {({ items }) => {
            return (
              <>
                <Layout right horizontal center>
                  <Search.Total />
                  <Search.ExportRange
                    onRangeSubmit={async (from, to, filters) => {
                      const body = this.props.body || {};

                      await this.onDataNeeded({
                        format: 'csv',
                        limit: 100000,
                        from,
                        to,
                        filename: safeFileName(`Sessions`),
                        ...filters,
                        ...body,
                      });
                    }}
                  />
                </Layout>

                <Search.Status
                  noItems={t(
                    'myUsage.messageNoSessions',
                    'No charge sessions processed yet'
                  )}
                />

                {items.length != 0 && (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={3}>
                          {t('myUsage.columnUser', 'User')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t('myUsage.columnCard', 'Name on card')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={3}>
                          {t('myUsage.columnDuration', 'Duration')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t(
                            'sessionTable.columnPriceVAT',
                            'Price (incl. VAT)'
                          )}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                          {t('myUsage.columnKwh', 'kWh')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t('myUsage.address', 'Address')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t(
                            'myUsage.columnExternalProvider',
                            'Infra ExternalProvider'
                          )}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => {
                        const location = item.location;
                        return (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              {item.user &&
                                `${item.user.contact.firstName} ${item.user.contact.lastName}`}
                            </Table.Cell>
                            <Table.Cell>{item.nameOnCard || '-'}</Table.Cell>
                            <Table.Cell>
                              {formatDuration(item.durationSeconds)}

                              <p>
                                <small>
                                  {t('common.start', 'Start')}:{' '}
                                  {formatDateTime(item.startedAt)}
                                </small>
                                <br />
                                <small>
                                  {t('common.end', 'End')}:{' '}
                                  {formatDateTime(item.endedAt)}
                                </small>
                              </p>
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              <Session.Price withVAT session={item} />
                              <p>
                                <small>
                                  {t('common.excl', 'Excl')}:{' '}
                                  <Session.Price session={item} />
                                </small>
                              </p>
                            </Table.Cell>

                            <Table.Cell width={1} textAlign="right">
                              {round(item.kwh, 2)}
                            </Table.Cell>
                            <Table.Cell>
                              {formatLocationAddress(location)}
                            </Table.Cell>
                            <Table.Cell
                              width={1}
                              title={`ExternalProviderId ${item.infraProviderId}`}>
                              {item.externalProvider
                                ? item.externalProvider.name
                                : `${item.infraProviderId}`}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}

                <Divider hidden />
                <div
                  style={{
                    textAlign: 'center',
                  }}>
                  <Search.Pagination />
                </div>
              </>
            );
          }}
        </Search.Provider>
      </>
    );
  }
}

export default withTranslation()(MyMspUsageSessions);
