export const defaultColors = [
  '#ff8500',
  '#00a5ff',
  '#00ffb6',
  '#ff3d00',
  '#f100a0',
  '#ab3fda',
];

export const sessionsExclusionFilter = {
  ranges: [
    {
      durationSeconds: { gte: 0, lte: 7 * 24 * 3600 },
    },
    {
      kwh: { lte: 10000, gt: 0 },
    },
  ],
};

export function mergeFilters(one, two) {
  const baseFilter = {};
  Object.keys(one).forEach((key) => {
    baseFilter[key] = one[key];
  });
  Object.keys(two).forEach((key) => {
    if (baseFilter[key]) {
      baseFilter[key] = baseFilter[key].concat(two[key]);
    } else {
      baseFilter[key] = two[key];
    }
  });
  return baseFilter;
}

export function changeFilterId(filter, field) {
  let newFilter = {};
  filter?.terms?.forEach((key) => {
    if (!newFilter?.terms) {
      newFilter = { terms: [] };
    }
    Object.keys(key)[0] === field
      ? newFilter.terms.push({ id: Object.values(key)[0] })
      : newFilter.terms.push(key);
  });
  return newFilter;
}
