import React, { useState, useEffect } from 'react';
import { Form, Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';
import SearchDropDown from 'components/form-fields/SearchDropdown';
import ConnectorSelector from 'components/form-fields/ConnectorSelector';

export default function UnlockConnector(props = { initialValues: {} }) {
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({ ...props.initialValues });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.initialValues !== formValues) {
      setSubmitted(false);
      setFormValues({ ...props.initialValues });
    }
  }, [props.initialValues]);

  const handleSubmit = () => {
    const { locationId, onDone } = props;

    setSubmitted(true);
    setLoading(true);

    const { evseControllerId } = formValues;
    const path = evseControllerId
      ? `/1/evse-controllers/${evseControllerId}/action`
      : `/2/locations/cpo/${locationId}/action`;

    request({
      method: 'POST',
      path,
      body: {
        method: 'UnlockConnector',
        evseControllerId: formValues.evseControllerId,
        ocppConnectorId: formValues.ocppConnectorId,
        ocppEvseId: formValues.ocppEvseId,
      },
    })
      .then(({ data }) => {
        if (onDone) {
          onDone(data);
        }
        setFormValues({ ...props.initialValues });
        setOpen(false);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
        setSubmitted(false);
      });
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const { locationId, trigger } = props;

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        setFormValues({ ...props.initialValues });
        setSubmitted(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>Unlock Connector</Modal.Header>
      <Modal.Content>
        <p>
          By executing this command, the charging station will attempt to
          disengage the charge cable.
        </p>
        <Form error={submitted && Boolean(error)} onSubmit={handleSubmit}>
          {error && <Message error content={error.message} />}

          {!props.initialValues?.evseControllerId && (
            <Form.Field>
              <label>EVSE Controller</label>
              <SearchDropDown
                value={formValues.evseControllerId}
                getOptionLabel={(option) => option.ocppIdentity}
                objectMode={false}
                onDataNeeded={(body) => {
                  return request({
                    path: `/2/locations/cpo/${locationId}/evse-controllers/search`,
                    method: 'POST',
                    body: body,
                  });
                }}
                onChange={(e, { value }) => setField('evseControllerId', value)}
              />
            </Form.Field>
          )}

          {formValues.evseControllerId && (
            <Form.Field>
              <label>Connector ID</label>
              <ConnectorSelector
                evseControllerId={formValues.evseControllerId}
                value={formValues.locationId}
                getOptionLabel={(option) => option.ocppIdentity}
                onChange={(e, { value }) => {
                  setField('ocppConnectorId', value.ocppConnectorId);
                  setField('ocppEvseId', value.ocppEvseId);
                }}
              />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading || submitted}
          primary
          content="Execute"
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}
