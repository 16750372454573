import React from 'react';

import { withTranslation } from 'react-i18next';

import { Table, Button, Dropdown, Popup, Label } from 'semantic';
import { Link } from 'react-router-dom';

import EditAccountTier from 'components/modals/EditAccountTier';
import { currentUserCanAccess } from 'utils/roles';
import { Search } from 'components';

import Actions from '../Actions';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';

class AccountTiersTable extends React.Component {
  static contextType = Search.Context;

  async componentDidMount() {
    const { data } = await request({
      method: 'GET',
      path: '/1/account-tiers/usage-summary',
    });

    this.setState({ summary: data });
  }

  render() {
    const { t } = this.props;
    const { reload, loading } = this.context;
    let { items } = this.context;
    const { summary } = this.state || {};

    const writeAccess = currentUserCanAccess('account-tiers', 'write');

    if (!items.length || loading) return null;

    // let's order the list by the 'lightest' package first
    items = items.sort(
      (a, b) => a.platformFeatures.length - b.platformFeatures.length
    );

    return (
      <>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>
                {t('accountTiers.columnName', 'Name')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('accountTiers.columnNoOfAccounts', 'No. of Accounts')}
              </Table.HeaderCell>
              <Table.HeaderCell width={6}>
                {t('accountTiers.columnFeatures', 'Features')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('accountTiers.columnCreated', 'Created')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('billingPlans.columnActions', 'Actions')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <Link to={`/accounts?page=1&accountTier=${item.id}`}>
                      {(summary &&
                        summary.find((s) => s.accountTierId == item.id)
                          ?.noOfAssignedAccounts) ||
                        0}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {item.platformFeatures
                      .sort((a, b) => (a.name < b.name ? -1 : 1))
                      .map((feature) => (
                        <Popup
                          key={item.id + feature.key}
                          content={feature.description}
                          trigger={<Label content={feature.name} />}
                        />
                      ))}
                  </Table.Cell>
                  <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <EditAccountTier
                      accountTier={item}
                      onSave={reload}
                      trigger={
                        <Button
                          basic
                          icon="pen-to-square"
                          title="Edit"
                          disabled={!writeAccess}
                        />
                      }
                    />
                    <Dropdown button basic text="More">
                      <Dropdown.Menu direction="left">
                        <Actions
                          writeAccess={writeAccess}
                          item={item}
                          onReload={reload}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default withTranslation()(AccountTiersTable);
