import React from 'react';
import { Modal } from 'semantic';

import modal from '../../helpers/modal';
import RemoteTokenSearch from '../RemoteTokenSearch';

class RemoteTokenSearchModal extends React.Component {
  render() {
    return (
      <>
        <Modal.Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span>Remote token search</span>
        </Modal.Header>

        <Modal.Content>
          <RemoteTokenSearch
            tokenUid={this.props.tokenUid}
            allowedProtocols={this.props.allowedProtocols}
          />
        </Modal.Content>
      </>
    );
  }
}

export default modal(RemoteTokenSearchModal);
