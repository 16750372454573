import React, { useRef, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RequestCardModal } from 'components/modals/RequestCard';
import { canAccess } from 'utils/roles';
import { Header, Segment, Divider, Icon, Button } from 'semantic';
import { request } from 'utils/api';
import {
  Breadcrumbs,
  Search,
  SearchFilters,
  ListHeader,
  Layout,
} from 'components';
import { UserContext } from 'contexts/user';
import Table from './Table';
import { useTranslation } from 'react-i18next';
import { AccountProvider } from 'contexts/account';

const MyCards = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const canEdit = canAccess(
    user,
    user.providerId,
    user.accountId,
    'cards',
    'write'
  );
  const searchRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDataNeeded = (params) => {
    return request({
      method: 'POST',
      path: '/1/cards/mine/fast',
      body: {
        ...params,
      },
    });
  };

  const getFilterMapping = () => {
    return {
      status: {
        label: t('cards.filterByStatus', 'Filter By Status'),
        type: 'string',
      },
      createdAt: {
        label: t('common.createdAt'),
        type: 'date',
        range: true,
      },
    };
  };

  const labels = {
    status: t('cards.filterByStatus', 'Filter By Status'),
    createdAt: t('common.createdAt', 'Created At'),
  };

  return (
    <Search.Provider
      ref={searchRef}
      live={() => {
        // This is a dirty hack to mitigate an eventual consistency issue
        // where a user is redirected to this page after signup, but their
        // newly created cards have not been indexed in ES yet. See SUP-101
        return (
          sessionStorage.getItem('signupCompletedAt') &&
          Date.now() - parseInt(sessionStorage.getItem('signupCompletedAt')) <
            1000 * 60 * 2
        );
      }}
      liveInterval={1000}
      onDataNeeded={onDataNeeded}
      filterMapping={getFilterMapping()}>
      {({ reload, loading, items }) => (
        <>
          <Breadcrumbs active={t('myCards.title', 'My Cards')} />

          <ListHeader title={t('myCards.title', 'My Cards')}>
            {canEdit && (
              <AccountProvider>
                <RequestCardModal
                  key={Date.now()}
                  onSuccess={reload}
                  trigger={
                    <Button
                      primary
                      content={t(
                        'myCards.buttonRequestExtraCard',
                        'Request Extra Card'
                      )}
                      icon="plus"
                    />
                  }
                />
              </AccountProvider>
            )}
          </ListHeader>

          {!loading &&
            items.filter((item) => ['pending', 'sent'].includes(item.status))
              .length > 0 && (
              <Segment placeholder>
                <Header icon>
                  <Icon name="envelope" />
                  {t(
                    'myCards.cardIsOnItsWayHeader',
                    'Your card is on the way. Once you receive it in the mail, please'
                  )}
                </Header>
                <Button as={Link} to="/activate" primary size="large">
                  {t('myCards.activateCardButton', 'Activate Your Card')}
                </Button>
              </Segment>
            )}

          <Divider hidden />
          <Segment>
            <Layout horizontal spread stackable>
              <SearchFilters.Modal>
                <SearchFilters.Dropdown
                  name="status"
                  label={labels.status}
                  options={[
                    {
                      text: t('cards.filterByStatusPending', 'Pending'),
                      value: 'pending',
                    },
                    {
                      text: t('cards.filterByStatusSent', 'Sent'),
                      value: 'sent',
                    },
                    {
                      text: t('cards.filterByStatusActive', 'Active'),
                      value: 'active',
                    },
                    {
                      text: t('cards.filterByStatusDisabled', 'Disabled'),
                      value: 'disabled',
                    },
                  ]}
                />
                <SearchFilters.DateRange
                  label={labels.createdAt}
                  name="createdAt"
                />
              </SearchFilters.Modal>
              <Layout horizontal stackable center right>
                <Search.Total />
                <SearchFilters.Search
                  name="searchPhrase"
                  placeholder={t(
                    'cards.filterPlaceHolder',
                    'Search by Name or ID'
                  )}
                />
              </Layout>
            </Layout>
          </Segment>

          <Search.Status noResults={t('common.noResults', 'No Results')} />
          <Table />

          <div
            style={{
              textAlign: 'center',
            }}>
            <Search.Pagination />
          </div>
        </>
      )}
    </Search.Provider>
  );
};

export default MyCards;
