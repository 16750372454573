import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { Container, Divider, Message, Dropdown, Grid, Header } from 'semantic';

import MenuContainer from './Menu';
import PageLoader from 'components/PageLoader';
import Overview from './Overview';
import EvseControllers from './EvseControllers';
import Sessions from './Sessions';
import Actions from './Actions';
import Costs from './Costs';
import Connectors from './ConnectorsCpo';
import Analytics from './Analytics';
import PaymentTerminals from './PaymentTerminals';
import { request } from 'utils/api';
import {
  currentUserCanAccessProviderEndpoint,
  currentUserIsSuperAdmin,
} from 'utils/roles';
import Breadcrumbs from 'components/Breadcrumbs';

import UnlockConnector from 'components/modals/UnlockConnector';
import ResetEvse from 'components/modals/ResetEvse';
import EditMyLocation from 'components/modals/EditMyLocation';
import EditLocation from 'components/modals/EditLocation';
import { useTranslation } from 'react-i18next';
import InspectObject from '../../components/modals/InspectObject';
import { UserWithFacilitatorPermissionsProvider } from 'contexts/user';
import { EnergyMarketTariffProvider } from 'components/MarketEnergyTariffs/Context';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';
import { AccountPlatformFeatureProvider } from 'components/account-platform-features/context';
import SyncLogStatus from 'components/modals/SyncLogStatus';

export default function Location({ match }) {
  const { t } = useTranslation();

  const itemId = match.params.id;

  const [item, setItem] = React.useState();
  const [facilitatorPermissions, setFacilitatorPermissions] = React.useState();
  const [status, setStatus] = React.useState({ request: true });

  async function fetchData() {
    setStatus({ loading: true });
    try {
      const { data, facilitatorPermissions } = await request({
        method: 'GET',
        path: `/2/locations/cpo/${itemId}`,
      });
      setStatus({ success: true });
      setItem(data);
      setFacilitatorPermissions(facilitatorPermissions);
    } catch (e) {
      setStatus({ error: e });
    }
  }

  React.useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [itemId]);

  React.useEffect(() => {
    if (item?.providerContext && item.providerContext !== 'cpo') {
      setStatus({ error: new Error(`Not a CPO location.`) });
    }
  }, [item]);

  return (
    <AppWrapper>
      <AccountPlatformFeatureProvider accountId={item?.accountId}>
        <UserWithFacilitatorPermissionsProvider
          facilitatorPermissions={facilitatorPermissions}>
          <Container>
            <Breadcrumbs
              path={
                currentUserCanAccessProviderEndpoint('locations', 'read')
                  ? [
                      <Link to="/charging-stations">Charging Stations</Link>,
                      <Link to="/charging-stations/locations">
                        {t('location.breadcrumbHeader', 'Locations')}
                      </Link>,
                    ]
                  : [
                      <Link to="/my-locations">
                        {t('myLocations.header', 'My Locations')}
                      </Link>,
                    ]
              }
              active={item ? item.name : t('loading.loading')}
            />
            <Grid reversed="computer">
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={4}
                textAlign="right"
                verticalAlign="middle">
                <Dropdown
                  basic
                  button
                  text={t('location.actionsBtn', 'Actions')}>
                  <Dropdown.Menu direction="left">
                    {item?.accessPolicy !== 'communityReimburse' &&
                      (currentUserCanAccessProviderEndpoint(
                        'locations',
                        'read'
                      ) ? (
                        <EditLocation
                          data={item}
                          trigger={
                            <Dropdown.Item
                              content={t('location.configure', 'Configure')}
                              icon="gear"
                              onClick={() =>
                                setResourceProviderIdInSessionStorage(
                                  item?.providerId
                                )
                              }
                            />
                          }
                          onClose={() => {
                            removeResourceProviderIdInSessionStorage();
                            fetchData();
                          }}
                        />
                      ) : (
                        <EditMyLocation
                          initialValues={item}
                          trigger={
                            <Dropdown.Item
                              content={t(
                                'location.editLocation',
                                'Edit location'
                              )}
                              icon="pen-to-square"
                            />
                          }
                          onClose={() => fetchData()}
                        />
                      ))}

                    {currentUserIsSuperAdmin() && (
                      <SyncLogStatus
                        getData={() => item}
                        modelName="location"
                        size="fullscreen"
                        syncStatusName="Location Push"
                        trigger={
                          <Dropdown.Item
                            disabled={item?.publishingMode === 'private'}
                            text="Sync status"
                            icon="wand-magic-sparkles"
                          />
                        }
                      />
                    )}

                    <UnlockConnector
                      locationId={itemId}
                      trigger={
                        <Dropdown.Item
                          content={t(
                            'location.unlockConnector',
                            'Unlock Connector'
                          )}
                          icon="plug"
                        />
                      }
                    />
                    <ResetEvse
                      locationId={itemId}
                      trigger={
                        <Dropdown.Item
                          icon="arrow-rotate-right"
                          content={t('location.reset', 'Reboot this station')}
                        />
                      }
                    />
                    <InspectObject
                      name="CPO Location"
                      data={item}
                      trigger={
                        <Dropdown.Item content={'Inspect'} icon="code" />
                      }
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={12}>
                <Header as="h2" style={{ margin: '0' }}>
                  {item ? item.name : t('loading.loading')}
                </Header>
              </Grid.Column>
            </Grid>
          </Container>
          <Divider hidden />
          {item && item.deletedAt && (
            <Message
              error
              content={t(
                'location.locationDeleted',
                'This location has been deleted'
              )}
            />
          )}
          <MenuContainer itemId={itemId} item={item} />
          <Divider hidden />

          {status.error && (
            <>
              <Message error content={status.error.message} />
              <Link to={`/cards/locations/${itemId}`}>
                Use {item?.providerContext.toUpperCase()} location endpoint
                instead
              </Link>
            </>
          )}

          {status.request && <PageLoader />}
          {item && !status.error && (
            <EnergyMarketTariffProvider
              energyDeliveryArea={item?.energyDeliveryArea}>
              <Switch>
                <Route
                  exact
                  path="/charging-stations/locations/:id/actions"
                  component={(props) => <Actions {...props} location={item} />}
                />
                <Route
                  exact
                  path="/charging-stations/locations/:id/config"
                  component={(props) => <Costs {...props} location={item} />}
                />
                <Route
                  exact
                  path="/charging-stations/locations/:id/connectors"
                  component={(props) => (
                    <Connectors {...props} location={item} />
                  )}
                />
                <Route
                  exact
                  path="/charging-stations/locations/:id/evse-controllers"
                  item={item}
                  component={(props) => (
                    <EvseControllers {...props} location={item} />
                  )}
                />
                <Route
                  exact
                  path="/charging-stations/locations/:id/payment-terminals"
                  item={item}
                  component={(props) => (
                    <PaymentTerminals {...props} location={item} />
                  )}
                />
                <Route
                  exact
                  path="/charging-stations/locations/:id/sessions"
                  item={item}
                  component={(props) => <Sessions {...props} location={item} />}
                />
                <Route
                  exact
                  path="/charging-stations/locations/:id/analytics"
                  item={item}
                  component={(props) => (
                    <Analytics {...props} location={item} />
                  )}
                />
                <Route
                  exact
                  path="/charging-stations/locations/:id"
                  item={item}
                  component={(props) => <Overview {...props} location={item} />}
                />
              </Switch>
            </EnergyMarketTariffProvider>
          )}
        </UserWithFacilitatorPermissionsProvider>
      </AccountPlatformFeatureProvider>
    </AppWrapper>
  );
}
