import React from 'react';
import { FieldValidator, useField } from 'formik';
import { Label, Form } from 'semantic';
import { DropdownItemProps } from 'semantic-ui-react';

interface Props {
  name: string;
  label?: string;
  validate?: FieldValidator;
  options: DropdownItemProps[];
  clearable?: boolean;
  objectMode?: boolean;
  required?: boolean;
  multiple?: boolean;
  selection?: boolean;
  fluid?: boolean;
  hideErrorLabel?: boolean;
}

const DropdownField: React.FC<Props> = ({
  name,
  label,
  validate,
  options,
  multiple = false,
  selection = false,
  objectMode = false,
  clearable = true,
  required = false,
  hideErrorLabel = false,
  fluid = false,
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  return (
    <>
      <Form.Dropdown
        clearable={clearable}
        fluid={fluid}
        value={field.value}
        objectMode={objectMode}
        label={label}
        required={required}
        multiple={multiple}
        selection={selection}
        options={options}
        onChange={(e, { value }) => {
          helpers.setValue(value, true);
        }}
        error={
          meta.touched && meta.error
            ? hideErrorLabel
              ? undefined
              : meta.error
            : undefined
        }
      />

      {/* We had to add this label to show error message box below the dropdown list */}
      {meta.error && meta.touched && !hideErrorLabel && (
        <Label
          basic
          pointing={'above'}
          style={{
            color: '#9F3A38',
            marginTop: -15,
            border: '1px solid #E0B4B4',
          }}>
          {meta.error}
        </Label>
      )}
    </>
  );
};

export default DropdownField;
