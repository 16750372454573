import React from 'react';

import { startCase } from 'lodash-es';

import { Divider, Message, Card, Icon, Transition, Button } from 'semantic';

import { Search } from 'components';

import ExpandableJson from 'components/ExpandableJson';
import ViewCommand from 'components/modals/ViewCommand';
import { fromNow } from 'utils/date';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';

function colorForItem(command) {
  if (command.error) {
    return 'red';
  }
  if (command.status === 'busy' && !command.result) {
    return 'yellow';
  }
  if (command.status === 'done' && command.result) {
    return 'olive';
  }
  return 'grey';
}

function colorForStatus(status) {
  if (status === 'pending' || status === 'busy') {
    return 'orange';
  }
  if (status === 'done') {
    return 'olive';
  }
  if (status === 'error' || status === 'timeout') {
    return 'red';
  }
  return undefined;
}

function formatOrigin(destination) {
  if (destination === 'centralsystem') {
    return 'Sent From Device';
  } else {
    return 'Sent From Cloud';
  }
}

const ResultsDiv = styled.div`
  color: #6c727f;
  margin-bottom: 1em;
`;

const RightFloatingDiv = styled.div`
  float: right;
`;

export default class EvseControllerCommandsTable extends React.Component {
  static contextType = Search.Context;

  toggleAll(items, checked) {
    items.forEach((item) => {
      this.props.onToggleItem(item.id, checked);
    });
  }

  render() {
    const { items, reload, meta } = this.context;

    if (!items.length) {
      return null;
    }

    return (
      <div className="list">
        <ResultsDiv>
          {meta.skip + 1} - {Math.min(meta.skip + meta.limit, meta.total)} of{' '}
          {meta.total} results
        </ResultsDiv>
        <Transition.Group
          animation="fade down"
          as={Card.Group}
          duration={600}
          divided="true">
          {items.map((item) => {
            const hasResult =
              (item.result && Object.keys(item.result).length) || item.error;
            return (
              <Card key={item.id} fluid color={colorForItem(item)}>
                <Card.Content>
                  <RightFloatingDiv>
                    <ViewCommand
                      item={item}
                      onClose={() => reload()}
                      trigger=<Button basic icon="code" title="Inspect" />
                    />
                    <Search.Export
                      content=""
                      filename={item.id}
                      body={{ ids: [item.id] }}
                    />
                  </RightFloatingDiv>
                  <Card.Header>{item.method}</Card.Header>
                  <Card.Meta>{fromNow(item.createdAt)}</Card.Meta>
                  <Card.Description>
                    <ExpandableJson
                      object={item.params}
                      name="Params"
                      collapsed={1}
                      displayDataTypes={false}
                    />
                    {hasResult && (
                      <>
                        <Divider
                          horizontal
                          style={{
                            textTransform: 'none',
                            fontWeight: 'normal',
                            letterSpacing: 'normal',
                            marginTop: '8px',
                            marginBottom: '8px',
                          }}>
                          {startCase(item.destination)} Response:
                        </Divider>
                        {item.result && (
                          <ExpandableJson
                            object={item.result}
                            name="Response"
                          />
                        )}{' '}
                        {item.error && (
                          <p>
                            <Message
                              error
                              header={item.error.type}
                              content={item.error.message}
                            />
                            {Object.keys(item.error.details || {}).length >
                              0 && (
                              <ExpandableJson
                                object={item.error.details}
                                name="Error"
                                collapsed={1}
                                displayDataTypes={false}
                              />
                            )}
                          </p>
                        )}
                      </>
                    )}
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  {formatOrigin(item.destination)} &middot; Status:{' '}
                  <Label color={colorForStatus(item.status)}>
                    {startCase(item.status)}
                  </Label>
                </Card.Content>
              </Card>
            );
          })}
        </Transition.Group>
      </div>
    );
  }
}
